import { ChangeEvent } from 'react';

// style
import styled from 'styled-components';
import { useThemeColor } from 'hooks/global/color';

type TextInputProps = {
    className?:string,
    value:string,
    type?:'text' | 'number',
    placeholder?:string,
    onChange?:(text:string) => void
}

export const TextInput = ({
    className = '',
    value,
    type = 'text',
    placeholder = '',
    onChange = () => {}
}:TextInputProps) => {

    const colors:ThemeColor = useThemeColor();

    const handleChange = (event:ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        // 親コンポーネントに伝達
        onChange(event.target.value);
    }

    return (
        <Style_TextInput 
          className={className}
          type={type} 
          value={value}
          placeholder={placeholder}
          colors={colors} 
          onChange={handleChange}
        />
    )
}

const Style_TextInput = styled.input<{
    colors:ThemeColor
}>`

  & {
    outline: none;
  }

  & {
    background-color: ${(props) => props.colors.deeperBackground};
    line-height: 1;
    padding: .4em .4em;
    border-radius: 4px;
    border:1px solid ${(props) => props.colors.border};

    &::placeholder {
      opacity: .4;
    }

    &:focus {
      border-color: ${(props) => props.colors.main};
    }
  }
`