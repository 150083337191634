// components
import { Layout } from './Layout'
import { ActionList } from './ActionList'

// compoents
type ChatbotRootProps = {
    scenarioData:CB_Scenario | undefined
}

export const ChatbotRoot = ({
    scenarioData
}:ChatbotRootProps) => {
    return (
        <>
        </>
    )
} 

/**
 * 
          <Layout >
            {scenarioData && (
              <ActionList scenarioData={scenarioData} />
            )}
          </Layout>
 */