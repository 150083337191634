export const talkActionTransformer = (actionBackend:CB_Action_Backend):CB_Action => {

    const actionDetailBackend = actionBackend.action_detail as CB_TalkActionDetail_Backend;

    const actionDetail:CB_TalkActionDetail = {
        text:actionDetailBackend.text
    }

    const talkAction:CB_Action= {
        id:actionBackend.id,
        type:'talk',
        actionDetail:actionDetail
    }

    return talkAction;

}