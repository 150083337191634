import { ReactNode, useEffect, useMemo, useState } from "react"

// style
import styled from 'styled-components';
import { useThemeColor } from 'hooks/global/color';

// modal
import { useDialog } from 'hooks/contexts/dialog'

/**
 * ここのコンポーネント出し分けシステムはちゃんと分離して整理する必要あり
 */

// element components
import { IconTextButton } from "components/elements/ui/IconTextButton";

// components
import { AddressActionEditor } from './actionEditors/AddressActionEditor';
import { NameActionEditor } from './actionEditors/NameActionEditor';
import { ConfirmActionEditor } from './actionEditors/ConfirmActionEditor';
import { RadioBranchActionEditor } from './actionEditors/RadioBranchActionEditor';
import { NextBlockActionEditor } from './actionEditors/NextBlockActionEditor';
import { PaymentActionEditor } from './actionEditors/PaymentActionEditor';

type GuestActionEditorProps = {
    scenarioData:ScenarioWithActions,
    action:Action,
    onUpdate:(newAction:Action) => void,
    onRemove:(action:Action) => void
}

export const GuestActionEditor = ({
    scenarioData,
    action,
    onUpdate,
    onRemove
}:GuestActionEditorProps) => {

    const colors:ThemeColor = useThemeColor();

    const {showDialog,closeDialog} = useDialog();

    const [actionToUpdate,setActionToUpdate] = useState<Action>(action);

    const minimumScenarioBlockList:ScenarioBlockMinimum[] = useMemo(() => {

        const currentScenarioBlockList:ScenarioBlock[] = scenarioData.scenarioBlockList;

        const _minimumScenarioBlockList:ScenarioBlockMinimum[] = 
            currentScenarioBlockList.map((scenarioBlock:ScenarioBlock) => {
                return {
                    id:scenarioBlock.id,
                    name:scenarioBlock.name
                }
            })

        return _minimumScenarioBlockList;


    },[scenarioData])

    const handleActionDetailToUpdate = (newActionDetail:ActionDetail) => {
        const _newAction:Action = {
            ...action,
            actionDetail:{...newActionDetail}
        };
        setActionToUpdate(_newAction);
    }

    const needChangeButton = useMemo(() => {

        const list = [
            'radioBranch',
            'nextBlock',
            'payment'
        ]

        return list.includes(action.type);

    },[action.type])

    const component:ReactNode = useMemo(() => {
        switch (action.type) {
            case 'address': return <AddressActionEditor />; ;
            case 'name': return <NameActionEditor />; ;
            case 'confirm': return <ConfirmActionEditor />; ;
            case 'radioBranch': return <RadioBranchActionEditor actionDetail={actionToUpdate.actionDetail as RadioBranchActionDetail} minimumScenarioBlockList={minimumScenarioBlockList}  onChange={(newActionDetail:ActionDetail) => handleActionDetailToUpdate(newActionDetail)}/>
            case 'nextBlock': return <NextBlockActionEditor actionDetail={actionToUpdate.actionDetail as NextBlockActionDetail} minimumScenarioBlockList={minimumScenarioBlockList}  onChange={(newActionDetail:ActionDetail) => handleActionDetailToUpdate(newActionDetail)}/>
            case 'payment': return <PaymentActionEditor actionDetail={actionToUpdate.actionDetail as PaymentActionDetail} onChange={(newActionDetail:ActionDetail) => handleActionDetailToUpdate(newActionDetail)}/>
            default: return <></>;
        }
    },[action,actionToUpdate])

    useEffect(() => {
        setActionToUpdate(action);
    },[action])

    return (
        <Style_GuestActionEditor colors={colors}>
          <div className="actionEditMainArea">
            {component}
          </div>
          <div className="actionEditButtonArea">
            {needChangeButton && (
                <IconTextButton text="変更" icon="edit" 
                  onClick={() => onUpdate({...actionToUpdate})}
                />
            )}
            <IconTextButton text="削除" icon="delete" 
              color={colors.error}
              ghost
              onClick={() => showDialog({
                  text:'削除してよろしいですか？',
                  onOk:() => {
                      onRemove(action);
                      closeDialog();
                  }
              })}
            />
          </div>
        </Style_GuestActionEditor>
    )
}


const Style_GuestActionEditor = styled.div<{
  colors:ThemeColor
}>`
&{
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: space-between;

  .actionEditMainArea {
    flex-grow: 1;
    overflow-y: auto;
  }

  .actionEditButtonArea {
    flex-shrink: 0;
  }

  .actionLabel {
    display: flex ;

    span {
      font-size: .9em;
      background-color: ${(props) => props.colors.main};
      color:#fff;
      border-bottom-right-radius: 4px;
      padding: .6em 2em;
    }
  }

  .actionEditButtonArea {
    margin: .8em 0;
    display: flex;
    justify-content: center;
    column-gap: 1em;

    > * {
      width: 33%;
    }
  }

}
`
