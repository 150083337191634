// style
import styled from 'styled-components';
import { useThemeColor } from 'hooks/global/color';
import { CardDesignMixin } from 'styleValues/mixins';

// router
import {Link} from 'react-router-dom';

// element component
import { ActiveIcon } from 'components/elements/icon/ActiveIcon';

// component
import { PaymentReport } from './paymentReport/PaymentReport'

type ReportPaymentPageViewProps = {
    brandId:number,
    chatbotId:number,
    scenarioId:number,
}

export const ReportPaymentPageView = ({
    brandId,
    chatbotId,
    scenarioId,
}:ReportPaymentPageViewProps) => {

    const colors:ThemeColor = useThemeColor();

    return (
        <Style_ReportPaymentPageView colors={colors}>
          {/* <div className="scenarioControlArea">
            <Link className="backButton" 
              to={`/settings/brands/${brandId}/chatbots/${chatbotId}/`}
              state={{ isBackFromScenario: true }}
            >
              <ActiveIcon iconName='left'/>
            </Link>
          </div> */}

          <div className="scenarioReportMainArea">
            <PaymentReport />
          </div>
        </Style_ReportPaymentPageView>
    )
}

const Style_ReportPaymentPageView = styled.div<{
    colors:ThemeColor
}>`
  & {
    max-width:1200px;
    margin: 0 auto;
    /* display: flex;
    flex-flow: column;
    gap: 24px;

    .scenarioControlArea {
      ${CardDesignMixin}
      height: 2.4em;

      display: flex;
      align-items: center;
      gap:8px;

      .backButton {
        font-size: 16px;
        width: 3em;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    } */

    .scenarioReportMainArea {
      flex-grow: 1;
      ${CardDesignMixin}
      padding: 40px;
    }
  }

`