
export const talkActionMock = (text:string):CB_Action_Backend => {

    return {
        id:'test_talk_' + new Date().getTime(),
        type:'talk',
        action_detail:{
            text
        }
    }
}
