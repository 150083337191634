// style
import styled from 'styled-components';
import { useThemeColor } from 'hooks/global/color';

// hooks
import { useModal } from 'hooks/contexts/modal';

// element component
import { ActiveIcon } from 'components/elements/icon/ActiveIcon';

// general component
import { ImageControllPanel } from 'components/generals/ImageControllPanel';

type ScenarioOperatorProps = {
    brandId:number,
    name:string,
    imageUrl?:string,
    onUpdateImage:(url:string) => void,
    onUpdateName:(name:string) => void
}

export const ScenarioOperator = ({
    brandId,
    name,
    imageUrl = '/img/default/operator.png',
    onUpdateImage,
    onUpdateName
}:ScenarioOperatorProps) => {

    const colors:ThemeColor = useThemeColor();

    const { showModal,closeModal } = useModal();

    return (
        <Style_ScenarioOperator colors={colors}>
          <div className="operatorHead">
            <span>オペレーター</span>
          </div>
          <div className="operatorBody">
            <div className="operatorImage">
            <div className="operatorImageBox"
              onClick={() => {
                  showModal({
                      contents:<ImageControllPanel 
                                brandId={brandId} 
                                type="operator" 
                                onOk={(url:string) => {
                                    onUpdateImage(url);
                                    closeModal();
                                }}
                                onCancel={() => closeModal()}
                              />,
                  })
              }}
            >
              <img src={imageUrl}/>
              <span className="imageEditButton">
                <ActiveIcon iconName='edit' />
              </span>
            </div>
            </div>
            {name && (
              <div className="operatorName">
                <span>{name}</span>
              </div>
            )}
          </div>
        </Style_ScenarioOperator>
    )
}

const Style_ScenarioOperator = styled.div<{
    colors:ThemeColor
}>`
& {
  height: 100%;
  font-size: 0.9em;
  display: flex;
  flex-flow: column;
  justify-content: space-between;

  .operatorHead {
    flex-shrink: 0;
    border-bottom:1px solid ${(props) => props.colors.border};
    text-align:center;
    padding: .8em 0;
    font-weight: bold;
  }

  .operatorBody {
    flex-grow: 1;
    padding: 0 6%;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    row-gap: .4em;


    .operatorImage {
      display: flex;
      justify-content: center;
      width: 72px;
      height: 72px;

      .operatorImageBox{
        border-radius: 100%;
        border: 1px solid ${(props) => props.colors.lightBorder};
        overflow: hidden;
        position: relative;

        img {
          width:100%;
          height: 100%;  
        }

        .imageEditButton {
          position: absolute;
          top:0;left:0;right:0;bottom:0;
          font-size: 20px;
          color: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          opacity: 0;
          cursor: pointer;
          transition: opacity .1s;

          > * {
            // 重ねがけで影を濃くする
            filter: drop-shadow(0px 0px 2px #000) drop-shadow(0px 0px 2px #000);
          }
          
          &:hover {
            opacity: 1;
            backdrop-filter: brightness(.8);
          }

        }
      }
    
    }

    .operatorName {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

}

`

