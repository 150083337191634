import {talkActionTransformer} from './actions/talkActionTransformer'
import {radioBranchActionTransformer} from './actions/radioBranchActionTransformer'
import {nextBlockActionTransformer} from './actions/nextBlockActionTransformer'
import {paymentActionTransformer} from './actions/paymentActionTransformer'

export const actionTransformer = (frontendData:Action):Action_Backend => {

    if(frontendData.type === 'talk') return talkActionTransformer(frontendData as TalkAction);
    if(frontendData.type === 'radioBranch') return radioBranchActionTransformer(frontendData as RadioBranchAction);
    if(frontendData.type === 'nextBlock') return nextBlockActionTransformer(frontendData as NextBlockAction);
    if(frontendData.type === 'payment') return paymentActionTransformer(frontendData as PaymentAction);

    // そのほか、単純なやつ
    if(frontendData.type === 'name') return {id:frontendData.id,type:'name'};
    if(frontendData.type === 'address') return {id:frontendData.id,type:'address'};
    if(frontendData.type === 'confirm') return {id:frontendData.id,type:'confirm'};

    // 当てはまらなかった場合はとりあえず talk で返しておく
    return {
        id:'0',
        type:'talk',
        action_detail:{
            text:'不明なアクションです'
        }
    };
}
