import { FC,ReactNode } from 'react';

import { ModalStore } from './modal'
import { DialogStore } from './dialog'


export const GlobalContext = ({children}:any) => {

  const CombinedComponents:FC<StoreComponentProps> = combineComponents([
      ModalStore,
      DialogStore,
  ])

  return (
    <>
      <CombinedComponents>
        {children}
      </CombinedComponents>
    </>
  )
}


/**
 * コンポーネントを入れ子にしてまとめる
 * 参考元：https://medium.com/front-end-weekly/how-to-combine-context-providers-for-cleaner-react-code-9ed24f20225e
 */
type StoreComponentProps = {
    children?:ReactNode;
}

const combineComponents = (components: FC<StoreComponentProps>[]): FC => {
  return components.reduce(
      (AccumulatedComponents, CurrentComponent) => {
          return ({children}: StoreComponentProps): JSX.Element => {
              return (
                  <AccumulatedComponents>
                    <CurrentComponent>{children}</CurrentComponent>
                  </AccumulatedComponents>
              );
          };
      },
      ({ children }: StoreComponentProps) => <>{children}</>,
  );
};

