import { useEffect, useState } from 'react';

// style
import styled from 'styled-components';
import { useThemeColor } from 'hooks/global/color';
import { CardDesignMixin } from 'styleValues/mixins'

// router
import {Link} from 'react-router-dom';
import { useNavigate,useLocation } from "react-router-dom";

// element component
import { TabSelector,TabData } from 'components/elements/ui/TabSelector';
import { ActiveIcon } from 'components/elements/icon/ActiveIcon';

// component
import { ChatbotBaseInfo } from './chatbotBaseInfo/ChatbotBaseInfo';
import { ScenarioList } from './scenarioList/ScenarioList';

type SettingChatbotPageViewProps = {
    brandId:number,
    chatbotId:number
}

export const SettingChatbotPageView = ({
    brandId,
    chatbotId
}:SettingChatbotPageViewProps) => {

    const colors:ThemeColor = useThemeColor();

    // router
    const navigate = useNavigate();
    const location = useLocation();

    // static
    const tabDataList:TabData[] = [
        {code:'baseInfo',name:'基本情報'},
        {code:'scenarioList',name:'シナリオ'},
    ]

    // state
    const [currentTabCode,setCurrentTabCode] = useState<string>('baseInfo');

    // useEffect
    useEffect(() => {
        if(location.state) {
            if((location.state as any).isBackFromScenario) setCurrentTabCode('scenarioList')
        };
    },[location.state])


    return (
        <Style_SettingChatbotPageView colors={colors}>

          <div className="chatbotTabSelectorArea">
            <Link className="backButton" to={'/settings/brands/' + brandId + '/chatbots'}>
              <ActiveIcon iconName='left'/>
            </Link>
            <TabSelector 
              tabDataList={tabDataList} 
              currentTabCode={currentTabCode}
              onSelect={(tabCode:string) => setCurrentTabCode(tabCode)}
            />
          </div>

          <div className="chatbotMainArea">
            { currentTabCode === 'baseInfo' && (
                <ChatbotBaseInfo 
                  chatbotId={chatbotId}
                />
            )}
            { currentTabCode === 'scenarioList' && (
                <ScenarioList 
                  chatbotId={chatbotId}
                  onScenarioSelect={(scenarioId:number) => {
                      navigate(`scenarios/${scenarioId}`);
                  }}
                />
            )}
          </div>

        </Style_SettingChatbotPageView>
    )
}

const Style_SettingChatbotPageView = styled.div<{
  colors:ThemeColor
}>`

  & {
    max-width:1080px;
    margin: 0 auto;
    display: flex;
    flex-flow: column;
    gap: 24px;

    .chatbotTabSelectorArea {
      ${CardDesignMixin}

      display: flex;
      align-items: center;
      gap:8px;

      .backButton {
        font-size: 16px;
        width: 3em;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .chatbotMainArea {
      flex-grow: 1;
      ${CardDesignMixin}
      padding: 40px;
    }
  }
`