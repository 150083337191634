// style
import styled from 'styled-components';
import { useThemeColor } from 'hooks/global/color';

// hooks
import { useModal } from 'hooks/contexts/modal';

// element component
import {TextInputElement} from 'components/elements/inputs/TextInputElement';
import {IconTextButton} from 'components/elements/ui/IconTextButton';
import { useEffect, useState } from 'react';


type OneLineInputPanelProps = {
    okButtonText?:string,
    initialText?:string,
    placeholder?:string,
    onOkButtonClicked?:(text:string) => void
}

export const OneLineInputPanel = ({
    okButtonText = 'OK',
    initialText = '',
    placeholder = '',
    onOkButtonClicked = (text:string) => {}
}:OneLineInputPanelProps) => {

    const colors:ThemeColor = useThemeColor();

    const {closeModal} = useModal();

    const [text,setText] = useState<string>('');

    return (
        <Style_OneLineInputPanel colors={colors}>
          <div className="textInputArea">
            <TextInputElement 
              initialValue={initialText}
              placeholder={placeholder}
              onChange={(value) => setText(value)}
            />
          </div>
          <div className="buttonArea">
            <IconTextButton text={okButtonText} onClick={() => onOkButtonClicked(text)}/>
            <IconTextButton text="キャンセル" ghost onClick={() => closeModal()}/>
          </div>
        </Style_OneLineInputPanel>
    )
}



const Style_OneLineInputPanel = styled.div<{
  colors:ThemeColor
}>`
  & {
    width:400px;

    .textInputArea {
      > input {
        width:100%;
      }
    }

    .buttonArea {
      margin-top: 2em;
      display: flex;
      justify-content: space-evenly;
      align-items: center;

      > * {
        width: 40%;
      }
    }
  }
`
