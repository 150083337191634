export const nextBlockActionTransformer = (actionBackend:Action_Backend):NextBlockAction => {

    const actionDetailBackend = actionBackend.action_detail as NextBlockActionDetail_Backend;

    const nextBlockActionDetail:NextBlockActionDetail = {
        nextScenarioBlockId:actionDetailBackend.next_scenario_block_id
    }

    const nextBlockAction:NextBlockAction = {
        id:actionBackend.id,
        type:'nextBlock',
        actionDetail:nextBlockActionDetail
    }

    return nextBlockAction;
    
}