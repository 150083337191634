// style
import styled from 'styled-components';
import { useThemeColor } from 'hooks/global/color';


type PopupSelectorProps = {

}
export const PopupSelector = ({}:PopupSelectorProps) => {

    const colors:ThemeColor = useThemeColor();

    const popupList:Popup[] = [
        {id:3,name:'ポップアップ3',imageSrc:'/img/mocks/popup.jpg'},
        {id:4,name:'ポップアップ4',imageSrc:'/img/mocks/popup.jpg'},
        {id:5,name:'ポップアップ5',imageSrc:'/img/mocks/popup.jpg'},
        {id:6,name:'ポップアップ6',imageSrc:'/img/mocks/popup.jpg'},
        {id:7,name:'ポップアップ7',imageSrc:'/img/mocks/popup.jpg'},
    ];

    return (
        <Style_PopupSelector colors={colors}>
          <div className="popupList">
            {popupList.map((popup:Popup) => {
                return (
                    <div key={popup.id} className="popupUnit"
                      onClick={() => {}}
                    >
                      <img src={popup.imageSrc} />
                      <div>{popup.name}</div>
                    </div>
                )
            })}
          </div>
        </Style_PopupSelector>
    )
}

const style_popupWidth = '120px';

const Style_PopupSelector = styled.div<{
    colors:ThemeColor
}>`

& {
  .popupList {

    display:grid;
    row-gap: 32px;
    column-gap: 24px;
    grid-template-columns: repeat(4,${style_popupWidth});

    .popupUnit {

      cursor: pointer;

      &:hover {
        opacity: .7;
      }

      &:active {
        transform: translateY(2px);
      }

      > img {
        width: ${style_popupWidth};
        height: ${style_popupWidth};
        object-fit: cover;
      }

      > div {
        font-size: .8em;
        font-weight: bold;
        line-height: 2;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      
    }
  }
}

`
