// router
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';


export const SettingBrandPage = () => {

    const navigate = useNavigate();

    // 問答無用で lp リストへ
    useEffect(() => {
        navigate('lps');
    },[])

    return (
        <></>
    )
}