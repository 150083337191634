/**
 * 　シナリオアクションの静的な設定値を返す
 */

import { useMemo } from "react";


// ホスト側アクションのリスト
const hostActionList:ActionStatic[] = [
    {type:'talk',label:'トーク',description:'オペレーターが発言します'},
    {type:'image',label:'画像',description:'画像を表示します'}
]

// ゲスト側アクションのリスト
const guestActionList:ActionStatic[] = [
    {type:'address',label:'住所入力',description:'住所を入力させるためのフォームを出現させます'},
    {type:'name',label:'名前入力',description:'名前を入力させるためのフォームを出現させます'},
    {type:'confirm',label:'入力値の確認',description:'入力した値を一覧で表示し確認させます'},
    {type:'radioBranch',label:'選択型ブロック移動',description:'任意のシナリオブロックへの分岐を作ります'},
    {type:'nextBlock',label:'指定ブロックへ移動',description:'任意のシナリオブロックへ移動します'},
    {type:'payment',label:'決済方法選択&入力',description:'決済方法を選択し、必要情報を入力させます'},
]



// hooks として出す
export const useActionConfig = () => {

    /**
     * type をキーとしたひとつのオブジェクトとして actionリストを作る
     * {
     *    talk : { type: 'talk', label:'会話'},
     *    image : {...},
     *    ...
     * }
     * みたいな感じ
     */
    const hostActionObject = useMemo(() => {
        const object:any = {};
        hostActionList.forEach((actionObject:ActionStatic) => {
            // type 名をキーとして格納
            const typeNameAsKey = actionObject.type;
            object[typeNameAsKey] = actionObject;
        })
        return object;
    },[hostActionList])

    /**
     * type をキーとしたひとつのオブジェクトとして actionリストを作る
     */
    const guestActionObject = useMemo(() => {
        const object:any = {};
        guestActionList.forEach((actionObject:ActionStatic) => {
            // type 名をキーとして格納
            const typeNameAsKey = actionObject.type;
            object[typeNameAsKey] = actionObject;
        })
        return object;
    },[guestActionList])


    /**
     * host と guest をマージ
     */
    const actionObject = useMemo(() => {

        const mergedObject = {
            ...hostActionObject, 
            ...guestActionObject
        }

        return mergedObject;

    },[hostActionObject,guestActionObject])


    const hostActionTypeList:string[] = useMemo(() => {
        return hostActionList.map((hostAction:ActionStatic) => {
            return hostAction.type
        })
    },[hostActionList]);



    return {
        hostActionList,
        hostActionTypeList,
        hostActionObject,

        guestActionList,
        guestActionObject,

        actionObject
    }
}