import { css } from 'styled-components';

import { useThemeColor } from 'hooks/global/color'

export const CardDesignMixin = () => {

    const colors:ThemeColor = useThemeColor();
  
    return css`
        background-color: ${colors.background};
        border-radius: 8px;
        box-shadow: 0 2px 4px 0px rgba(0,0,0,.04);
    `
}
