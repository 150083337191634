// hooks
import { useThemeColor } from 'hooks/global/color'

import styled from 'styled-components'

// components
import {NavigationModule} from 'components/layouts/WorkspaceModules/navigation/NavigationModule'

type WorkspaceLayoutProps = DefaultLayoutProps & {};

export const WorkspaceLayout = ({
    routeName,
    pageComponent
}:WorkspaceLayoutProps) => {


    const colors:ThemeColor = useThemeColor();

    const PageComponent = pageComponent; // 格納

    return (
        <Style_layout colors={colors}>
          <div className={'mainArea'}>
            <div className="navigationWrapper">
              <NavigationModule 
                routeName={routeName}
              />
            </div>
            <div className="mainWrapper">
              <PageComponent />
            </div>
          </div>
        </Style_layout>
    )
}

const Style_layout = styled.div<{
    colors:ThemeColor
}>`
  & {
    .mainArea {
      display:flex;
      justify-content:space-between;

      .navigationWrapper {
        flex-shrink:0;
        height:94vh;
        margin:3vh 0 0;
        max-height:800px;
      }
  
      .mainWrapper {
        flex-grow:1;
        height:100vh;
        overflow-y:auto;
        padding:24px 3%;
      }
    }
  }
`;


