// contexts
import { useDialog } from 'hooks/contexts/dialog'

// style
import styled from 'styled-components';
import { useThemeColor } from 'hooks/global/color';
import { zIndex } from 'styleValues/z-index'

// element component
import { IconTextButton } from 'components/elements/ui/IconTextButton';


export const Dialog = () => {

    const colors:ThemeColor = useThemeColor();

    const {dialogState,closeDialog} = useDialog();

    return (
      <Style_Dialog colors={colors} 
        className={!(dialogState.isShown) ? 'isHidden' : ''} 
      >
        <div className="dialogBase">
          <div className='textArea'>{dialogState.text}</div>
          <div className="dialogButtonArea">
            <IconTextButton text="OK" onClick={dialogState.onOk}/>
            <IconTextButton text="キャンセル" ghost onClick={closeDialog}/>
          </div>
        </div>
      </Style_Dialog>
    )
}

const Style_Dialog = styled.div<{
  colors:ThemeColor
}>`

&{
  position: fixed;
  top:0;left:0;bottom:0;right:0;
  z-index: ${zIndex.dialog};
  background-color: rgba(0,0,0,.6);
  backdrop-filter: blur(8px);
  display: flex;
  justify-content: center;
  align-items: center;

  transition: opacity .1s;

  &.isHidden {
    opacity: 0;
    pointer-events: none;
  }

  .dialogBase {
    max-width: 400px;
    padding: 40px 32px;
    border-radius: 8px;
    box-shadow: 0 2px 8px 0px rgba(0,0,0,.1);
    background-color:${(props) => props.colors.background} ;

    .textArea {
      white-space: pre-wrap;
      margin-bottom: 2em;
      line-height: 1.6;
    }

    .dialogButtonArea {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      gap:1em;

      > * {
        width: 144px;
      }
    }
  }
}
`