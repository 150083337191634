import { ChatbotRoot } from 'chatbotSrc/components/ChatbotRoot';

import { useGetSourceHooks,CB_ChatbotRootHooks } from './getSource';

// test
import { StripeTest } from './StripeTest';
export const ChatbotPage = () => {

    const { scenarioData }:CB_ChatbotRootHooks = useGetSourceHooks();

    return (
        <ChatbotRoot 
          scenarioData={scenarioData}
        />
        // <StripeTest/>
    )
}