import { useState } from 'react';

// style
import styled from 'styled-components';
import { useThemeColor } from 'hooks/global/color';

// element compoenent
import { LoadingIcon } from 'components/elements/icon/LoadingIcon';


type ImageGalleryProps = {
    type:ImageType,
    imageList:Image[] | undefined,
    selectedImage:Image | undefined,
    setSelectedImage:(image:Image) => void
}

export const ImageGallery = ({
    type,
    imageList,
    selectedImage,
    setSelectedImage
}:ImageGalleryProps) => {

    const colors:ThemeColor = useThemeColor();

    const isSelected = (image:Image):boolean => {
        if(selectedImage && selectedImage.url === image.url){
            return true;
        }
        return false;
    }

    return (
        <Style_ImageGallery colors={colors}>

          { imageList !== undefined ? (

              (imageList.length > 0) ? (
                  <div className="imageGallery">
                    {imageList.map((image:Image) => {
                        return (
                          <div 
                            key={image.id}
                            className={
                                'imageBaseArea' + 
                                (isSelected(image) ? ' selected' : '' ) +
                                ' ' + type
                            }
                            onClick={() => setSelectedImage(image)}
                          >
                            <div className="imageBox">
                              <img key={image.id} src={image.url} />
                            </div>
                          </div>
                        );
                    })}
                  </div>
              ) : (
                  <div className="isEmpty">画像が登録されていません</div>
              )

          ) : (
              <div className="loadingBox">
                <LoadingIcon icon="ripple" color={colors.main} />
              </div>
          )}

          
        </Style_ImageGallery>
    )
}

const Style_ImageGallery = styled.div<{
  colors:ThemeColor
}>`
& {
  height: 100%;

  .isEmpty {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: .9em;
  }

  .imageGallery {
    display: grid;
    row-gap: 32px;
    column-gap: 24px;
    grid-template-columns: repeat(auto-fill,minmax(120px,1fr));

    > .imageBaseArea {
      padding-bottom: 100%;
      height: 0%;
      position: relative;
      cursor: pointer;

      > .imageBox {
        position: absolute;
        top:0;left:0;right:0;bottom:0;
        padding: 6px;
        border: 3px solid transparent;
        border-radius: 6px;
        
        > img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          box-sizing: content-box;
        }
      }

      &.operator {
        > .imageBox {
          border-radius: 100%;
          overflow: hidden;
          > img {
            border-radius: 100%;
          }
        }
      }

      &:hover {
        opacity: .7;
      }

      &:active {
        transform: scale(.98);
      }

      &.selected {
        > .imageBox {
          border-color: ${(props) => props.colors.main};
        }
      }

    }
  }

  .loadingBox {
    width:100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

}

`
