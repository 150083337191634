import { actionTransformer } from './actionTransformer'

export const scenarioBlockTransformer = (scenarioBlockFrontend:ScenarioBlock):ScenarioBlock_Backend => {

  const actionList:Action_Backend[] = scenarioBlockFrontend.actionList.map((action:Action) => {
      return actionTransformer(action);
  })

  return {
      id:scenarioBlockFrontend.id,
      name:scenarioBlockFrontend.name,
      action_list:actionList
  }

}
