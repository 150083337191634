type DefaultLayoutProps = {
  routeName:string,
  pageComponent:React.VFC
}

export const DefaultLayout = ({
  routeName,
  pageComponent
}:DefaultLayoutProps) => {

  const PageComponent = pageComponent; // 格納
  
  return (
      <PageComponent />
  )
}