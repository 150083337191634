export const nextBlockActionTransformer = (actionFrontend:NextBlockAction):Action_Backend => {

    const actionDetailFrontend = actionFrontend.actionDetail as NextBlockActionDetail;

    const nextBlockActionDetail:NextBlockActionDetail_Backend = {
        next_scenario_block_id:actionDetailFrontend.nextScenarioBlockId
    }

    const nextBlockAction:Action_Backend = {
        id:actionFrontend.id,
        type:'next_block',
        action_detail:nextBlockActionDetail
    }

    return nextBlockAction;

}