import { useState } from 'react';

// style
import styled from 'styled-components';
import { useThemeColor } from 'hooks/global/color';
import { CardDesignMixin } from 'styleValues/mixins';
import { controlAreaHeight,controlAreaGapHeight,mainWrapperPadding } from 'styleValues/layouts';

// router
import {Link} from 'react-router-dom';


// element component
import { ActiveIcon } from 'components/elements/icon/ActiveIcon';
import { TabSelector,TabData } from 'components/elements/ui/TabSelector';

// component
import { PopupPreview } from './PopupPreview'
import { PopupBaseInfo } from './popupBaseInfo/PopupBaseInfo'


type SettingPopupPageViewProps = {
    brandId:number,
    popupId:number
}

export const SettingPopupPageView = ({
    brandId,
    popupId
}:SettingPopupPageViewProps) => {

    const colors:ThemeColor = useThemeColor();

    // tab
    const [currentTabCode,setCurrentTabCode] = useState<string>('baseInfo');

    const tabDataList:TabData[] = [
        {code:'baseInfo',name:'基本情報'},
        {code:'email',name:'メールアドレス収集'},
    ];


    // state

    const imageUrl:string = '/img/mocks/popup.jpg';
    const [hasEmailLog,setHasEmailLog] = useState<boolean>(false);
    const emailImageUrl:string = '/img/mocks/popup_email.jpg';
    const [emailPlaceholder,setEmailPlaceholder] = useState<string>('メールアドレス');
    const [emailButtonDisplay,setEmailButtonDisplay] = useState<string>('クーポンGET');
    const [emailTermsUrl,setEmailTermsUrl] = useState<string>('https://google.com');
    const [emailTermsString,setEmailTermsString] = useState<string>('利用規約に同意して送信します');

    return (
        <Style_SettingPopupPageView colors={colors}>
          <div className="popupControlArea">
            <Link className="backButton" to={'/settings/brands/' + brandId + '/popups'}>
              <ActiveIcon iconName='left'/>
            </Link>
            {/* <TabSelector 
              tabDataList={tabDataList} 
              currentTabCode={currentTabCode}
              onSelect={(tabCode:string) => setCurrentTabCode(tabCode)}
            /> */}

          </div>

          <div className="popupEditArea">
            <div className="popupPreviewArea">
              <PopupPreview
                imageUrl={imageUrl}
                hasEmailLogFunction={hasEmailLog}
                emailImageUrl={emailImageUrl}
                emailPlaceholder={emailPlaceholder}
                emailButtonDisplay={emailButtonDisplay}
                emailTermsUrl={emailTermsUrl}
                emailTermsString={emailTermsString}
              />
            </div>

            <div className="popupMainArea">
              <PopupBaseInfo 
                popupId={popupId}
                imageUrl={imageUrl}
                hasEmailLog={hasEmailLog}
                emailImageUrl={emailImageUrl}
                emailPlaceholder={emailPlaceholder}
                emailButtonDisplay={emailButtonDisplay}
                emailTermsUrl={emailTermsUrl}
                emailTermsString={emailTermsString}
                onEmailStateChange={(state:boolean) => setHasEmailLog(state)}
              />
            </div>
          </div>
        </Style_SettingPopupPageView>
    )
}

// const controlAreaHeight = '40px';
// const controlAreaGapHeight = '24px';
// const mainWrapperPadding = '24px'; // これは一応ここに書くが、グローバルに設定した方がいいと思う。

const Style_SettingPopupPageView = styled.div<{
  colors:ThemeColor,
}>`

  & {
    max-width:1080px;
    margin: 0 auto;
    display: flex;
    flex-flow: column;
    gap: ${controlAreaGapHeight};

    .popupControlArea {
      ${CardDesignMixin}
      height: ${controlAreaHeight};
      display: flex;
      align-items: center;
      gap:8px;

      .backButton {
        font-size: 16px;
        width: 3em;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .popupEditArea {

      display: flex;
      align-items: flex-start;
      gap:1em;

      .popupPreviewArea {
        flex-shrink: 0;
        width: 320px;
      }

      .popupMainArea {
        flex-grow: 1;
        ${CardDesignMixin}
        padding: 40px;
        height: calc(100vh - ${controlAreaHeight} - ${controlAreaGapHeight} - ${mainWrapperPadding} - ${mainWrapperPadding});
        overflow-y: auto;
      }
    }

  }
`