import { useEffect, useState } from 'react';

// style
import styled from 'styled-components';
import { useThemeColor } from 'hooks/global/color';

// hooks
import { useModal } from 'hooks/contexts/modal';
import { useDialog } from 'hooks/contexts/dialog';

// icon
import { ActiveIcon } from 'components/elements/icon/ActiveIcon'

// component
import { PopupSelector } from './PopupSelector';
import { LpPopupDisplay } from './LpPopupDisplay';


export const PopupForLp = () => {

    const colors:ThemeColor = useThemeColor();

    const {showModal} = useModal();
    const {showDialog} = useDialog();

    const [selectedPopup,setSelectedPopup] = useState<PopupForLp | undefined>();

    const [popupList,setPopupList] = useState<PopupForLp[]>([]);

    useEffect(() => {
        getPopupList().then((popupList:PopupForLp[]) => {
            setPopupList(popupList);
            setSelectedPopup(popupList[0]);
        })
    },[])

    // add
    const showPopupSelectorModal = () => {
        showModal({
            contents:<PopupSelector />,
            baseStyle:'standard'
        })
    }
    

    const removePopup = (popup?:Popup) => {        
        if(popup){
            showDialog({
                text:popup.name + 'をけす？'
            });
        }
    }


    const getPopupList = ():Promise<PopupForLp[]> => {
        return new Promise((resolve) => {

            const mockdata_popupList:PopupForLp[] = [
                { 
                  id:1,name:'ポップアップ1',imageSrc:'/img/mocks/popup.jpg',appearanceRate:50,
                  trigger:{
                      scroll:{isEnabled:true,threshold:50},
                      time:{isEnabled:true,seconds:20},
                      backAction:{isEnabled:true},
                  }
                },
                { 
                  id:2,name:'ポップアップ2',imageSrc:'/img/mocks/popup.jpg',appearanceRate:30,
                  trigger:{
                      scroll:{isEnabled:false,threshold:50},
                      time:{isEnabled:false,seconds:20},
                      backAction:{isEnabled:true},
                  }
                },
                { 
                  id:3,name:'ポップアップ3',imageSrc:'/img/mocks/popup.jpg',appearanceRate:0,
                  trigger:{
                      scroll:{isEnabled:true,threshold:90},
                      time:{isEnabled:true,seconds:10},
                      backAction:{isEnabled:false},
                  }
                },
            ]
      
            resolve(mockdata_popupList);

        })
    }


    return (
        <Style_PopupForLp colors={colors}>
          <div className="popupSelectorArea">
            <div className="popupList">
              {selectedPopup && 
                <>
                  {popupList.map((popup:PopupForLp) => {
                      return (
                          <div 
                            key={popup.id}
                            className={'popupButton' + ( (selectedPopup.id === popup.id) ? ' selected' : '' )}
                            onClick={() => setSelectedPopup(popup)}
                          >
                            <span>{popup.name}</span>
                            <span>{popup.appearanceRate}%</span>
                          </div>
                      )
                  })}
                </>
              }
            </div>
            <div className="popupFunctions">
              <div className="addPopupButton" onClick={() => showPopupSelectorModal()}>
                <ActiveIcon iconName='plus' />
              </div>
              <div className="removePopupButton" onClick={() => removePopup(selectedPopup)}>
                <ActiveIcon iconName='minus' />
              </div>
            </div>
          </div>
          <div className="popupDisplayArea">
            {selectedPopup && (
              <LpPopupDisplay popup={selectedPopup} />
            )}
          </div>
        </Style_PopupForLp>
    )
}

const Style_PopupForLp = styled.div<{
    colors:ThemeColor
}>`

& {
  height: 400px;
  display: flex;
  column-gap: 1.6em;

  .popupSelectorArea {
    flex-shrink: 0;
    width:220px;

    display: flex;
    flex-flow: column;

    .popupList {
      flex-grow: 1;

      display: flex;
      flex-flow: column;
      gap:.4em;

      .popupButton {
        font-size:.9em;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap:1em;
        height: 2.8em;
        border-radius: 4px;
        padding: 0 .6em;
        cursor:pointer;

        &.selected {
          background-color: ${(props) => props.colors.main}!important;
          color:#fff;
          font-weight: bold;
        }

        &:hover {
          background-color: ${(props) => props.colors.deeperBackground};
        }

        > span:first-child{
          flex-grow: 1;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        > span:last-child{
          flex-shrink:0;
        }
      }
    }

    .popupFunctions {
      flex-shrink: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .addPopupButton, .removePopupButton {
        width:3em;
        height:2em;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: ${(props) => props.colors.shadowOnBackground};
        border-radius: 4px;
        cursor: pointer;

        &:active{
          transform: translateY(1px);
        }
      }
    }
    
  }

  .popupDisplayArea {
    flex-grow: 1;
  }

}

`
