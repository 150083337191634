// style
import styled from 'styled-components';
import { useThemeColor } from 'hooks/global/color';

// hooks
import { useModal } from 'hooks/contexts/modal';

// element component
import {TextInputElement} from 'components/elements/inputs/TextInputElement';
import {IconTextButton} from 'components/elements/ui/IconTextButton';


type LpNameEditPanelProps = {
    name:string
}

export const LpNameEditPanel = ({
    name
}:LpNameEditPanelProps) => {

    const colors:ThemeColor = useThemeColor();

    const {closeModal} = useModal();

    return (
        <Style_LpNameEditPanel colors={colors}>
          <div className="nameInputArea">
            <TextInputElement 
              initialValue={name}
              placeholder="LP名"
            />
          </div>
          <div className="buttonArea">
            <IconTextButton text="更新"/>
            <IconTextButton text="キャンセル" ghost onClick={() => closeModal()}/>
          </div>
        </Style_LpNameEditPanel>
    )
}



const Style_LpNameEditPanel = styled.div<{
  colors:ThemeColor
}>`
  & {
    width:400px;

    .nameInputArea {
      > input {
        width:100%;
      }
    }

    .buttonArea {
      margin-top: 2em;
      display: flex;
      justify-content: space-evenly;
      align-items: center;

      > * {
        width: 40%;
      }
    }
  }
`
