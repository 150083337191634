export const radioBranchActionTransformer = (actionFrontend:RadioBranchAction):Action_Backend => {

    const actionDetailFrontend = actionFrontend.actionDetail as RadioBranchActionDetail;

    const radioList:RadioBranchObject_Backend[] = actionDetailFrontend.radioList.map((radio:RadioBranchObject) => {
        return {
            id:radio.id,
            label:radio.label,
            next_scenario_block_id:radio.nextScenarioBlockId
        }
    })

    const radioBranchActionDetail:RadioBranchActionDetail_Backend = {
        radio_list:radioList
    }

    const radioBranchAction:Action_Backend = {
        id:actionFrontend.id,
        type:'radio_branch',
        action_detail:radioBranchActionDetail
    }

    return radioBranchAction;

}