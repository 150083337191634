import { useEffect, useState, useMemo } from "react";

// api
import { useScenarioRestApiHooks } from 'api/rest/scenario'


type SettingScenarioPageHooksProps = {
    brandId:number,
    chatbotId:number,
    scenarioId:number
}
export const useSettingScenarioPageHooks = ({
    brandId,
    chatbotId,
    scenarioId
}:SettingScenarioPageHooksProps) => {

    const scenarioRestApiHooks:ScenarioRestApiHooks = useScenarioRestApiHooks();

    const [editStarted,setEditStarted] = useState<boolean>(false);

    const [scenarioData,setScenarioData] = useState<ScenarioWithActions | undefined>(undefined);

    const [selectedScenarioBlockId,setSelectedScenarioBlockId] = useState<string>('');

    const [selectedAction,setSelectedAction] = useState<Action | undefined>(undefined);

    const selectedScenarioBlock:ScenarioBlock | undefined = useMemo(() => {

        if(scenarioData === undefined) return undefined;

        const selectedScenarioBlock = scenarioData.scenarioBlockList.find((scenarioBlock:ScenarioBlock) => {
            return scenarioBlock.id === selectedScenarioBlockId;
        })

        return selectedScenarioBlock;
    },[scenarioData,selectedScenarioBlockId]);

    useEffect(() => {
        getThenSetScenarioData();
    },[scenarioId]);

    const getThenSetScenarioData = () => {
        scenarioRestApiHooks.show(brandId,chatbotId,scenarioId)
        .then((data:ScenarioWithActions) => {
            setScenarioData(data);

            setSelectedScenarioBlockId(data.scenarioBlockList[0].id);
        });
    }

    const handleOperatorUpdate = ({imageUrl,name}:{imageUrl?:string,name?:string}) => {

        if(scenarioData === undefined) return undefined;

        const scenarioDataToUpdate:ScenarioWithActions = {
            id:scenarioData.id,
            name:scenarioData.name,
            firstHeader:scenarioData.firstHeader,
            secondHeader:scenarioData.secondHeader,        
            operatorName:scenarioData.operatorName,
            operatorImageUrl:scenarioData.operatorImageUrl,
            scenarioBlockList:scenarioData.scenarioBlockList
        }


        if(imageUrl){
            scenarioDataToUpdate.operatorImageUrl = imageUrl;
        }

        if(name){
            scenarioDataToUpdate.operatorName = name;
        }

        setScenarioData(scenarioDataToUpdate);
    }

    /**
     * シナリオブロックが選択された場合の取り回し
     * @param scenarioBlockId 
     * @returns 
     */
    const handleSelectScenarioBlock = (scenarioBlockId:string) => {

        setSelectedScenarioBlockId(scenarioBlockId)

        setSelectedAction(undefined); // アクションを閉じる

    }

    const handleAddScenarioBlock = (scenarioBlockName:string) => {

        if(scenarioData === undefined) return;

        const randomString:string = Math.random().toString(32).substring(2);
        const newScenarioBlockId:string = 'new_' + randomString;

        const randomStringForAction:string = Math.random().toString(32).substring(2);
        const newActionId:string = 'new_' + randomStringForAction;

        const initialAction:Action = {
            id:newActionId,
            type:'talk',
            actionDetail:{
                text:'新しいシナリオブロック'
            }
        }

        const newScenarioBlockData:ScenarioBlock = {
            id:newScenarioBlockId,
            name:scenarioBlockName,
            actionList:[initialAction]
        }

        scenarioData.scenarioBlockList.push(newScenarioBlockData);

        setScenarioData(scenarioData);

        // 格納し直し
        setSelectedScenarioBlockId(newScenarioBlockId);

        // アクション選択
        setSelectedAction(initialAction);

        // エディット中に変更
        setEditStarted(true);
    }

    const handleEditScenarioBlock = (scenarioBlockName:string) => {

        if(scenarioData === undefined) return;
        if(selectedScenarioBlock === undefined) return;

        // アップデートするシナリオブロックを探す
        const selectedScenarioBlockIndex:number = scenarioData.scenarioBlockList.findIndex((scenarioBlock:ScenarioBlock) => {
            return scenarioBlock.id === selectedScenarioBlockId;
        })

        scenarioData.scenarioBlockList[selectedScenarioBlockIndex].name = scenarioBlockName;

        setScenarioData(scenarioData);

        // 格納し直し
        setSelectedScenarioBlockId('');
        setTimeout(() => {
            // なぜ？ というと、secnarioBlockList 内の scenarioBlockName を更新したかったため
            setSelectedScenarioBlockId(scenarioData.scenarioBlockList[selectedScenarioBlockIndex].id);
        },10);

        // エディット中に変更
        setEditStarted(true);
    }

    const handleDeleteScenarioBlock = (scenarioBlockIdToDel:string) => {

        if(scenarioData === undefined) return;

        const scenarioBlockList = scenarioData.scenarioBlockList;

        const newScenarionBlockList = scenarioBlockList.filter((scenarioBlock:ScenarioBlock) => {
            return scenarioBlock.id !== scenarioBlockIdToDel;
        });

        const newScenarioData:ScenarioWithActions = {
            id:scenarioData.id,
            name:scenarioData.name,
            firstHeader:scenarioData.firstHeader,
            secondHeader:scenarioData.secondHeader,        
            operatorName:scenarioData.operatorName,
            operatorImageUrl:scenarioData.operatorImageUrl,
            scenarioBlockList:newScenarionBlockList
        }

        setScenarioData(newScenarioData);

        // 一番上のものを選択しなおす
        const firstScenarioBlock:ScenarioBlock | undefined = newScenarioData.scenarioBlockList[0];

        if(firstScenarioBlock){
            setSelectedScenarioBlockId(firstScenarioBlock.id);
        }

        // アクション選択を切る
        setSelectedAction(undefined);


        // エディット中に変更
        setEditStarted(true);
    }

    /**
     * アクションリストの更新リクエストを受けて反映させる
     * @param newActionList 
     * @returns 
     */
    const handleUpdateActionList = (newActionList:Action[]) => {        

        if(scenarioData === undefined) return;
        if(selectedScenarioBlock === undefined) return;

        // deep copy
        const newScenarioData:ScenarioWithActions = {
            id:scenarioData.id,
            name:scenarioData.name,
            firstHeader:scenarioData.firstHeader,
            secondHeader:scenarioData.secondHeader,        
            operatorName:scenarioData.operatorName,
            operatorImageUrl:scenarioData.operatorImageUrl,
            scenarioBlockList:[
                ...scenarioData.scenarioBlockList
            ]
        }

        // アップデートするアクションリストの属するシナリオブロックを探す
        const selectedScenarioBlockIndex:number = newScenarioData.scenarioBlockList.findIndex((scenarioBlock:ScenarioBlock) => {
            return scenarioBlock.id === selectedScenarioBlock.id;
        })

        // アクションリストを更新
        newScenarioData.scenarioBlockList[selectedScenarioBlockIndex].actionList = newActionList;        

        setScenarioData(newScenarioData);

        // エディット中に変更
        setEditStarted(true);
    }

    return {
        editStarted,
        scenarioData,
        selectedScenarioBlockId,
        selectedScenarioBlock,
        selectedAction,
        setSelectedAction,
        handleOperatorUpdate,
        handleSelectScenarioBlock,
        handleAddScenarioBlock,
        handleEditScenarioBlock,
        handleDeleteScenarioBlock,
        handleUpdateActionList
    }
}