export const radioBranchActionTransformer = (actionBackend:CB_Action_Backend):CB_Action => {

    const actionDetailBackend = actionBackend.action_detail as CB_RadioBranchActionDetail_Backend;

    const radioList:CB_RadioBranchObject[] = actionDetailBackend.radio_list.map((radio:CB_RadioBranchObject_Backend) => {
        return {
            id:radio.id,
            label:radio.label,
            nextScenarioBlockId:radio.next_scenario_block_id
        }
    })

    const actionDetail:CB_RadioBranchActionDetail = {
        radioList:radioList
    }

    const radioBranchAction:CB_Action = {
        id:actionBackend.id,
        type:'radioBranch',
        actionDetail:actionDetail
    }

    return radioBranchAction;

}
