// style
import styled from 'styled-components';
import { useThemeColor } from 'hooks/global/color'

// icon
import { ActiveIcon } from 'components/elements/icon/ActiveIcon'

type IconTextButtonProps = {
    text?:string,
    size?:string,
    icon?:string,
    color?:string,
    ghost?:boolean,
    onClick?:() => void
}

export const IconTextButton = ({
    text = '',
    size = '1em',
    icon = '',
    color = undefined,
    ghost = false,
    onClick = () => {}
}:IconTextButtonProps) => {


    const themeColors:ThemeColor = useThemeColor();

    return (
          <Style_IconTextButton 
            className={ghost ? 'ghost' : 'standard'}
            style={{fontSize:size}}
            color={color ?? themeColors.main} onClick={() => onClick()}
          >
            {icon &&
              <ActiveIcon iconName={icon} className="buttonIcon" />
            }
            {text &&
              <span>{text}</span>
            } 
          </Style_IconTextButton>
    )
}

const Style_IconTextButton = styled.div<{
    color:string
}>`
  & {
    display:inline-flex;
    justify-content: center;
    align-items:center;
    gap:.4em;
    padding: .5em 1.4em;
    background-color: ${(props) => props.color};
    border:2px solid  ${(props) => props.color};
    color:#fff;
    border-radius:4px;
    line-height: 1;
    cursor:pointer;

    &:active {
      transform: translateY(2px);
    }

    .buttonIcon {
      font-size:1.2em;
    }
  }

  &.ghost {
    background-color: transparent;
    color: ${(props) => props.color};
  }
`