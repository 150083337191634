import { createContext,Dispatch,ReactNode,useContext,useReducer } from 'react'

const initialState:ModalState = {
    contents: null,
    baseStyle:undefined,
    bgClose:true
}

export const useModalContext = () => useContext(ModalContext);

export const useModal = () => {

    const setModalState = useModalContext()[1];
    const modalState = useModalContext()[0];

    const showModal = ({contents,baseStyle,bgClose}:ModalPayload) => {
        setModalState({
            actionName:'show',
            contents,
            baseStyle,
            bgClose
        })
    }

    const closeModal = () => {
        setModalState({
            actionName:'close'
        })
    }

    return {
        modalState,
        showModal,
        closeModal
    }
}

const ModalContext = createContext<
    [ModalState,Dispatch<ModalAction>]
>([initialState,() => {}]);

export const ModalStore = ({ children }:any) => {

    const [state, dispatch] = useReducer((state:ModalState, action:ModalAction) => {
        switch (action.actionName) {
            case 'show':
                return {
                    ...state,
                    contents:action.contents,
                    baseStyle:action.baseStyle ?? 'standard',
                    bgClose:action.bgClose ?? true
                }
            case 'close':
                return { 
                    ...state,
                    contents: null,
                    baseStyle: undefined,
                    bgClose:true
                }
            default:
                return state
        }
    }, initialState);


    return (
        <ModalContext.Provider value={[state, dispatch]}>
          {children}
        </ModalContext.Provider>
    )
}

