// router
import { useParams } from 'react-router-dom';

// pageView
import {SettingLpPageView} from 'components/pages/settings/lp/SettingLpPageView';


export const SettingLpPage = () => {

    const params = useParams();
    
    const brandId = parseInt(params.brandId!);
    const lpId = parseInt(params.lpId!);

    return (
        <SettingLpPageView brandId={brandId} lpId={lpId}/>
    )
}