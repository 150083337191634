import { useEffect, useState } from 'react';

// style
import styled from 'styled-components';
import { useThemeColor } from 'hooks/global/color';

// element compoenent
import { IconTextButton } from 'components/elements/ui/IconTextButton';
import { ActiveIcon } from 'components/elements/icon/ActiveIcon';

// component
import { ImageGallery } from './ImageGallery';
import { ImageAddPanel } from './ImageAddPanel';

const _images:Image[] = [
  {id:1,type:'operator',url:'/img/mocks/operator.png'},
  {id:2,type:'operator',url:'/img/mocks/lp.jpg'},
  {id:3,type:'operator',url:'/img/mocks/popup.jpg'},
  {id:4,type:'operator',url:'/img/mocks/popup_email.jpg'},
];


type ImageControllPanelProps = {
    brandId:number,
    type:ImageType,
    onOk:(imageSrc:string) => void
    onCancel:() => void
}

export const ImageControllPanel = ({
    brandId,
    type,
    onOk,
    onCancel
}:ImageControllPanelProps) => {

    const colors:ThemeColor = useThemeColor();

    const [mode,setMode] = useState<'select' | 'add'>('select');

    const [imageList,setImageList] = useState<Image[] | undefined>(undefined);

    const [selectedImage,setSelectedImage] = useState<Image | undefined>(undefined);


    useEffect(() => {
        getThenSetImages();
    },[]);

    const getThenSetImages = () => {
        setTimeout(() => {
            setImageList(_images);
        },1000)
    }

    return (
      <Style_ImageControllPanel colors={colors}>
        {mode === 'select' && (
            <>
              <div className="mainArea">
                <ImageGallery 
                  imageList={imageList} 
                  type={type}
                  selectedImage={selectedImage}
                  setSelectedImage={setSelectedImage}
                />
              </div>
              <div className="controllArea">
                <div className="addAndMinusButtonArea">
                  <div onClick={() => setMode('add')}>
                    <ActiveIcon iconName="plus" />
                  </div>
                  <div>
                    <ActiveIcon iconName="minus" />
                  </div>
                </div>
                <div>
                  <IconTextButton text="OK" onClick={() => {
                      if(selectedImage){
                          onOk(selectedImage.url)
                      }
                  }}/>
                  <IconTextButton text="キャンセル" ghost onClick={() => onCancel()}/>
                </div>
              </div>
            </>
        )}
        {mode === 'add' && (
            <>
              <ImageAddPanel 
                type={type}
                onOk={(imageSrc:string) => onOk(imageSrc)}
                onCancel={() => setMode('select')}
              />
            </>
        )}
      </Style_ImageControllPanel>
    )
}

const Style_ImageControllPanel = styled.div<{
  colors:ThemeColor
}>`

& {
  width:800px;
  min-height: 400px;
  max-height: 72vh;

  display: flex;
  justify-content: space-between;
  column-gap: 2em;

  .mainArea {
    flex-grow: 1;
    overflow-y: auto;
  }

  .controllArea {
    flex-shrink: 0;
    width: 160px;
    display: flex;
    flex-flow: column;
    justify-content: space-between;

    > div:nth-child(2){
      display: flex;
      flex-flow: column;
      row-gap: 1em;
    }

    .addAndMinusButtonArea {
      display: flex;
      justify-content: space-between;
      align-items: center;

      > div {
        border: 1px solid ${(props) => props.colors.main};
        border-radius: 4px;
        width:2.8em;
        height: 2em;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        &:hover {
          opacity: .7;
        }

        &:active {
          transform: translateY(1px);
        }
      }
    }
  }


}
`
