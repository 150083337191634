// style
import styled from 'styled-components';
import { useThemeColor } from 'hooks/global/color';

// router
import { useNavigate } from 'react-router-dom';

// hooks
import { useDialog } from 'hooks/contexts/dialog';

// element component
import { ActiveIcon } from 'components/elements/icon/ActiveIcon';
import { IconTextButton } from 'components/elements/ui/IconTextButton';


type ScenarioHeaderProps = {
    brandId:number,
    chatbotId:number,
    editStarted:boolean,
    scenarioData:ScenarioWithActions | undefined,
    onPreviewButtonClick:() => void
}

export const ScenarioHeader = ({
    brandId,
    chatbotId,
    editStarted,
    scenarioData,
    onPreviewButtonClick
}:ScenarioHeaderProps) => {

    const colors:ThemeColor = useThemeColor();

    const {showDialog,closeDialog} = useDialog();

    const navigate = useNavigate();


    const backAction = () => {
        navigate(
            `/settings/brands/${brandId}/chatbots/${chatbotId}/`,
            {
                state:{ isBackFromScenario: true }
            }
        )
    }

    return (
        <Style_SettingScenarioPageView colors={colors}>
          <div className={'backButton'}
            onClick={() => {
                if(editStarted){
                    showDialog({
                        text:'編集中ですが、更新せず戻りますか？',
                        onOk:() => {
                            backAction();
                            closeDialog();
                        }
                    })
                }else{
                    backAction();
                }
            }}
          >
            <ActiveIcon iconName='left'/>
          </div>
          {scenarioData && (
              <>
                <div className="scenarioName" onClick={() => {
                    const value = JSON.stringify(scenarioData);
                    if(navigator.clipboard) {
                        var copyText = value;
                        navigator.clipboard.writeText(copyText).then(function() {
                            alert('データをコピーしました。');
                        });
                    } else {
                        alert('対応していません。');
                    }
                }}>
                  <span>{scenarioData ? scenarioData.name : ''}</span>
                </div>
                <div className={'submitButton'}
                  onClick={() => onPreviewButtonClick()}
                >
                  <IconTextButton text="プレビュー" ghost />
                </div>
                <div className={'submitButton' + (editStarted ? '' : ' disabled')}>
                  <IconTextButton text="下書きとして保存" ghost />
                </div>
                <div className={'submitButton' + (editStarted ? '' : ' disabled')}>
                  <IconTextButton text="更新" />
                </div>
              </>
          )}
        </Style_SettingScenarioPageView>
    )
}

const Style_SettingScenarioPageView = styled.div<{
  colors:ThemeColor
}>`

& {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap:8px;
  
  .backButton {
    font-size: 16px;
    width: 3em;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .scenarioName {
    font-weight: bold;
    margin-right: auto;
  }

  .submitButton {
    font-size:.8em;
    margin-right: .8em;
    cursor: pointer;

    > * {
      width:12em;
    }

    &.disabled {
      cursor: auto;
      opacity: .4;
      pointer-events: none;
    }
  }
}
`