// router
import { useParams } from 'react-router-dom';

// pageView
import {SettingPopupPageView} from 'components/pages/settings/popup/SettingPopupPageView'

export const SettingPopupPage = () => {

    const params = useParams();
      
    const brandId = parseInt(params.brandId!);

    const popupId = parseInt(params.popupId!);

    return (
        <SettingPopupPageView brandId={brandId} popupId={popupId} />
    )
}