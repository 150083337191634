// react cropper の wrapper コンポーネント
import { useState,useCallback, useEffect } from 'react'

import Cropper,{Area} from 'react-easy-crop';

import {getCroppedImg} from './cropper'

// style
import styled from 'styled-components';
import { useThemeColor } from 'hooks/global/color';




type ImageCropper = {
    imageSrc:string,
    cropperAreaclassName?:string,
    circled:boolean,
    onCrop:(newSrc:string) => void
}

export const ImageCropper = ({
    imageSrc,
    cropperAreaclassName = '',
    circled,
    onCrop
}:ImageCropper) => {

    const colors:ThemeColor = useThemeColor();

    const minZoom = 1;
    const maxZoom = 5;

    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState<number>(1);

    const [croppedAreaPixels,setCroppedAreaPixels] = useState<Area>({x:0,y:0,width:0,height:0});

    // src もしくはクロップ status が変更になったときに画像データを作成
    useEffect( () => {
        if(croppedAreaPixels.width !== 0 && croppedAreaPixels.height !== 0){
            cropImage();
        }
    },[imageSrc,croppedAreaPixels])

    const handleCropComplete = async (croppedArea: Area, croppedAreaPixels: Area) => {
          setCroppedAreaPixels(croppedAreaPixels);
    };

    // トリミングして、親に返す
    const cropImage = async () => {
        const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels);
        onCrop(croppedImage);
    }

    return (
      <Style_ImageCropper colors={colors}>
        <div className={'cropperBaseArea' + ' ' + cropperAreaclassName}>
          <div style={{position:'relative',width:'100%',height:'100%'}}>
            <Cropper
              image={imageSrc}
              crop={crop}
              zoom={zoom}
              minZoom={minZoom}
              maxZoom={maxZoom}
              showGrid={false}
              aspect={1 / 1}
              zoomSpeed={2}
              objectFit={'auto-cover'}
              cropShape={circled ? 'round' : 'rect'}
              onCropChange={setCrop}
              onZoomChange={setZoom}
              onCropComplete={handleCropComplete}
            />
          </div>
        </div>
        <div>
          <input
            type="range"
            value={zoom}
            min={minZoom}
            max={maxZoom}
            step={0.1}
            aria-labelledby="Zoom"
            onChange={(e) => {
                setZoom( parseFloat(e.target.value) )
            }}
            className="zoom-range"
          />
        </div>
      </Style_ImageCropper>
    )
}

const Style_ImageCropper = styled.div<{
    colors:ThemeColor
}>`

& {
  display: flex;
  flex-flow: column;
  row-gap: 1em;

  > div:nth-child(1){
    flex-shrink: 0;
  }

  .cropperBaseArea {

  }

  input[type="range"]{
    appearance: none;
    cursor: pointer; 
    outline: none; 
    height: 8px; 
    width: 100%; 
    background: ${(props) => props.colors.main}; 
    border-radius: 10px; 
    border: 1px solid ${(props) => props.colors.lightBorder};

    &::-webkit-slider-thumb {
      -webkit-appearance: none; // デフォルトのつまみのスタイルを解除
      background: ${(props) => props.colors.main}; // 背景色
      width: 24px; // 幅
      height: 24px; // 高さ
      border-radius: 50%; // 円形に
      box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.15); // 影
    }
  }
}
`