import { useAjax } from "api/index";

// helper
import { scenarioTransformer } from '../transformer/toFrontend/scenario/scenarioTransformer'

export const useScenarioRestApiHooks = ():ScenarioRestApiHooks => {
  
    const {get} = useAjax({needAuth:true});

    const show = async (brandId:number,chatbotId:number,scenarioId:number):Promise<ScenarioWithActions> => {
        const path:string = `brands/${brandId}/chatbots/${chatbotId}/scenarios/${scenarioId}`;

        const response:ApiResponse = await get(path).catch(res => res);

        if(response.status >= 400){
            throw response;
        }

        const scenarioWithActionsBackend = response.data as ScenarioWithActions_Backend;
        
        // フロント用のデータに変換
        const scenarioWithActions:ScenarioWithActions = scenarioTransformer(scenarioWithActionsBackend)

        return scenarioWithActions;
    }

    return {
        show
    }
}
