import { store } from "redux/store";
import { Provider } from "react-redux";

import { Router } from "router/index";
import { GlobalStyle } from "GlobalStyle";
import { AuthProvider } from "providers/useAuth";
import { GlobalComponents } from "components/global/index";
import { Helmet, HelmetProvider } from 'react-helmet-async'

function App() {
  // TODO: Titleは環境変数を用いる
  return (
    <Provider store={store}>
      <HelmetProvider>
        <Helmet>
          <meta name="robots" content="noindex,nofollow" />
          <title>イザコザ仕訳明細書</title>
        </Helmet>
        <AuthProvider>
          <GlobalStyle />
          <Router />
          <GlobalComponents />
        </AuthProvider>
      </HelmetProvider>
    </Provider>
  );
}

export default App;
