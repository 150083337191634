// router
import { useParams } from 'react-router-dom';

// pageView
import {SettingChatbotPageView} from 'components/pages/settings/chatbot/SettingChatbotPageView'

export const SettingChatbotPage = () => {

    const params = useParams();
        
    const brandId = parseInt(params.brandId!);
    const chatbotId = parseInt(params.chatbotId!);

    return (
        <SettingChatbotPageView brandId={brandId} chatbotId={chatbotId} />
    )
}