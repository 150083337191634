// router
import { useParams } from 'react-router-dom';

// pageView
import {SettingScenarioPageView} from 'components/pages/settings/scenario/SettingScenarioPageView'

export const SettingScenarioPage = () => {

    const params = useParams();
        
    const brandId = parseInt(params.brandId!);
    const chatbotId = parseInt(params.chatbotId!);
    const scenarioId = parseInt(params.scenarioId!);

    return (
        <SettingScenarioPageView 
          brandId={brandId} 
          chatbotId={chatbotId} 
          scenarioId={scenarioId}
        />
    )
}