import { useEffect, useState,useMemo } from 'react';

// config
import { useActionConfig } from 'hooks/config/scenario/action';

// style
import styled from 'styled-components';
import { useThemeColor } from 'hooks/global/color';

// element components
import { SelectInput } from 'components/elements/inputs/SelectInput'
import { IconTextButton } from 'components/elements/ui/IconTextButton'

type AddActionPanelProps = {
    isHostAction:boolean,
    onSubmit:(actionType:Action['type']) => void,
    onCancel:() => void
}

export const AddActionPanel = ({
    isHostAction = true,
    onSubmit,
    onCancel
}:AddActionPanelProps) => {

    const colors:ThemeColor = useThemeColor();

    const { hostActionList,guestActionList } = useActionConfig();

    const [ selectedActionType,setSelectedActionType ] = useState<Action['type'] | undefined>(undefined);

    const actionList:ActionStatic[] = useMemo(() => {
      
        if(isHostAction){
            return hostActionList;
        }else {
            return guestActionList;
        }

    },[isHostAction])

    const selectOptionList = useMemo(() => {
      
        const _selectOptionList = actionList.map((actionStatic:ActionStatic) => {
            return {
                label:actionStatic.label,
                value:actionStatic.type
            }
        })

        return _selectOptionList;

    },[actionList])

    useEffect(() => {
        setSelectedActionType(selectOptionList[0].value as ActionType)
    },[selectOptionList])

    return (
      <Style_AddActionPanel colors={colors}>
        <h2>{isHostAction ? 'ホスト側' : 'ゲスト側'}アクションを追加</h2>
        <div className="actionSelectArea">
          {selectedActionType && (
              <SelectInput 
                selectOptionList={selectOptionList} 
                value={selectedActionType}
                onChange={(type) => { setSelectedActionType(type as ActionType) }} 
              />
          )}
        </div>
        <div className="buttonArea">
          <IconTextButton ghost text="キャンセル" 
            onClick={() => {onCancel()}}
          />
          <IconTextButton 
            text="追加" 
            onClick={() => {onSubmit(selectedActionType as ActionType)}}
          />
        </div>
      </Style_AddActionPanel>
    )
}

const Style_AddActionPanel = styled.div<{
  colors:ThemeColor
}>`

& {
  width: 380px;

  h2 {
    font-weight: bold;
    margin-bottom: 1em;
  }

  .buttonArea {
    margin-top: 2em;
    display: flex;
    justify-content: space-between;
    align-items: center;

    > * {
      width: 160px;
    }
  }
}

`