import { rest } from 'msw';

import { Handler } from '../Handler';

import { baseUrl } from 'api/apiSettings';

import { ScenarioMock } from '../../data/rest/scenario';

export class ScenarioMswHandler extends Handler {

    private statusCode;

    constructor(statusCode:number) {
        super(); // 親のコンストラクタを呼んでおく
        this.statusCode = statusCode | 200;
    }

    public apis = [
        rest.get(
            baseUrl + '/brands/:brandId/chatbots/:chatbotId/scenarios/:scenarioId',
            async (req, res, ctx) => {
                const params = req.params;

                const scenarioId:number = parseInt(params.scenarioId.toString());
                
                const response = ScenarioMock.show( scenarioId );
                await super.wait();
                return res(ctx.status(this.statusCode), ctx.json(response));
            }
        ),
    ]


}
