import { useMemo } from 'react';

// style
import styled from 'styled-components';
import { useThemeColor } from 'hooks/global/color';

// element components
import { ActiveIcon } from 'components/elements/icon/ActiveIcon';

// compoments
import { RadioBranchOptionInput } from './RadioBranchOptionInput'

type RadioBranchActionEditorProps = {
    minimumScenarioBlockList:ScenarioBlockMinimum[],
    actionDetail:RadioBranchActionDetail,
    onChange:(newActionDetail:RadioBranchActionDetail) => void
}

export const RadioBranchActionEditor = ({
    minimumScenarioBlockList,
    actionDetail,
    onChange
}:RadioBranchActionEditorProps) => {

    const colors:ThemeColor = useThemeColor();

    const radioBranchList = useMemo(() => {

        // これやっとかないとエラー
        // 再現として、 talk → address → radioBranch
        // の順で開くと、 actionDetail がなくて死ぬ
        if(actionDetail){
            if(Object.prototype.hasOwnProperty.call(actionDetail, 'radioList')){
                return actionDetail.radioList;
            }else{
                return undefined;
            }
        }


    },[actionDetail])

    const scenarioBlockSelectOptionList:SelectOption[] = useMemo(() => {

        const _selectOption:SelectOption[] = minimumScenarioBlockList.map((scenarioBlock:ScenarioBlockMinimum) => {
            return {
                value:scenarioBlock.id,
                label:scenarioBlock.name
            }
        })

        return _selectOption;

    },[])

    const handleChange = (newObj:RadioBranchObject,index:number) => {

        if(radioBranchList === undefined) return;

        const listToUpdate:RadioBranchObject[] = [...radioBranchList];

        listToUpdate[index] = newObj;

        changeAction(listToUpdate);
    }

    const handleAdd = () => {

        if(radioBranchList === undefined) return;

        const listToUpdate:RadioBranchObject[] = [...radioBranchList];

        const randomString:string = Math.random().toString(32).substring(2);

        listToUpdate.push({
            id:randomString,
            label:'新しい選択肢',
            nextScenarioBlockId:'none'
        })

        changeAction(listToUpdate);

    }

    const handleDelete = (index:number) => {
        if(radioBranchList === undefined) return;
        const listToUpdate:RadioBranchObject[] = [...radioBranchList];

        listToUpdate.splice(index, 1);

        changeAction(listToUpdate);
    }

    const changeAction = (listToChange:RadioBranchObject[]) => {

        const actionDetailToChange:RadioBranchActionDetail = {
            ...actionDetail,
            radioList:listToChange
        }
        
        onChange(actionDetailToChange);
    }


    return (
        <Style_RadioBranchActionEditor colors={colors}>
          {radioBranchList && (
            <>
              <div className="inputSelectListArea">
                {radioBranchList && (radioBranchList.map((radioBranchObj:RadioBranchObject,index:number) => {
                    return (
                        <div 
                          key={radioBranchObj.id}
                          className="radioBranchInputArea"
                        >
                          <RadioBranchOptionInput 
                            radioBranchObj={radioBranchObj}
                            selectOptionList={scenarioBlockSelectOptionList}
                            onChange={(newObj:RadioBranchObject) => handleChange(newObj,index)}
                            onDelete={() => handleDelete(index)}
                          />
                        </div>
                    )
                }))}
              </div>
              <div className="addObjectButton" onClick={() => handleAdd()}>
                <ActiveIcon iconName="plus" />
                <span>選択肢を追加</span>
              </div>
            </>
          )}
        </Style_RadioBranchActionEditor>
    )
}

const Style_RadioBranchActionEditor = styled.div<{
    colors:ThemeColor
}>`
  & {
    padding: 2em 40px;

    .inputSelectListArea {
      display: flex;
      flex-flow: column;
      row-gap: 1em;

      .radioBranchInputArea {
        border:1px solid ${(props) => props.colors.lightBorder};
        box-shadow: 0 2px 2px -1px rgba(0,0,0,.3);
        border-radius: 6px;
        padding: 1em .8em;
      }
    }

    .addObjectButton {
      background-color: ${(props) => props.colors.deeperBackground};
      border-radius: 6px;
      margin: 1em auto 0;
      width: 80%;
      height: 2.4em;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      &:hover {
        opacity: .7;
      }

      &:active {
        transform: translateY(1px);
      }
    }

    .buttonArea {
      margin-top: 1.8em;
      display: flex;
      justify-content: flex-end;

      > * {
        width: 120px;
      }
    }


  }

`