// 継承元
export class Handler {
    private waitingTime = 1000; // 待機時間

    wait():Promise<void>{
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve();
            },this.waitingTime)
        })
    }

}