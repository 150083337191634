const blues = {
    paleBlue:'#ffffff',
    // paleBlue:'#f3f8fd',
    // paleBlue:'#f3f8fd',
    lightBlue:'#48b0d8',
    blue:'#3177b7',
}


const grays = {
    lightGray:'#f3f8fd',
    gray:'#ccc',
    heavyGray:'#999',
}

const colorSource = {
    red:'#c7243a',
    yellow:'#edad0b',
    white:'#fff',
    black:'#666',
    green:'#7eca7c',
    ...blues,
    ...grays
}


const lightTheme:ThemeColor = {
    deeperBackground:colorSource.paleBlue,
    background:colorSource.white,
    shadowOnBackground:colorSource.lightGray,
    font:colorSource.black,
    border:colorSource.gray,
    lightBorder:colorSource.lightGray,
    main:colorSource.lightBlue,
    safe:colorSource.green,
    caution:colorSource.yellow,
    error:colorSource.red,
    disabled:colorSource.heavyGray
}

const darkTheme:ThemeColor = {
    deeperBackground:'#1c2e47',
    background:'#2d415e',
    shadowOnBackground:'rgba(255,255,255,.2)',
    font:colorSource.white,
    border:colorSource.heavyGray,
    lightBorder:colorSource.gray,
    main:colorSource.lightBlue,
    safe:colorSource.green,
    caution:colorSource.yellow,
    error:colorSource.red,
    disabled:colorSource.heavyGray
}

export const colorSettings:ColorSettings = {
    light:lightTheme,
    dark:darkTheme
}




