export const radioBranchActionTransformer = (actionBackend:Action_Backend):RadioBranchAction => {

    const actionDetailBackend = actionBackend.action_detail as RadioBranchActionDetail_Backend;

    const radioList:RadioBranchObject[] = actionDetailBackend.radio_list.map((radio:RadioBranchObject_Backend) => {
        return {
            id:radio.id,
            label:radio.label,
            nextScenarioBlockId:radio.next_scenario_block_id
        }
    })

    const radioBranchActionDetail:RadioBranchActionDetail = {
        radioList:radioList
    }

    const radioBranchAction:RadioBranchAction = {
        id:actionBackend.id,
        type:'radioBranch',
        actionDetail:radioBranchActionDetail
    }

    return radioBranchAction;

}
