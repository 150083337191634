import { useState } from 'react';

// style
import styled from 'styled-components';
import { useThemeColor } from 'hooks/global/color';
import { CardDesignMixin } from 'styleValues/mixins'

// router
import {Link} from 'react-router-dom';

// element component
import { TabSelector,TabData } from 'components/elements/ui/TabSelector';
import { ActiveIcon } from 'components/elements/icon/ActiveIcon';

// component
import { BaseInfo } from './baseInfo/BaseInfo';
import { PopupForLp } from './popupForLp/PopupForLp';

type SettingLpPageViewProps = {
    brandId:number,
    lpId:number
}

export const SettingLpPageView = ({
    brandId,
    lpId
}:SettingLpPageViewProps) => {

    const colors:ThemeColor = useThemeColor();

    const [currentTabCode,setCurrentTabCode] = useState<string>('baseInfo');

    const tabDataList:TabData[] = [
        {code:'baseInfo',name:'基本情報'},
        {code:'popups',name:'ポップアップ'},
    ]

    return (
        <Style_SettingLpPageView colors={colors}>

          <div className="lpTabSelectorArea">
            <Link className="backButton" to={'/settings/brands/' + brandId + '/lps'}>
              <ActiveIcon iconName='left'/>
            </Link>
            <TabSelector 
              tabDataList={tabDataList} 
              currentTabCode={currentTabCode}
              onSelect={(tabCode:string) => setCurrentTabCode(tabCode)}
            />
          </div>

          <div className="lpMainArea">
            { currentTabCode === 'baseInfo' && (
                <BaseInfo />
            )}
            { currentTabCode === 'popups' && (
                <PopupForLp />
            )}
          </div>

        </Style_SettingLpPageView>
    )
}

const Style_SettingLpPageView = styled.div<{
  colors:ThemeColor
}>`

  & {
    max-width:760px;
    margin: 0 auto;
    display: flex;
    flex-flow: column;
    gap: 24px;

    .lpTabSelectorArea {
      ${CardDesignMixin}

      display: flex;
      align-items: center;
      gap:8px;

      .backButton {
        font-size: 16px;
        width: 3em;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .lpMainArea {
      flex-grow: 1;
      ${CardDesignMixin}
      padding: 40px;
    }
  }
`