// style
import styled from 'styled-components';
import { useThemeColor } from 'hooks/global/color';
import { CardDesignMixin } from 'styleValues/mixins'

// router
import {Link} from 'react-router-dom';

// hooks
import { useModal } from 'hooks/contexts/modal';

// element component
import { IconTextButton } from 'components/elements/ui/IconTextButton'

// general component
import { OneLineInputPanel } from 'components/generals/OneLineInputPanel'



type SettingPopupListPageViewProps = {
    brandId:number
}

export const SettingPopupListPageView = ({
    brandId
}:SettingPopupListPageViewProps) => {

    const colors:ThemeColor = useThemeColor();

    const {showModal} = useModal();

    const popupList:Popup[] = [
        {id:1,name:'ポップアップ1',imageSrc:'/img/mocks/popup.jpg'},
        {id:2,name:'ポップアップ2',imageSrc:'/img/mocks/popup.jpg'},
        {id:3,name:'ポップアップ3',imageSrc:'/img/mocks/popup.jpg'},
        {id:4,name:'ポップアップ4',imageSrc:'/img/mocks/popup.jpg'},
        {id:5,name:'ポップアップ5',imageSrc:'/img/mocks/popup.jpg'},
    ]

    const showAddModal = () => {
        showModal({
            contents:<OneLineInputPanel placeholder="新規ポップアップ名を入力してください" okButtonText='追加' />,
            baseStyle:'standard'
        })
    }

    return (
        <Style_PopupListPage colors={colors}>
          <div className="popupFunctionArea">
            <IconTextButton text="add" icon="plus" onClick={() => showAddModal()} />
          </div>
          <div className="popupListArea">
            {popupList.map((popup:Popup) => {
                return (
                    <Link to={'/settings/brands/' + brandId + '/popups/' + popup.id} className="popupCard">
                      <img src={popup.imageSrc} />
                      <p>{popup.name}</p>
                    </Link>
                )
            })}
          </div>
        </Style_PopupListPage>
    )
}

const Style_PopupListPage = styled.div<{
  colors:ThemeColor
}>`

  .popupFunctionArea {
    display:flex;
    justify-content: flex-end;
    align-items: center;
    
  }

  .popupListArea {
    margin-top: 24px;
    display:grid;
    row-gap: 32px;
    column-gap: 24px;
    grid-template-columns: repeat(auto-fill,minmax(200px,1fr));

    .popupCard {
      ${CardDesignMixin}

      overflow: hidden;
      cursor:pointer;

      p {
        margin: 0;
        padding: 1em .8em;
      }

      img {
        display:block;
        width:100%;
        height: 160px;
        object-fit: cover;
      }
    }
  }
`;

