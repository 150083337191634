export class ScenarioMock {

    public static show = (scenarioId:number):ScenarioWithActions_Backend => {
        const scenarioWithActionsBackend:ScenarioWithActions_Backend = {
            id:scenarioId,
            name:'テストシナリオ',
            first_header:'30秒でカンタン入力！',
            second_header:'今この機会に手に入れろ！',        
            operator_name:'シーサーくん',
            operator_image_url:'/img/mocks/operator.png',
            scenario_block_list:[
                {
                    id:'1',
                    name:'開始ブロック',
                    action_list:[
                        {id:'1',type:'talk',action_detail:{
                            text:'こんにちは！'
                        }},
                        {id:'2',type:'talk',action_detail:{
                            text:'住所を教えてもらえます？'
                        }},
                        {id:'3',type:'address'},
                        {id:'4',type:'talk',action_detail:{
                            text:'ありがとうございます！'
                        }},
                        {id:'5',type:'talk',action_detail:{
                            text:'何語で行きます？'
                        }},
                        {id:'6',type:'radio_branch',name:'選択肢',action_detail:{
                            radio_list:[
                                {   
                                    id:'aaaadslafk;l',
                                    label:'日本語',
                                    next_scenario_block_id:'2'
                                },
                                {   
                                    id:'ahdfjalflsnflk',
                                    label:'ゴリラ語',
                                    next_scenario_block_id:'3'
                                },
                            ]
                        }},
                    ]
                },
                {
                    id:'2',
                    name:'日本語ルート',
                    action_list:[
                        {id:'7',type:'talk',action_detail:{
                            text:'お名前を教えてください'
                        }},
                        {id:'8',type:'name'},
                        {id:'9',type:'talk',action_detail:{
                            text:'いいお名前ですね！'
                        }},
                        {id:'10',type:'next_block',action_detail:{
                            next_scenario_block_id:'4'
                        }}

                    ]
                },
                {
                    id:'3',
                    name:'ゴリラ語ルート',
                    action_list:[
                        {id:'11',type:'talk',action_detail:{
                            text:'ウホ？'
                        }},
                        {id:'12',type:'name'},
                        {id:'13',type:'talk',action_detail:{
                            text:'ウホウホ'
                        }},
                        {id:'14',type:'next_block',action_detail:{
                            next_scenario_block_id:'4'
                        }}
                    ]
                },
                {
                    id:'4',
                    name:'エンディング',
                    action_list:[
                        {id:'akldflksfnlksdaf',type:'talk',action_detail:{
                            text:'決済方法を選択してください'
                        }},
                        {id:'11212sdfa',type:'payment',action_detail:{
                            payment_list:['konbini','creditCard','bankTransfer']
                        }},
                        {id:'15',type:'talk',action_detail:{
                            text:'確認します'
                        }},
                        {id:'16',type:'confirm'},
                        {id:'17',type:'talk',action_detail:{
                            text:'お買い上げありがとうございますー！'
                        }}
                    ]
                },
            ]
        }

        return scenarioWithActionsBackend;
    }

}

