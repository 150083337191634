import { useAjax } from "api/index";

export const useBrandRestApiHooks = ():BrandRestApiHoooks => {

    const {get} = useAjax({needAuth:true});

    const index = async ():Promise<Brand[]> => {

        const path:string = `brands`;

        const response:ApiResponse = await get(path).catch(res => res);

        if(response.status >= 400){
            throw response;
        }

        const brandListFromBackend = response.data as Brand_Backend[];
        
        // フロント用のデータに変換
        const brandList:Brand[] = brandListFromBackend.map((brandFromBackend:Brand_Backend) => {
            return transformIntoFrontType(brandFromBackend);
        })

        return brandList;
        
    }

    const show = async (brandId:number):Promise<Brand> => {
        const path:string = `brands/${brandId}`;

        const response:ApiResponse = await get(path).catch(res => res);

        if(response.status >= 400){
            throw response;
        }

        const brandFromBackend = response.data as Brand_Backend;
        
        // フロント用のデータに変換
        const brand:Brand = transformIntoFrontType(brandFromBackend);

        return brand;
    }

    /**
     * api の戻り値をフロントの型へ変換
     * @param backendData 
     * @returns 
     */
    const transformIntoFrontType = (backendData:Brand_Backend):Brand => {

        const frontendData:Brand = {
            id:backendData.id,
            name:backendData.name,
        };

        return frontendData;
    }

    return {
        index,
        show
    }
  
}

