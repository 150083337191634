// style
import styled from 'styled-components';
import { useThemeColor } from 'hooks/global/color'

export type TabData = {
    code:string,
    name:string
}

type TabSelectorProps = {
    tabDataList:TabData[],
    currentTabCode:string,
    onSelect:(code:string) => void
}

export const TabSelector = ({
    tabDataList,
    currentTabCode,
    onSelect
}:TabSelectorProps) => {
    const colors:ThemeColor = useThemeColor();
    return (
        <Style_TabSelector colors={colors}>
          {tabDataList.map((tabData:TabData) => {
              return (
                <div key={tabData.code} 
                  className={currentTabCode === tabData.code ? 'isSelected' : ''}
                  onClick={() => onSelect(tabData.code)}
                >
                  <span>{tabData.name}</span>
                </div>
              )
          })}
        </Style_TabSelector>
    )
}

const Style_TabSelector = styled.div<{
    colors:ThemeColor
}>`

  & {
    display: flex;

    > div {
      font-size: .9em;
      font-weight: bold;
      height: 40px;
      padding: 0 2em;
      border-top: 3px solid transparent;
      border-bottom: 3px solid transparent;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      > span {
        opacity: .5;
      }

      &:active {
        > span {
          transform: translateY(2px);
        }
      }

      &.isSelected {

        border-bottom-color: ${(props) => props.colors.main};

        > span {
          opacity: 1;
        }

      }
    }
  }
`