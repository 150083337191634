/**
 * redux store のテーマ変更を監視して変更する hooks
 */

import { useMemo } from "react";

// redux
import { useSelector } from 'redux/store';

// color
import {colorSettings} from 'styleValues/color';

export const useThemeName = ():keyof ColorSettings => {
    return useSelector((state) => state.styleTheme.themeName) as keyof ColorSettings;
}

/**
 * テーマの変更を監視し、具体的な色設定を吐く
 */
export const useThemeColor = ():ThemeColor => {

    const themeName = useSelector((state) => state.styleTheme.themeName) as keyof ColorSettings;
    
    const colors:ThemeColor = useMemo(() => {

        let newThemeColors:ThemeColor = colorSettings[themeName];
    
        if(newThemeColors === undefined){
            newThemeColors = colorSettings['light'];
        }

        return newThemeColors;

    },[themeName]);

    return colors;
}
