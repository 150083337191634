// style
import styled from 'styled-components';
import { useThemeColor } from 'hooks/global/color';
import { CardDesignMixin } from 'styleValues/mixins'

// router
import {Link} from 'react-router-dom';

// hooks
import { useModal } from 'hooks/contexts/modal';

// element components
import {IconTextButton} from 'components/elements/ui/IconTextButton';

// general component
import { OneLineInputPanel } from 'components/generals/OneLineInputPanel'

type SettingLpListPageViewProps = {
    brandId:number
}

export const SettingLpListPageView = ({
    brandId 
}:SettingLpListPageViewProps) => {

    const colors:ThemeColor = useThemeColor();

    const {showModal} = useModal();

    const lpList:Lp[] = [
        {id:1,name:'LP1'},
        {id:2,name:'LP2'},
        {id:3,name:'LP3'},
        {id:4,name:'LP4'},
    ]

    const showLpAddModal = () => {
        showModal({
            contents:<OneLineInputPanel placeholder='新規LP名を入力してください' okButtonText='追加'/>,
            baseStyle:'standard'
        })
    }


    return (
        <Style_LpListPage colors={colors}>
          <div className="lpFunctionArea">
            <IconTextButton text="add" icon="plus" onClick={() => showLpAddModal()}/>
          </div>
          <div className="lpListArea">
            {lpList.map((lp:Lp) => {
                return (
                  <Link key={lp.id} className="lpLink" to={'/settings/brands/' + brandId + '/lps/' + lp.id}>
                    <div>{lp.name}</div>
                  </Link>
                )
            })}
          </div>
        </Style_LpListPage>
    )
}

const Style_LpListPage = styled.div<{
    colors:ThemeColor
}>`
  .lpFunctionArea {
    display:flex;
    justify-content: flex-end;
    align-items: center;
    gap:1em;
    
    > *:nth-child(2){
      flex-shrink:0;
    }
  }

  .lpListArea {
    margin-top: 32px;
    ${CardDesignMixin}

    .lpLink {
      height: 56px;
      display: flex;
      align-items: center;
      padding: 0 2em;
      font-weight: bold;

      &:hover {
        background-color: ${(props) => props.colors.lightBorder};
      }

      &:active {
        filter: brightness(.8);
      }

      + .lpLink {
        border-top: 1px solid ${(props) => props.colors.border};
      }
      
    }
  }

`