// style
import styled from 'styled-components';
import { useThemeColor } from 'hooks/global/color';

// hooks
import { useModal } from 'hooks/contexts/modal';

// element component
import { IconTextButton } from 'components/elements/ui/IconTextButton'
import { ToggleSwitch } from 'components/elements/inputs/ToggleSwitch'

// general component
import { OneLineInputPanel } from 'components/generals/OneLineInputPanel'

type PopupBaseInfoProps = {
  popupId:number,
  imageUrl:string,
  hasEmailLog:boolean,
  emailImageUrl:string,
  emailPlaceholder:string,
  emailButtonDisplay:string,
  emailTermsUrl:string,
  emailTermsString:string,
  onEmailStateChange:(state:boolean) => void
}

export const PopupBaseInfo = ({
    popupId,
    imageUrl,
    hasEmailLog,
    emailImageUrl,
    emailPlaceholder,
    emailButtonDisplay,
    emailTermsUrl,
    emailTermsString,  
    onEmailStateChange
}:PopupBaseInfoProps) => {

    const colors:ThemeColor = useThemeColor();

    const {showModal} = useModal();

    const name:string = 'ポップアップ1';
    const linkUrl:string = 'https://popup-sample.com';

    const showEditModal = (initialText:string,placeholder:string) => {
        showModal({
            contents:<OneLineInputPanel initialText={initialText} placeholder={placeholder}/>,
            baseStyle:'standard'
        })
    }

    return (
      <Style_PopupBaseInfo colors={colors}>
        <div className="editItemArea">
          <h3>ポップアップ名</h3>
          <div className="editItemRack">
            <span className='editItemDisplay'>{name}</span>
            <div className="editButton">
              <IconTextButton icon='edit' 
                onClick={() => showEditModal(name,'ポップアップ名')}
              />
            </div>
          </div>
        </div>

        <div className="editItemArea">
          <h3>遷移先URL</h3>
          <div className="editItemRack">
            <span className='editItemDisplay'>{linkUrl}</span>
            <div className="editButton">
              <IconTextButton icon='edit' 
                onClick={() => showEditModal(linkUrl,'遷移先URL')}
              />
            </div>
          </div>
        </div>

        <div className="editItemArea">
          <h3>ポップアップ画像</h3>
          <div className="popupImageArea">
            <div className="popupImage">
              <img className="editItemImage" src={imageUrl} />
            </div>
            <div className="editButton">
              <IconTextButton text="変更" />
            </div>
          </div>
        </div>

        <div className="editItemArea">
          <h3>メールアドレス記録機能</h3>
          <div className="editItemRack">
            <span className='editItemDisplay'></span>
            <ToggleSwitch 
              value={hasEmailLog}
              onClickSwitch={() => onEmailStateChange(!hasEmailLog)}
            />
          </div>
        </div>

        <section className={ !hasEmailLog ? 'disabled' : ''}>
          <div className="editItemArea">
            <h3>メールアドレス送信ボタンテキスト</h3>
            <div className="editItemRack">
              <span className='editItemDisplay'>{emailButtonDisplay}</span>
              <div className="editButton">
                <IconTextButton icon='edit' 
                  onClick={() => showEditModal(emailButtonDisplay,'メールアドレス送信ボタンテキスト')}
                />
              </div>
            </div>
          </div>

          <div className="editItemArea">
            <h3>メールアドレス欄プレースホルダー</h3>
            <div className="editItemRack">
              <span className='editItemDisplay'>{emailPlaceholder}</span>
              <div className="editButton">
                <IconTextButton icon='edit' 
                  onClick={() => showEditModal(emailPlaceholder,'メールアドレス欄プレースホルダー')}
                />
              </div>
            </div>
          </div>

          <div className="editItemArea">
            <h3>利用規約リンク文言</h3>
            <div className="editItemRack">
              <span className='editItemDisplay'>{emailTermsString}</span>
              <div className="editButton">
                <IconTextButton icon='edit' 
                  onClick={() => showEditModal(emailTermsString,'利用規約リンク文言')}
                />
              </div>
            </div>
          </div>
          <div className="editItemArea">
            <h3>利用規約リンクURL</h3>
            <div className="editItemRack">
              <span className='editItemDisplay'>{emailTermsUrl}</span>
              <div className="editButton">
                <IconTextButton icon='edit' 
                  onClick={() => showEditModal(emailTermsUrl,'利用規約リンクURL')}
                />
              </div>
            </div>
          </div>

          <div className="editItemArea">
            <h3>メアド送信前画像（いい見出しが思いつかず。）</h3>
            <div className="popupImageArea">
              <div className="popupImage">
                <img className="editItemImage" src={emailImageUrl} />
              </div>
              <div className="editButton">
                <IconTextButton text="変更" />
              </div>
            </div>
          </div>

        </section>

      </Style_PopupBaseInfo>
    )
}

const Style_PopupBaseInfo = styled.div<{
  colors:ThemeColor
}>`

  & {

    .editItemArea {

      + .editItemArea {
        margin-top: 1.8em;
      }

      h3 {
        font-size: .8em;
        font-weight: bold;
      }
    }

    .editItemRack {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap:1em;
      border-bottom: 1px solid ${(props) => props.colors.main};
      height: 3em;
  
      .editItemDisplay {
        flex-grow: 1;
        line-height: 2;
        padding: 0 .4em;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .editItemImage {
        height: 100%;
        
      }
  
      .editButton {
        font-size:.9em;
      }
    } 

    .popupImageArea {
      margin-top: 1em;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      padding: .4em 0;
      border-bottom: 1px solid ${(props) => props.colors.main};

      .popupImage {
        flex-grow: 1;
        padding: 0 .4em;
        > img {
          width:200px;
          object-fit: contain;
        }
      }
    }


    section {
      margin-top: 2em;

      &.disabled {
        pointer-events: none;
        opacity: .4;
      }
    }

  }

`
