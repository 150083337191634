/**
 * ajax 通信の抽象化
 * axios やら fetch やら切り替えが可能なようにしている
 */

import { axiosAPI } from "./axiosConfig";

type useAjaxProps = {
    needAuth?:boolean
}

export const useAjax = ({needAuth}:useAjaxProps):{
    get:(url:string,params?:any) => Promise<ApiResponse>,
    post:(url:string,params?:any) => Promise<ApiResponse>,
    del:(url:string,params?:any) => Promise<ApiResponse>
} => {

    const get = async (url:string,params?:any):Promise<ApiResponse> => {
        if(params === undefined) params = {};

        const responseFromSever:any = await axiosAPI.get(url,{params}).catch((error) => error.response); 
        
        const apiResponse:ApiResponse = responseHandling(responseFromSever);

        if(apiResponse.status >= 400) throw apiResponse;

        return apiResponse;
    }

    const post = async (url:string,params?:any):Promise<ApiResponse> => {

        if(params === undefined) params = {};

        const formdata:FormData = makeFormData(params);
                  
        const responseFromSever = await axiosAPI.post(url,formdata).catch((error) => error.response);
        
        const apiResponse:ApiResponse = responseHandling(responseFromSever);

        if(apiResponse.status >= 400) throw apiResponse;

        return apiResponse;
    }

    const del = async (url:string,params?:any):Promise<ApiResponse> => {
        const responseFromSever:any = await axiosAPI.delete(url,params);

        const apiResponse:ApiResponse = responseHandling(responseFromSever);

        if(apiResponse.status >= 400) throw apiResponse;

        return apiResponse;
    }

    return {
        get,
        post,
        del
    }

}


const makeFormData = (params:any):FormData => {

  const formdata:FormData = new FormData();

  Object.keys(params).forEach((key:string) => {

      if(Array.isArray(params[key])){

          // 配列の場合特別に処理
          params[key].forEach((arrayValue:any) => {
              formdata.append(key + '[]',arrayValue.toString());
          })

      }else{
          const value = params[key] === null ? 'null' : params[key].toString();
          formdata.append(key,value);
      }
  });

  return formdata;
}

const responseHandling = (responseFromSever:any):ApiResponse => {      
    const apiResponse:ApiResponse = {
        data:responseFromSever.data,
        status:responseFromSever.status
    }

    if(apiResponse.status >= 400){
        // 403 のときは alert 出して lp に飛ばす
        if(apiResponse.status === 403) {
            alert('ログイン状態が切れています。再度ログインしてください。');
            window.location.href = '/';
        }
        throw apiResponse;
    }

    return apiResponse;
}