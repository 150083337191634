import { Navigate } from "react-router-dom";
import useAuth from "providers/useAuth";

/* This is only for Super admin */
export const ProtectedSuperAdminRoute = ({ children }: any) => {
  const { user } = useAuth();
  if (!user) {
    return <Navigate to="/admin/login" />;
  }
  if (user && user.role === 'brand_admin') {
    return <Navigate to="/brandadmin/dashboard" />;
  }
  return children;
};

/* This is only for brand admin */
export const ProtectedBrandAdminRoute = ({ children }: any) => {
  const { user } = useAuth();
  if (!user) {
    return <Navigate to="/admin/login" />;
  }
  if (user && user.role === 'admin') {
    return <Navigate to="/admin/dashboard" />;
  }
  return children;
};
/* This is only for login */
export const AdminAuthRoute = ({ children }: any) => {
  const { user } = useAuth();
  if (user && user.role === 'admin') {
    return <Navigate to="/admin/dashboard" />;
  }
  if (user && user.role === 'admin') {
    return <Navigate to="/admin/dashboard" />;
  }
  if (user && user.role === 'brand_admin') {
    return <Navigate to="/brandadmin/dashboard" />;
  }
  return children;
};
