// style
import styled from 'styled-components';
import { useThemeColor } from 'hooks/global/color';
import { useEffect, useState } from 'react';

// element component
import { IconTextButton } from 'components/elements/ui/IconTextButton';


type TalkActionEditorProps = {
    operatorImageUrl:string,
    action:TalkAction,
    onUpdate:(newAction:TalkAction) => void
}

export const TalkActionEditor = ({
    operatorImageUrl,
    action,
    onUpdate
}:TalkActionEditorProps) => {

    const colors:ThemeColor = useThemeColor();

    const [talkText,setTalkText] = useState<string>('');

    useEffect(() => {
        setTalkText(action.actionDetail.text);
    },[action])

    const handleSubmit = () => {
        // ディープコピーして変更して渡す
        const newAction:TalkAction = {...action};
        newAction.actionDetail.text = talkText;
        onUpdate(newAction)
    }

    return (
        <Style_TalkActionEditor colors={colors}>
          <div className="operatorIcon">
            <img src={operatorImageUrl} alt="operatorIcon" />
          </div>
          <div className="talkInputArea">
            <textarea 
              rows={4} 
              placeholder={'テキストを入力してください'} 
              value={talkText}
              onChange={(e) => setTalkText(e.target.value)}
            />
          </div>
          <div className="buttonArea">
            <IconTextButton text="元に戻す" ghost onClick={() =>setTalkText(action.actionDetail.text)}/>
            <IconTextButton text="変更" onClick={handleSubmit}/>
          </div>
        </Style_TalkActionEditor>
    )
}

const Style_TalkActionEditor = styled.div<{
    colors:ThemeColor
}>`
  & {
    padding: 0 40px;

    .operatorIcon {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 200px;
      img {
        border-radius: 100%;
        width:120px;
        height:120px;
        object-fit: cover;
      }
    }

    .talkInputArea {
      > textarea {
        display: block;
        width: 100%;
        padding: 1em;
        resize: none;
        outline: none;
        border: 2px solid ${(props) => props.colors.main};
        border-radius: 10px;
        line-height: 1.6;
      }
    }

    .buttonArea {
      margin-top: 2em;
      display: flex;
      justify-content: space-between;
      font-size:.9em;

      > * {
        width:10em;
      }
    }
  }

`