import { useEffect, useMemo, useState } from 'react';

// style
import styled from 'styled-components';
import { useThemeColor } from 'hooks/global/color';

// element component
import { Checkbox } from 'components/elements/inputs/Checkbox'

type PaymentActionEditorProps = {
    actionDetail:PaymentActionDetail,
    onChange:(newActionDetail:PaymentActionDetail) => void
}

export const PaymentActionEditor = ({
    actionDetail,
    onChange
}:PaymentActionEditorProps) => {

    const colors:ThemeColor = useThemeColor();

    const paymentCheckboxList:CheckboxObject[] = [
        {label:'クレジットカード',value:'creditCard'},
        {label:'銀行振込',value:'bankTransfer'},
        {label:'代引き',value:'cashOnDelivery'},
        {label:'コンビニ決済',value:'konbini'},
    ];

    const [checkedPaymentList,setCheckedPaymentList] = useState<PaymentType[]>([]);

    useEffect(() => {
        setCheckedPaymentList(actionDetail.paymentList);
    },[actionDetail.paymentList]);

    const handleChange = (newList:PaymentType[]) => {

        // 新しい値で actionDetail を更新
        const newActionDetail:PaymentActionDetail = {...actionDetail};

        newActionDetail.paymentList = newList;

        onChange(newActionDetail);
    }

    return (
        <Style_RadioBranchActionEditor colors={colors}>
          <Checkbox 
            checkboxList={paymentCheckboxList} 
            valueList={checkedPaymentList}
            onChange={(newList:string[]) => handleChange(newList as PaymentType[])}
          />
        </Style_RadioBranchActionEditor>
    )
}

const Style_RadioBranchActionEditor = styled.div<{
    colors:ThemeColor
}>`

& {
  height: 100%;
  display:flex;
  flex-flow: column;
  justify-content: center;
  padding: 0 20%;
}
`
