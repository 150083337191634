import { useLocalStorage } from "hooks/useLocalStorage";
import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { getCurrentUser, logout } from "services/auth.service";

interface User {
  role: string;
  access_token: string;
  message: string;
  token_type: string;
}

interface AuthContextType {
  user?: User;
  signIn: () => void;
  signOut: () => void;
}

const AuthContext = createContext<AuthContextType>({} as AuthContextType);

export function AuthProvider({
  children,
}: {
  children: ReactNode;
}): JSX.Element {
  const [user, setUser] = useLocalStorage("user", null);

  useEffect(() => {
    getCurrentUser()
      .then((user) => {
        setUser(user);
      })
      .catch((_error) => {})
      .finally(() => {});
  }, [setUser]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function signIn() {
    getCurrentUser()
      .then((user) => {
        setUser(user);
      })
      .catch((_error) => {})
      .finally(() => {

      });
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function signOut() {
    logout();
    setUser(undefined);
  }

  const memoedValue = useMemo(
    () => ({
      user,
      signIn,
      signOut,
    }),
    [user, signIn, signOut]
  );

  return (
    <AuthContext.Provider value={memoedValue}>{children}</AuthContext.Provider>
  );
}

export default function useAuth() {
  return useContext(AuthContext);
}
