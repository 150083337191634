import { AxiosRequestHeaders } from "axios";
import { axiosClient } from "./client";

export const login = (email: string, password: string) => {
  return axiosClient
    .post("auth/login", {
        email,
      password,
    })
    .then((response) => {
      if (response.data.access_token) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }
      return response.data;
    });
};

export const logout = () => {
  localStorage.removeItem("user");
};

export async function getCurrentUser(): Promise<any> {
  const userStr = await localStorage.getItem("user");
  if (userStr) return JSON.parse(userStr);
  return null;
}

export function authHeader (): AxiosRequestHeaders {
  try {
    const userInfo = JSON.parse(localStorage.getItem("user") || "");
    
    if (typeof userInfo.access_token !== 'string') throw new Error('User info not found');

    const accessToken = userInfo.token_type + ' ' + userInfo.access_token;

    return {'Authorization': accessToken};
  }
  catch (err) {
    console.log(err);
    return {};
  }
}