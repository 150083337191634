import { useState,useEffect } from 'react'

import { useLocation } from 'react-router-dom';

import { scenarioTransformer } from 'chatbotSrc/transformer/toFrontend/scenario/scenarioTransformer';

import { mockData } from 'chatbotSrc/mock/index';


export type CB_ChatbotRootHooks = {
    scenarioData:CB_Scenario | undefined
}

export const useGetSourceHooks = ():CB_ChatbotRootHooks => {

    const search = useLocation().search;

    const searchParams = new URLSearchParams(search);

    const [scenarioData,setScenarioData] = useState<CB_Scenario | undefined>(undefined);

    useEffect( () => {

        let scenarioDataSourceBackend:CB_Scenario_Backend | undefined = undefined;

        // mocktest か否か
        const isMockTest:boolean = searchParams.get('mockTest') !== null;        
    
        if(isMockTest){

            scenarioDataSourceBackend = mockData();

        }else{

            // get パラメータから取得する
            const scenarioDataSourceJson:string | null = searchParams.get('scenarioData');        
    
            if(scenarioDataSourceJson === null) return;
    
            scenarioDataSourceBackend = JSON.parse(scenarioDataSourceJson) as CB_Scenario_Backend;

        }

        if(scenarioDataSourceBackend === undefined) return;


        const scenarioDataSource:CB_Scenario = scenarioTransformer(scenarioDataSourceBackend);        

        setScenarioData(scenarioDataSource);

    },[])

    return {
        scenarioData
    }

}