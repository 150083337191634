import {MouseEvent,useMemo} from 'react'

// style
import styled from 'styled-components';
import { zIndex } from 'styleValues/z-index'

import { scenarioTransformer } from 'api/transformer/toBackend/scenario/scenarioTransformer'

type PreviewBoxProps = {
    scenarioData:ScenarioWithActions,
    onCloseRequest: () => void
}

export const PreviewBox = ({
    scenarioData,
    onCloseRequest
}:PreviewBoxProps) => {


    const scenarioDataBackend:ScenarioWithActions_Backend = useMemo(() => {

        const scenarioBackend:ScenarioWithActions_Backend = scenarioTransformer(scenarioData);

        return scenarioBackend;

    },[scenarioData])

    const iframeSrc = useMemo(() => {
        const url = '/preview/scenario';

        const data:string = JSON.stringify(scenarioDataBackend);

        const src = `${url}?scenarioData=${data}`;

        return src;
    },[scenarioDataBackend])


    const handleBackgroundClick = (e:MouseEvent) => { 
        if(e.currentTarget === e.target){
            onCloseRequest();
        }
    }

    return (
        <Style_PreviewBox onClick={(e:MouseEvent)=> handleBackgroundClick(e)}>
          <div className="previewArea">
            <iframe 
              width="375" 
              height="667" 
              src={iframeSrc}
            />
          </div>
        </Style_PreviewBox>
    )
}

const Style_PreviewBox = styled.div<{
}>`
& {
  position: fixed;
  top:0;left:0;right:0;bottom:0;
  z-index:${zIndex.modal - 1};
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0,0,0,.3);

  .previewArea {
    transform: scale(.8);
    padding: 40px;
    border-radius: 10px;
    background-color: #fff;

    > iframe {
      border:1px solid #ccc;
      border-radius: 4px;
    }
  }

}
`