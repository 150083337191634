export const paymentActionTransformer = (actionBackend:CB_Action_Backend):CB_Action => {

    const actionDetailBackend = actionBackend.action_detail as CB_PaymentActionDetail_Backend;

    const paymentList:CB_PaymentType[] = actionDetailBackend.payment_list.map((paymentType:CB_PaymentType_Backend) => {
        return paymentType
    })

    const actionDetail:CB_PaymentActionDetail = {
        paymentList:paymentList
    }

    const paymentAction:CB_Action = {
        id:actionBackend.id,
        type:'payment',
        actionDetail:actionDetail
    }

    return paymentAction;

}
