export const talkActionTransformer = (actionFrontend:TalkAction):Action_Backend => {

    const actionDetailFrontend = actionFrontend.actionDetail as TalkActionDetail;

    const talkActionDetail:TalkActionDetail_Backend = {
        text:actionDetailFrontend.text
    }

    const talkAction:Action_Backend = {
        id:actionFrontend.id,
        type:'talk',
        action_detail:talkActionDetail
    }

    return talkAction;

}