import { talkActionMock } from "./data/actions/talkAction"
import { confirmActionMock } from "./data/actions/confirmAction"
import { paymentActionMock } from "./data/actions/paymentAction"

export const mockData = ():CB_Scenario_Backend => {

    const action_list = [
        talkActionMock('スタート'),
        paymentActionMock,
        talkActionMock('終わり'),
        confirmActionMock
    ];
    

    const test:CB_Scenario_Backend = {
        id:1,
        name:'test',
        first_header:'ファーストヘッダ',
        second_header:'セカンドヘッダ',
        operator_name:'test',
        operator_image_url:'/',
        scenario_block_list:[
            {
                id:'scenarioBlock_test_gfhjkaflsd',
                name:'test',
                action_list
            }
        ]
    }

    return test;
}
