// style
import styled from 'styled-components';
import { useThemeColor } from 'hooks/global/color';

// config
import { useActionConfig } from 'hooks/config/scenario/action';


type ConfirmActionEditorProps = {
}

export const ConfirmActionEditor = ({
}:ConfirmActionEditorProps) => {

    const colors:ThemeColor = useThemeColor();

    const { guestActionList } = useActionConfig();

    const actionStaticData = guestActionList.find((actionStatic:ActionStatic) => actionStatic.type === 'confirm');

    return (
        <Style_ConfirmActionEditor colors={colors}>
          <div className="editorDescriptionBox">
            <h3>{actionStaticData?.label}</h3>
            <p>{actionStaticData?.description}</p>
          </div>
        </Style_ConfirmActionEditor>
    )
}

const Style_ConfirmActionEditor = styled.div<{
    colors:ThemeColor
}>`
  & {
    padding: 2em 40px;
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: center;

    .editorDescriptionBox {
      background-color: ${(props) => props.colors.deeperBackground};
      border-radius: 6px;
      padding: 1em 1.2em;

      h3 {
        font-weight: bold;
      }

      p {
        margin-top: .4em;
        line-height: 1.6;
      }
    }

  }

`