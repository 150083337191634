// style
import styled from 'styled-components';
import { useThemeColor } from 'hooks/global/color';

// hooks
import { useModal } from 'hooks/contexts/modal';

// element component
import {TextInputElement} from 'components/elements/inputs/TextInputElement';
import {IconTextButton} from 'components/elements/ui/IconTextButton';


type LpUrlEditPanelProps = {
    url:string
}

export const LpUrlEditPanel = ({
    url
}:LpUrlEditPanelProps) => {

    const colors:ThemeColor = useThemeColor();

    const {closeModal} = useModal();

    return (
        <Style_LpUrlEditPanel colors={colors}>
          <div className="urlInputArea">
            <TextInputElement 
              initialValue={url}
              placeholder="URL"
            />
          </div>
          <div className="buttonArea">
            <IconTextButton text="更新"/>
            <IconTextButton text="キャンセル" ghost onClick={() => closeModal()}/>
          </div>
        </Style_LpUrlEditPanel>
    )
}



const Style_LpUrlEditPanel = styled.div<{
  colors:ThemeColor
}>`
  & {
    width:400px;

    .urlInputArea {
      > input {
        width:100%;
      }
    }

    .buttonArea {
      margin-top: 2em;
      display: flex;
      justify-content: space-evenly;
      align-items: center;

      > * {
        width: 40%;
      }
    }
  }
`
