import { useMemo, useState } from 'react';

// style
import styled,{css} from 'styled-components';
import { useThemeColor } from 'hooks/global/color'

// router
import {Link} from 'react-router-dom';

// hooks
import { useModal } from 'hooks/contexts/modal';

// icon
import { ActiveIcon } from 'components/elements/icon/ActiveIcon';

// components
import { BrandController } from './BrandController'
import { SettingPanel } from './SettingPanel'


type NavigationMuduleProps = {
    routeName:string
}

export const NavigationModule = ({
    routeName
}:NavigationMuduleProps) => {

    const {showModal} = useModal();

    const showSettingModal = () => {
        showModal({
            contents:<SettingPanel />,
            baseStyle:'standard'
        })
    }


    const colors:ThemeColor = useThemeColor();

    const [isFolded,setIsFolded] = useState<boolean>(false);

    // disable にするルートネームリスト
    const disableRouteList:string[] = [
        'settings-scenario'
    ];

    const navigationClassName = useMemo(() => {
        if(disableRouteList.includes(routeName)) return 'disabled';
        if(isFolded) return 'isFolded';
        return '';
    },[routeName,isFolded])



    // // モック用の雑なやつ
    // // 本当はちゃんとシステムを組んでいく
    // const navigationList = ():NavigationObject[]  => {
    //     const navigationList:NavigationObject[] = [
    //         {name:'brand',display:'ブランド',path:'/settings/brands',icon:'brand'},
    //     ];
    //     return navigationList;
    // }


    return (
        <Style_Navigation colors={colors} 
          className={navigationClassName}
        >
          <div className="headerArea">
            <div className="serviceLogo">
              <img src="/img/logo/service_logo.png" alt="service_logo" />
            </div>
            <Link className="navigationLink" to={`/reports/brands/1/payments`}>
              <ActiveIcon iconName="report" className="naviIcon" />
              <span>レポート</span>
            </Link>
          </div>
          <div className="navigationList">
            <div>
              <BrandController />
            </div>
          </div>
          <div className="footerArea">
            <div className="navigationLink" onClick={() => showSettingModal()}>
              <ActiveIcon iconName="setting" className="naviIcon" />
              <span>設定</span>
            </div>
            <div className="navigationLink" onClick={() => setIsFolded( !isFolded )}>
              <ActiveIcon iconName="none" className="naviIcon" />
              <span>折りたたみ</span>
            </div>
          </div>
        </Style_Navigation>
    )
}

const mixinFrame = css<{
    colors:ThemeColor
}>`
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    background-color: ${(props) => props.colors.background};
    box-shadow: 0 2px 4px 0px rgba(0,0,0,.04);
`;

const Style_Navigation = styled.div<{
    colors:ThemeColor
}>`
    & {
      width:280px;
      height: 100%;
      display:flex;
      flex-flow:column;
      justify-content: space-between;
      gap:16px;
      font-size:14px;

      .headerArea {
        flex-shrink: 0;
        ${mixinFrame}

        .serviceLogo {
          display:flex;
          justify-content:center;
          align-items:center;
          height:80px;
          border-bottom:1px solid ${(props) => props.colors.border};

          img {
            width:52%;
          }
        }
      }


      .navigationList {
        flex-grow:1;
        ${mixinFrame}

        .brandControllerArea {
          border-bottom:2px solid ${(props) => props.colors.main};
          overflow: hidden;
        }
      }

      .footerArea {
        ${mixinFrame}
        flex-shrink: 0;
      }

      .navigationLink {
        display: flex;
        align-items: center;
        height: 3.4em;
        white-space: nowrap;
        text-overflow: ellipsis;
        cursor: pointer;
        
        &:active {
          > * {
            transform: translateY(2px);
          }
        }

        &:hover {
          background-color: ${(props) => props.colors.main};
          color: #fff;
        }

        .naviIcon {
          flex-shrink:0;
          font-size:20px;
          width:48px;
          display:flex;
          justify-content: center;
          align-items: center;
        }
      }
    }

    &.isFolded{
      width:48px;

      .headerArea {
        img {
          width:0;
          opacity:0;
        }
      }

      .brandControllerArea {
        opacity: 0;
      }


      .navigationLink {
        > span {
          width:0;
          opacity:0;
        }

      }
    }

    &.disabled {
      pointer-events: none;
      opacity: 0;
      width:0;
      overflow: hidden;
    }

    // transition
    & {
      transition: width .2s;
    }

`;