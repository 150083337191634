/**
 * axios の基本設定
 */

import axios from 'axios' ;

import { baseUrl } from './apiSettings';

const axiosAPI = axios.create({
    xsrfHeaderName: "X-XSRF-TOKEN",
    withCredentials: true,
    baseURL: baseUrl
});

 // interceptor
axiosAPI.interceptors.request.use(request => {
  if (!(window.navigator.onLine)) {
    if (process.env.NODE_ENV !== 'development') {
      // 開発環境以外
      alert('インターネットに接続されていません');
      return;
    }
  }
  return request;
})


 
axiosAPI.interceptors.response.use(response => {
  // 419 の対応など
  return response;
})

// ログイン後処理axios都度 token付与
// providers/useAuth が責任範囲となるので今後変更した方が良い
const userAuthority:any = localStorage.getItem('user');

if ( userAuthority !== null && userAuthority?.access_token !== 'undefined' ) {
  axiosAPI.interceptors.request.use(config => {
    let authConfig = JSON.parse(userAuthority);
    config.headers!.authorization = `Bearer ${authConfig.access_token}`;
    return config;
  })
}

export {axiosAPI};