// style
import styled from 'styled-components';
import { useThemeColor } from 'hooks/global/color';

// hooks
import { useModal } from 'hooks/contexts/modal';

// element component
import {IconTextButton} from 'components/elements/ui/IconTextButton';
import {TextInputElement} from 'components/elements/inputs/TextInputElement';
import {ToggleSwitch} from 'components/elements/inputs/ToggleSwitch';


type LpPopupEditPanelProps = {
    id:number
}
export const LpPopupEditPanel = ({
    id
}:LpPopupEditPanelProps) => {

    const colors:ThemeColor = useThemeColor();

    const {closeModal} = useModal();

    return (
        <Style_LpPopupEditPanel colors={colors}>
          <div className="settingArea">
            <div className="settingRow">
              <span>出現率</span>
              <div className="settingInputBlock">
                <TextInputElement type="number"/>
                <span>％</span>
              </div>
            </div>

            <div className="settingRow">
              <span>スクロール量</span>
              <div className="settingInputBlock">
                <TextInputElement type="number"/>
                <span>％</span>
                <ToggleSwitch value={true} onClickSwitch={() => {}}/>
              </div>
            </div>

            <div className="settingRow">
              <span>時間</span>
              <div className="settingInputBlock">
                <TextInputElement type="number"/>
                <span>秒</span>
                <ToggleSwitch value={false} onClickSwitch={() => {}}/>
              </div>
            </div>

            <div className="settingRow">
              <span>ブラウザバック</span>
              <div className="settingInputBlock">
                <ToggleSwitch value={false} onClickSwitch={() => {}}/>
              </div>
            </div>

            <div className="buttonArea">
              <IconTextButton text="更新"/>
              <IconTextButton text="キャンセル" ghost onClick={() => closeModal()}/>
            </div>
          </div>
        </Style_LpPopupEditPanel>
    )
}

const Style_LpPopupEditPanel = styled.div<{
  colors:ThemeColor
}>`

& {

  .settingArea {
    font-size: .9em;
    width: 360px;
  
    .settingRow {
  
      display: flex;
      align-items: center;
      justify-content: space-between;
  
      background-color: ${(props) => props.colors.shadowOnBackground};
      border-radius: 4px;
      padding: .4em .8em;
  
      + .settingRow {
        margin-top: .8em;
      }
  
      > span {
        font-weight: bold;
      }
  
      .settingInputBlock {
        display: flex;
        align-items: center;
        gap:8px;
  
        > input {
          width:4em;
          text-align: right;
        }
      }
    }

    .buttonArea {
      margin-top: 2em;
      display: flex;
      justify-content: space-evenly;
      align-items: center;

      > * {
        width: 40%;
      }
    }
  }
}
`



