// redux
import { useDispatch } from 'react-redux';
import { changeTheme } from 'redux/slice/styleThemeSlice';
import { useThemeName } from 'hooks/global/color'

export const SettingPanel = () => {

    const themeName = useThemeName();

    // カラー変更テスト用
    const dispatch = useDispatch();
    const changeColor = () => {
        if(themeName === 'dark'){
          dispatch(changeTheme({themeName:'light'}))
        }else{
          dispatch(changeTheme({themeName:'dark'}))
        }
    }
  
    return (
        <div>
          <label>
            <input type="checkbox" checked={themeName === 'dark'} onChange={ () => changeColor()} />
            <span>ダークモード</span>
          </label>
        </div>
    )
}