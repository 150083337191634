import {Link} from 'react-router-dom';

export const LandingPage = () => {
    return (
        <div className="landingPage">
          <div>
            {/* <Link to="/reports/brands">レポートページへ</Link> */}
            <Link to="/admin">管理画面へ</Link>
          </div>
        </div>
    )
}
