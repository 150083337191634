import { actionTransformer } from './actionTransformer'

export const scenarioBlockTransformer = (scenarioBlockBackend:CB_ScenarioBlock_Backend):CB_ScenarioBlock => {

  const actionList:CB_Action[] = scenarioBlockBackend.action_list.map((action_backend:CB_Action_Backend) => {
      return actionTransformer(action_backend);
  })

  return {
      id:scenarioBlockBackend.id,
      name:scenarioBlockBackend.name,
      actionList:actionList
  }

}
