// style
import styled from 'styled-components';

import { RippleLoading } from './svgs/RippleLoading';

type LoadingIconProps = {
    icon:string,
    color?:string,
}


export const LoadingIcon = ({
    icon,
    color = '#000'
}:LoadingIconProps) => {

    return (
        <Style_LoadingIcon>
          { icon === 'ripple' && (<RippleLoading color={color} />) }
        </Style_LoadingIcon>
    )
}

const Style_LoadingIcon = styled.div<{
}>`
& {
  width:3em;
}
`