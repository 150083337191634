// router
import { useParams } from "react-router-dom";

// pageView
import { SettingBrandContentsListPageView } from "components/pages/settings/brandContentsList/SettingBrandContentsListPageView";
import useAuth from "providers/useAuth";

export const SettingLpListPage = () => {
  const { user } = useAuth();

  console.log(user);

  const params = useParams();

  const brandId = parseInt(params.brandId!);

  return <SettingBrandContentsListPageView brandId={brandId} pageCode="lp" />;
};
