import { axiosClient } from "./client";
import { authHeader } from "./auth.service";
import { AxiosRequestConfig } from "axios";

const requestConfig: AxiosRequestConfig = {headers: authHeader()};

export const getLoginUserData = async () => {
  return await axiosClient.get("profile/me", requestConfig).then((response) => {
    return response.data;
  });
};
