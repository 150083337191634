export const talkActionTransformer = (actionBackend:Action_Backend):TalkAction => {

    const actionDetailBackend = actionBackend.action_detail as TalkActionDetail_Backend;

    const talkActionDetail:TalkActionDetail = {
        text:actionDetailBackend.text
    }

    const talkAction:TalkAction = {
        id:actionBackend.id,
        type:'talk',
        actionDetail:talkActionDetail
    }

    return talkAction;

}