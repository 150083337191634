export const paymentActionTransformer = (actionBackend:Action_Backend):PaymentAction => {

    const actionDetailBackend = actionBackend.action_detail as PaymentActionDetail_Backend;

    const paymentActionDetail:PaymentActionDetail = {
        paymentList:actionDetailBackend.payment_list
    }

    const paymentAction:PaymentAction = {
        id:actionBackend.id,
        type:'payment',
        actionDetail:paymentActionDetail
    }

    return paymentAction;
    
}

