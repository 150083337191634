// pageView

import { getLoginUserData } from "services/user.service";

export const SuperAdminDashboard = () => {
  const loginedData = getLoginUserData();

  console.log(loginedData);

  return <>
  <h1>Super Admin</h1>
  </>;
};
