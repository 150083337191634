import { useMemo } from 'react';

// style
import styled from 'styled-components';
import { useThemeColor } from 'hooks/global/color';

// element componets
import { SelectInput } from 'components/elements/inputs/SelectInput'


type NextBlockActionEditorProps = {
    minimumScenarioBlockList:ScenarioBlockMinimum[],
    actionDetail:NextBlockActionDetail,
    onChange:(newAction:NextBlockActionDetail) => void
}


export const NextBlockActionEditor = ({
    minimumScenarioBlockList,
    actionDetail,
    onChange
}:NextBlockActionEditorProps) => {

    const colors:ThemeColor = useThemeColor();

    const scenarioBlockSelectOptionList:SelectOption[] = useMemo(() => {

        const _selectOption:SelectOption[] = minimumScenarioBlockList.map((scenarioBlock:ScenarioBlockMinimum) => {
            return {
                value:scenarioBlock.id,
                label:scenarioBlock.name
            }
        })

        return _selectOption;

    },[])

    const handleChange = (newId:string) => {

        const newActionDetail:NextBlockActionDetail = {
            ...actionDetail,
            nextScenarioBlockId:newId
        }

        onChange(newActionDetail);
    }

    return (
        <Style_NextBlockActionEditor colors={colors}>
          {actionDetail && (
              <SelectInput 
                selectOptionList={scenarioBlockSelectOptionList} 
                value={actionDetail.nextScenarioBlockId}
                withHiddenOption
                onChange={(id:string | number) => handleChange(id.toString())} 
              />
          )}
        </Style_NextBlockActionEditor>

    )
}

const Style_NextBlockActionEditor = styled.div<{
  colors:ThemeColor
}>`

& {
  padding: 2em 40px;
  min-height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
}
`