// router
import { useParams } from 'react-router-dom';

// component
import { ReportPaymentPageView } from 'components/pages/reports/payments/ReportPaymentPageView';

export const ReportPaymentPage = () => {
    const params = useParams();
        
    const brandId = parseInt(params.brandId!);
    const chatbotId = parseInt(params.chatbotId!);
    const scenarioId = parseInt(params.scenarioId!);

    return (
        <ReportPaymentPageView 
          brandId={brandId} 
          chatbotId={chatbotId} 
          scenarioId={scenarioId}
        />
    )
}