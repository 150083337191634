// style
import styled from 'styled-components';
import { useThemeColor } from 'hooks/global/color';
import { CardDesignMixin } from 'styleValues/mixins'

// router
import { useNavigate } from 'react-router-dom';

// element component
import {TabSelector} from 'components/elements/ui/TabSelector';

// component
import { SettingLpListPageView } from './lpList/SettingLpListPageView'
import { SettingPopupListPageView } from './popupList/SettingPopupListPageView'
import { SettingChatbotListPageView } from './chatbotList/SettingChatbotListPageView'

type SettingBrandContentsListPageViewProps = {
    brandId:number,
    pageCode:'lp' | 'chatbot' | 'popup'
}

export const SettingBrandContentsListPageView = ({
    brandId,
    pageCode
}:SettingBrandContentsListPageViewProps) => {

    const colors:ThemeColor = useThemeColor();

    const navigate = useNavigate();

    const tabDataList = [
        {code:'lp',name:'LP'},
        {code:'popup',name:'ポップアップ'},
        {code:'chatbot',name:'チャットボット'},
    ];

    const changePage = (code:string) => {
        if(code === 'lp'){
          navigate('/settings/brands/' + brandId + '/lps');
        }
        if(code === 'popup'){
          navigate('/settings/brands/' + brandId + '/popups');
        }
        if(code === 'chatbot'){
          navigate('/settings/brands/' + brandId + '/chatbots');
        }
    }

    return (
        <Style_SettingBrandContentsListPageView colors={colors}>
          <div className="tabSelectorArea">
            <TabSelector tabDataList={tabDataList} currentTabCode={pageCode} onSelect={(code:string) => changePage(code)}/>
          </div>
          {pageCode === 'lp' && (
              <SettingLpListPageView brandId={brandId} />
          )}
          {pageCode === 'popup' && (
              <SettingPopupListPageView brandId={brandId} />
          )}
          {pageCode === 'chatbot' && (
              <SettingChatbotListPageView brandId={brandId} />
          )}
        </Style_SettingBrandContentsListPageView>
    )
}

const Style_SettingBrandContentsListPageView = styled.div<{
    colors:ThemeColor
}>`
  & {
    .tabSelectorArea {
      margin-bottom: 24px;
      padding: 0 16px;
      ${CardDesignMixin}
    }
  }
`