import { ReactNode } from 'react';

// style
import styled from 'styled-components';
import { useThemeColor } from 'hooks/global/color';

// hooks
import { useModal } from 'hooks/contexts/modal';

// element component
import { IconTextButton } from 'components/elements/ui/IconTextButton';

// component
import { TagDisplay } from './TagDisplay'
import { LpNameEditPanel } from './LpNameEditPanel';
import { LpUrlEditPanel } from './LpUrlEditPanel';

export const BaseInfo = () => {

    const name = 'LPサンプル1';
    const url = 'https://testtest.com/kenko_tablet';

    const colors:ThemeColor = useThemeColor();

    const {showModal} = useModal();

    const _showModal = (contents:ReactNode) => {
        showModal({
            contents,
            baseStyle:'standard'
        })
    }

    return (
        <Style_BaseInfo colors={colors}>
          <div className="nameRack">
            <span className='lpNameDisplay'>{name}</span>
            <div className="editButton">
              <IconTextButton icon='edit' 
                onClick={() => _showModal(<LpNameEditPanel name={name}/>)}
              />
            </div>
          </div>
          <div className="urlRack">
            <span className='lpUrlDisplay'>{url}</span>
            <div className="editButton">
              <IconTextButton icon='edit'
                onClick={() => _showModal(<LpUrlEditPanel url={url}/>)}
              />
            </div>
          </div>
          <div className="lpTagArea">
            <TagDisplay 
              headDisplay="計測タグ"
              tagAsStr='<script src="lp.js"></script>'
            />
            <TagDisplay 
              headDisplay="コンバージョンタグ"
              tagAsStr='<script src="cv.js"></script>'
            />
          </div>
        </Style_BaseInfo>
    )
}

const Style_BaseInfo = styled.div<{
    colors:ThemeColor
}>`

& {
  .nameRack,.urlRack {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap:1em;
    border-bottom: 1px solid ${(props) => props.colors.main};

    .lpNameDisplay,.lpUrlDisplay {
      flex-grow: 1;
      line-height: 3em;
      padding: 0 .4em;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .editButton {
      font-size:.9em;
    }
  }  

  .urlRack {
    margin-top: 1em;
  }

  .lpTagArea {
    margin-top: 2em;

    > * + *{
      margin-top: 1em;
    }
  }
}

`
