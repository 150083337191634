import { talkActionTransformer } from './actions/talkActionTransformer'
import { radioBranchActionTransformer } from './actions/radioBranchActionTransformer'
import { nextBlockActionTransformer } from './actions/nextBlockActionTransformer'
import { paymentActionTransformer } from './actions/paymentActionTransformer'

export const actionTransformer = (backendData:Action_Backend):Action => {

    if(backendData.type === 'talk') return talkActionTransformer(backendData);
    if(backendData.type === 'radio_branch') return radioBranchActionTransformer(backendData);
    if(backendData.type === 'next_block') return nextBlockActionTransformer(backendData);
    if(backendData.type === 'payment') return paymentActionTransformer(backendData);

    // そのほか、単純なやつ
    if(backendData.type === 'name') return {id:backendData.id,type:'name'};
    if(backendData.type === 'address') return {id:backendData.id,type:'address'};
    if(backendData.type === 'confirm') return {id:backendData.id,type:'confirm'};

    // 当てはまらなかった場合はとりあえず talk で返しておく
    return {
        id:'0',
        type:'talk',
        actionDetail:{
            text:'不明なアクションです'
        }
    };
}
