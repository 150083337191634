export class BrandMock {

    public static index = ():Brand_Backend[] => {
        return [
            {id:1,name:'グルコサミン'},
            {id:2,name:'ロコモア'},
            {id:3,name:'セサミン'},
        ]
    }

    public static show = (brandId:number):Brand_Backend | null=> {

        const list:Brand_Backend[] = this.index();

        const brand:Brand_Backend | undefined = list.find((brand:Brand_Backend) => {
            return brand.id === brandId;
        })

        if(brand){
            return brand;
        }else{
            return null
        }

    }

    // public static create:any = ():any => {
    //     const site_id = Math.floor(Math.random() * 100);

    //     return {site_id};
    // }


    // public static update:any = (siteId:number,siteDataToUpdate:any) => {
    //     return siteDataToUpdate;
    // }

    // public static destroy:any = (siteId:number) => {
    //     const latest_site_id = Math.floor(Math.random() * 100);
    //     return {latest_site_id};
    // }
}

