import { scenarioBlockTransformer } from './scenarioBlockTransformer'

export const scenarioTransformer = (frontendData:ScenarioWithActions):ScenarioWithActions_Backend => {

    const scenarioBlockList:ScenarioBlock_Backend[] = 
        frontendData.scenarioBlockList.map((scenarioBlockBackend:ScenarioBlock) => {
            return scenarioBlockTransformer(scenarioBlockBackend);
        })        

    const backendData:ScenarioWithActions_Backend = {
        id:frontendData.id,
        name:frontendData.name,
        first_header:frontendData.firstHeader,
        second_header:frontendData.secondHeader,        
        operator_name:frontendData.operatorName,
        operator_image_url:frontendData.operatorImageUrl ?? '',
        scenario_block_list:scenarioBlockList
    };

    return backendData;
}