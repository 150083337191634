export const nextBlockActionTransformer = (actionBackend:CB_Action_Backend):CB_Action => {

    const actionDetailBackend = actionBackend.action_detail as CB_NextBlockActionDetail_Backend;

    const actionDetail:CB_NextBlockActionDetail = {
        nextScenarioBlockId:actionDetailBackend.next_scenario_block_id
    }

    const nextBlockAction:CB_Action = {
        id:actionBackend.id,
        type:'nextBlock',
        actionDetail:actionDetail
    }

    return nextBlockAction;
    
}