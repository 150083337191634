import { actionTransformer } from './actionTransformer'

export const scenarioBlockTransformer = (scenarioBlockBackend:ScenarioBlock_Backend):ScenarioBlock => {

  const actionList:Action[] = scenarioBlockBackend.action_list.map((action_backend:Action_Backend) => {
      return actionTransformer(action_backend);
  })

  return {
      id:scenarioBlockBackend.id,
      name:scenarioBlockBackend.name,
      actionList:actionList
  }

}
