// style
import styled from 'styled-components';
import { useThemeColor } from 'hooks/global/color';

// config
import { useActionConfig } from 'hooks/config/scenario/action';

// modal
import { useDialog } from 'hooks/contexts/dialog';

// element component
import { ActiveIcon } from 'components/elements/icon/ActiveIcon';


// component
import { ReactElement, useMemo } from 'react'
import { TalkActionEditor } from './actionEditors/TalkActionEditor'


type HostActionEditorProps = {
    operatorImageUrl:string,
    action:Action,
    onUpdate:(newAction:Action) => void,
    onRemove:(action:Action) => void
}

export const HostActionEditor = ({
    operatorImageUrl,
    action,
    onUpdate,
    onRemove
}:HostActionEditorProps) => {

    const colors:ThemeColor = useThemeColor();

    const { hostActionObject } = useActionConfig();

    const {showDialog,closeDialog} = useDialog();

    const component:ReactElement = useMemo(() => {

        let component:ReactElement = <></>;

        switch (action.type) {
            case 'talk': 
                component = <TalkActionEditor action={action as TalkAction} operatorImageUrl={operatorImageUrl} onUpdate={(newAction) => onUpdate(newAction)} />; 
                break;
        }

        return component;
    },[action])

    return (
        <Style_HostActionEditor colors={colors}>
          <div className='actionLabel'>
            <span>{hostActionObject[action.type].label}</span>
          </div>
          <div>
            {component}
          </div>
          <div className="hostActionRemoveButton">
            <div className="removeButton"
              onClick={() => showDialog({
                  text:'削除してよろしいですか？',
                  onOk:() => {
                      onRemove(action);
                      closeDialog();
                  }
              })}
            >
              <ActiveIcon iconName='delete'/>
              <span>削除</span>
            </div>
          </div>
        </Style_HostActionEditor>
    )
}

const Style_HostActionEditor = styled.div<{
  colors:ThemeColor
}>`
&{
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: space-between;

  .actionLabel {
    display: flex ;

    span {
      font-size: .9em;
      background-color: ${(props) => props.colors.main};
      color:#fff;
      border-bottom-right-radius: 4px;
      padding: .6em 2em;
    }
  }

  .removeButton {
    margin: .4em auto;
    background-color: ${(props) => props.colors.shadowOnBackground};
    font-size: 1em;
    width: 80%;
    height: 2.4em;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: .4em;

    cursor: pointer;

    &:hover {
      opacity: .7;
    }
    &:active > * {
      transform: translateY(1px);
    }

  }
}
`
