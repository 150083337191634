import { createContext,Dispatch,useContext,useReducer } from 'react'

const initialState:DialogState = {
    isShown: false,
    text:undefined,
    onOk:undefined,
    onCancel:undefined
}

export const useDialog = () => {

    const [dialogState,dialogDispatch] = useDialogContext();

    const showDialog = ({text,onOk,onCancel}:DialogPayload) => {
        dialogDispatch({
            actionName:'show',
            text,
            onOk,
            onCancel
        })
    }

    const closeDialog = () => {
        dialogDispatch({
            actionName:'close'
        })
    }

    return {
        dialogState,
        showDialog,
        closeDialog
    }
}

export const useDialogContext = () => useContext(DialogContext);

const DialogContext = createContext<
    [DialogState,Dispatch<DialogAction>]
>([initialState,() => {}]);

export const DialogStore = ({ children }:any) => {

    const [state, dispatch] = useReducer((state:DialogState, action:DialogAction) => {

        switch (action.actionName) {
            case 'show':
                return {
                    ...state,
                    isShown:true,
                    text:action.text,
                    onOk:action.onOk,
                    onCancel:action.onCancel
                }
            case 'close':
                return { 
                    ...state,
                    isShown:false,
                    onOk:undefined,
                    onCancel:undefined
                }
            default:
                return state
        }

    }, initialState);


    return (
        <DialogContext.Provider value={[state, dispatch]}>
          {children}
        </DialogContext.Provider>
    )
}

