import { BrowserRouter, Route, Routes } from "react-router-dom";
import {
    ProtectedBrandAdminRoute,
    AdminAuthRoute,
    ProtectedSuperAdminRoute,
} from "hooks/ProtectedRoute";

import { DefaultLayout } from "layouts/Default";
import { WorkspaceLayout } from "layouts/Workspace";

import { LandingPage } from "pages/index";
import { Dashboard } from "pages/brand_admin/Dashboard";
import { SuperAdminDashboard } from "pages/super_admin/SuperAdminDashboard";
//import { SettingBrandListPage } from 'pages/settings/brands/index';
import { SettingBrandPage } from "pages/brand_admin/brands/:brandId/index";
import { SettingLpListPage } from "pages/brand_admin/brands/:brandId/lps/index";
import { SettingLpPage } from "pages/brand_admin/brands/:brandId/lps/:lpId/index";
import { SettingPopupListPage } from "pages/brand_admin/brands/:brandId/popups/index";
import { SettingPopupPage } from "pages/brand_admin/brands/:brandId/popups/:popupId/index";
import { SettingChatbotListPage } from "pages/brand_admin/brands/:brandId/chatbots/index";
import { SettingChatbotPage } from "pages/brand_admin/brands/:brandId/chatbots/:chatbotId/index";
import { SettingScenarioPage } from "pages/brand_admin/brands/:brandId/chatbots/:chatbotId/scenarios/:scenarioId/index";

import { ReportPaymentPage } from "pages/reports/brands/:brandId/index";

import Login from "pages/auth/login";



// プレビュー（簡易的なものなので、いずれなくします）
import { ChatbotPage } from 'chatbotSrc/pages/index'


export const Router = () => {

  const defaultLayoutPageList: RouteSource[] = [
      { name: "landing", path: "/", component: LandingPage },
      { name:'chatbot-preview', path :'/preview/scenario',component:ChatbotPage }, // 簡易
  ];
  const defaultLayoutAdminAuthPageList: RouteSource[] = [
      { name: "admin-index", path: "/admin", component: Login },
      { name: "admin-login", path: "/admin/login", component: Login },
  ];

  const workspaceLayoutBrandAdminPageList: RouteSource[] = [
      { name: "dashboard", path: "/brandadmin/dashboard", component: Dashboard },
      // {name:'settings-brandList',path:'/settings/brands',component:SettingBrandListPage},
      {
        name: "settings-brand",
        path: "/settings/brands/:brandId",
        component: SettingBrandPage,
      },
      {
        name: "settings-lpList",
        path: "/settings/brands/:brandId/lps",
        component: SettingLpListPage,
      },
      {
        name: "settings-lp",
        path: "/settings/brands/:brandId/lps/:lpId",
        component: SettingLpPage,
      },
      {
        name: "settings-popupList",
        path: "/settings/brands/:brandId/popups",
        component: SettingPopupListPage,
      },
      {
        name: "settings-popup",
        path: "/settings/brands/:brandId/popups/:popupId",
        component: SettingPopupPage,
      },
      {
        name: "settings-chatbotList",
        path: "/settings/brands/:brandId/chatbots",
        component: SettingChatbotListPage,
      },
      {
        name: "settings-chatbot",
        path: "/settings/brands/:brandId/chatbots/:chatbotId",
        component: SettingChatbotPage,
      },
      {
        name: "settings-scenario",
        path: "/settings/brands/:brandId/chatbots/:chatbotId/scenarios/:scenarioId",
        component: SettingScenarioPage,
      },

      {
        name: "reports-payments",
        path: "/reports/brands/:brandId/payments",
        component: ReportPaymentPage,
      },
  ];

  const workspaceLayoutSuperAdminPageList: RouteSource[] = [
      { name: "dashboard", path: "/admin/dashboard", component: SuperAdminDashboard },
      // {name:'settings-brandList',path:'/settings/brands',component:SettingBrandListPage},
      {
        name: "settings-brand",
        path: "/settings/brands/:brandId",
        component: SettingBrandPage,
      },
      {
        name: "settings-lpList",
        path: "/settings/brands/:brandId/lps",
        component: SettingLpListPage,
      },
      {
        name: "settings-lp",
        path: "/settings/brands/:brandId/lps/:lpId",
        component: SettingLpPage,
      },
      {
        name: "settings-popupList",
        path: "/settings/brands/:brandId/popups",
        component: SettingPopupListPage,
      },
      {
        name: "settings-popup",
        path: "/settings/brands/:brandId/popups/:popupId",
        component: SettingPopupPage,
      },
      {
        name: "settings-chatbotList",
        path: "/settings/brands/:brandId/chatbots",
        component: SettingChatbotListPage,
      },
      {
        name: "settings-chatbot",
        path: "/settings/brands/:brandId/chatbots/:chatbotId",
        component: SettingChatbotPage,
      },
      {
        name: "settings-scenario",
        path: "/settings/brands/:brandId/chatbots/:chatbotId/scenarios/:scenarioId",
        component: SettingScenarioPage,
      },

      {
        name: "reports-payments",
        path: "/reports/brands/:brandId/payments",
        component: ReportPaymentPage,
      },
  ];

  return (
    <BrowserRouter>
      <Routes>
        {defaultLayoutPageList.map((route: RouteSource) => {
          return (
            <Route
              path={route.path}
              key={route.path}
              element={
                <DefaultLayout
                  routeName={route.name}
                  pageComponent={route.component}
                />
              }
            />
          );
        })}

        {defaultLayoutAdminAuthPageList.map((route: RouteSource) => {
          return (
            <Route
              path={route.path}
              key={route.path}
              element={
                <AdminAuthRoute>
                  <DefaultLayout
                    routeName={route.name}
                    pageComponent={route.component}
                  />
                </AdminAuthRoute>
              }
            />
          );
        })}

        {workspaceLayoutBrandAdminPageList.map((route: RouteSource) => {
          return (
            <Route
              path={route.path}
              key={route.path}
              element={
                <ProtectedBrandAdminRoute>
                  <WorkspaceLayout
                    routeName={route.name}
                    pageComponent={route.component}
                  />
                </ProtectedBrandAdminRoute>
              }
            />
          );
        })}

        {workspaceLayoutSuperAdminPageList.map((route: RouteSource) => {
          return (
            <Route
              path={route.path}
              key={route.path}
              element={
                <ProtectedSuperAdminRoute>
                  <WorkspaceLayout
                    routeName={route.name}
                    pageComponent={route.component}
                  />
                </ProtectedSuperAdminRoute>
              }
            />
          );
        })}
      </Routes>
    </BrowserRouter>
  );
};
