// style
import styled from 'styled-components';
import { useThemeColor } from 'hooks/global/color';

// component
import {ReportTable} from './reportTable/ReportTable'

type PaymentReportProps = {

}
export const PaymentReport = ({

}:PaymentReportProps) => {

    const colors:ThemeColor = useThemeColor();

    const paymentReportList = [

    ];

    return (
        <Style_PaymentReport colors={colors}>
          <div className="paymentTableArea">
            <ReportTable />
          </div>
        </Style_PaymentReport>
    )
}
const Style_PaymentReport = styled.div<{
  colors:ThemeColor
}>`
  .paymentTableArea {
    width: 100%;
    overflow-x: auto;
  }
`
