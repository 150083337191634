import { useState } from 'react';

// style
import styled from 'styled-components';
import { useThemeColor } from 'hooks/global/color';

// element component
import { TabSelector } from 'components/elements/ui/TabSelector';

// component
import { ActionListBody } from './ActionListBody'

export type ActionListEditMode = ('edit' | 'add');

type ActionListProps = {
    actionList:Action[],
    selectedAction:Action | undefined,
    onSelectAction:(action:Action | undefined) => void
    onAddAction:(index:number,newAction:Action) => void,
    onSortAction:(newIndex:number,oldIndex:number) => void
}

export const ActionList = ({
    actionList,
    selectedAction,
    onSelectAction,
    onAddAction,
    onSortAction
}:ActionListProps) => {

    const colors:ThemeColor = useThemeColor();

    const tabDataList = [
        {code:'edit',name:'編集'},
        {code:'add',name:'追加'}
    ];

    const [mode,setMode] = useState<ActionListEditMode>('edit');


    return (
        <Style_ActionList colors={colors}>
          <div className="actionListModeTab">
            <TabSelector 
              tabDataList={tabDataList} 
              currentTabCode={mode}
              onSelect={(code:string) => setMode(code as ActionListEditMode)}
            />
          </div>
          <div className="actionListBodyArea">
            <ActionListBody 
              actionList={actionList}
              mode={mode}
              selectedAction={selectedAction}
              onSelectAction={onSelectAction}
              onAddAction={onAddAction}
              onSortAction={onSortAction}
            />
          </div>
        </Style_ActionList>
    )
}

const Style_ActionList = styled.div<{
  colors:ThemeColor
}>`

& {
  height: 100%;
  display: flex;
  flex-flow: column;

  .actionListModeTab {
    flex-shrink: 0;
    font-size:.9em;
    border-bottom: 1px solid ${(props) => props.colors.border};

    > * {
      justify-content: space-between;
      > * {
        width:100%;
      }
    }
  }

  .actionListBodyArea {
    flex-grow: 1;
    overflow-y: auto;
  }
}
`