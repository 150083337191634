import { useState } from 'react';

// style
import styled from 'styled-components';
import { useThemeColor } from 'hooks/global/color';

// element components
import { ActiveIcon } from '../icon/ActiveIcon';

type SelectInputProps ={
    selectOptionList:SelectOption[],
    value:string | number,
    withHiddenOption?:true,
    hiddenOptionText?:string
    onChange: (newValue:string | number) => void
}

export const SelectInput = ({
    selectOptionList,
    value,
    withHiddenOption,
    hiddenOptionText = '選択してください',
    onChange
}:SelectInputProps) => {

    const colors:ThemeColor = useThemeColor();

    return (
        <Style_SelectInput
          colors={colors}
        >
          <select
            value={value}
            onChange={(e) => onChange(e.target.value)}
          >
            {withHiddenOption && (
              <option hidden>{hiddenOptionText}</option>
            )}
            {selectOptionList.map((option:SelectOption,index:number) => {
                return (
                  <option key={option.value + index} value={option.value}>
                    {option.label}
                  </option>
                )
            })}
          </select>
          <div className="selectInputIconArea">
            <ActiveIcon iconName='drawerDown'/>
          </div>
        </Style_SelectInput>
    )
}

const Style_SelectInput = styled.div<{
  colors:ThemeColor
}>`


& {
  position: relative;

  &:hover {
      opacity: .7;
    }

  select {
    width: 100%;
    border: 1px solid ${(props) => props.colors.border};
    box-shadow: 0 2px 0 -1px ${(props) => props.colors.border};
    border-radius: 4px;
    outline: none;
    padding: .6em .8em;
  
    padding-right: calc(.6em + 24px); // アイコン用のスペースを開ける
    cursor: pointer;
  
  }

  .selectInputIconArea {
    position: absolute;
    top:0;right:0;bottom:0;
    display: flex;
    align-items: center;
    pointer-events: none;
    width:24px;
    font-size: 16px;
    color: ${(props) => props.colors.main};
  }
}

`