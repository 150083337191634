import { scenarioBlockTransformer } from './scenarioBlockTransformer'

export const scenarioTransformer = (backendData:CB_Scenario_Backend):CB_Scenario => {

  const scenarioBlockList:CB_ScenarioBlock[] = 
      backendData.scenario_block_list.map((scenarioBlockBackend:CB_ScenarioBlock_Backend) => {
          return scenarioBlockTransformer(scenarioBlockBackend);
      })        

  const frontendData:CB_Scenario = {
      id:backendData.id,
      name:backendData.name,
      firstHeader:backendData.first_header,
      secondHeader:backendData.second_header,        
      operatorName:backendData.operator_name,
      operatorImageUrl:backendData.operator_image_url ?? '',
      scenarioBlockList:scenarioBlockList
  };

  return frontendData;
}