// font awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// icons
import { 
    faSquareFull,
    faPlus,
    faMinus,
    faPen,
    faXmark,
    faMagnifyingGlass,
    faChartColumn,
    faGear,
    faGift,
    faWindowRestore,
    faAngleLeft,
    faAngleDown,
    faCaretRight,
    faCaretDown,
    faTrashCan,
    faSort,
} from '@fortawesome/free-solid-svg-icons';


type ActiveIconProps = {
    iconName?:string,
    className?:string
}

export const ActiveIcon = ({
    iconName = 'none',
    className = ''
}:ActiveIconProps) => {

    const iconList = {
        none:faSquareFull, // 未設定用
        plus:faPlus,
        minus:faMinus,
        edit:faPen,
        close:faXmark,
        delete:faTrashCan,
        search:faMagnifyingGlass,
        sort:faSort,
        report:faChartColumn,
        setting:faGear,
        brand:faGift,
        popup:faWindowRestore,
        left:faAngleLeft,
        down:faAngleDown,
        drawerRight:faCaretRight,
        drawerDown:faCaretDown,
    }
    
    return (
        <FontAwesomeIcon icon={(iconList as any)[iconName]} className={className} />
    )
}