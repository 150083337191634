const full:CB_PaymentActionDetail_Backend = {
    payment_list:[
        'creditCard',
        'cashOnDelivery',
        'bankTransfer',
        'konbini'
    ]
}

export const paymentActionMock:CB_PaymentAction_Backend = {
    id:'test_payment' + new Date().getTime(),
    type:'payment',
    action_detail:full
}
