// style
import styled from 'styled-components';
import { useThemeColor } from 'hooks/global/color';

// element component
import {IconTextButton} from 'components/elements/ui/IconTextButton';

type TagDisplayProps = {
    headDisplay:string,
    tagAsStr:string
}

export const TagDisplay = ({
    headDisplay,
    tagAsStr
}:TagDisplayProps) => {

    const colors:ThemeColor = useThemeColor();

    return (
      <Style_TagDisplay colors={colors}>
        <div className="headRack">
          <h3>{headDisplay}</h3>
          <IconTextButton text="COPY" size="12px"/>
        </div>
        <div className="tagBox">
          <span>{tagAsStr}</span>
        </div>
      </Style_TagDisplay>
    )
}

const Style_TagDisplay = styled.div<{
  colors:ThemeColor
}>`

  .headRack {
    margin-bottom:.2em;

    display: flex;
    align-items: center;
    justify-content: space-between;

    h3 {
      font-weight: bold;
      font-size: .8em;
    }
  }
  
  .tagBox {
    border:1px solid ${(props) => props.colors.border};
    border-radius: 4px;
    padding: 1em;
    font-size:.9em;
  }

`