import { useEffect, useState } from "react";
import { ReactSortable, SortableEvent } from "react-sortablejs";

// config
import { useActionConfig } from 'hooks/config/scenario/action';

// style
import styled from 'styled-components';
import { useThemeColor } from 'hooks/global/color';

// element component
import { ActiveIcon } from "components/elements/icon/ActiveIcon";

// component
import { AddActionInterface } from "./AddActionInterface";

// local types
import { ActionListEditMode } from '../index';


type ActionListBodyProps = {
    actionList:Action[],
    mode:ActionListEditMode,
    selectedAction:Action | undefined,
    onSelectAction:(action:Action | undefined) => void
    onAddAction:(index:number,newAction:Action) => void,
    onSortAction:(newIndex:number,oldIndex:number) => void
}

export const ActionListBody = ({
    actionList,
    mode,
    selectedAction,
    onSelectAction,
    onAddAction,
    onSortAction
}:ActionListBodyProps) => {

    const colors:ThemeColor = useThemeColor();

    const {actionObject,hostActionTypeList} = useActionConfig();

    useEffect(() => {
        if(mode === 'add') onSelectAction(undefined);
    },[mode])

    // これも別の hooks としてまとめた方がよいと思う
    const actionLabel = (action:Action) => {
        if( action.type === 'talk'){
            const talkAction = action as TalkAction;
            return talkAction.actionDetail.text;
        }else{
            if( action.type in actionObject ){
                const actionTypeKey = action.type as (keyof typeof actionObject)
                return actionObject[actionTypeKey].label
            }else{
                return action.type
            }
        }
    }

    const onSortEnd = (e:SortableEvent) => {

        if( e.newIndex === undefined || e.oldIndex === undefined){
            return false;
        }

        const newIndex:number = e.newIndex;
        const oldIndex:number = e.oldIndex;

        onSortAction(newIndex,oldIndex);

    }

    return (
        <Style_ActionListBody colors={colors}>

          <ReactSortable 
            className="sortableWrapper"
            list={actionList}
            animation={160}
            setList={() => {}} // これはいろんなタイミングで走ってしまうのでスルー。間違えるといかんのであえて空を記述している
            onEnd={(e:SortableEvent) => onSortEnd(e)}
            handle=".js-sortHandle"
            dragClass="isDragged"
          >
            {actionList.map((action:Action,index:number) => {

                const isHost = hostActionTypeList.includes(action.type);              

                const isSelected = (selectedAction) && (action.id === selectedAction.id);

                return(
                  <div key={action.id} className={'sortableWarapper'}>

                    <div className={'addInterfaceArea' + (mode !== 'add' ? ' isHidden' : '')}>
                      <AddActionInterface 
                        onAddNewAction={(newAction:Action) => onAddAction(index,newAction)}
                      />
                    </div>

                    <div 
                      className={
                        'actionBlock' + 
                        (isHost ? ' isHost' : ' isGuest') +
                        (isSelected ? ' isSelected' : '') + 
                        (mode === 'add' ? ' justDisplay' : '')
                      } 
                    >
                      <div className="actionSelectBalloonArea">
                        <div className={'actionSelectBalloon'}
                          onClick={() => onSelectAction(action)}
                        >
                          <div className="actionLabel">{actionLabel(action)}</div>
                        </div>
                      </div>
                      { mode === 'edit' && (
                        <div className="sortButton js-sortHandle">
                          <ActiveIcon iconName="sort" />
                        </div>
                      )}
                    </div>

                  </div>
                )
            })}

            <div className={'addInterfaceArea' + (mode !== 'add' ? ' isHidden' : '')}>
              <AddActionInterface 
                onAddNewAction={(newAction:Action) => onAddAction(actionList.length,newAction)}
              />
            </div>

          </ReactSortable>
        </Style_ActionListBody>
    )
}

const Style_ActionListBody = styled.div<{
    colors:ThemeColor
}>`

  & {
    padding: 24px 0;

    .actionBlock {
      display: flex;

      > *:nth-child(1){
        flex-grow: 1;
      }
      > *:nth-child(2){
        flex-shrink: 0;
      }
    }


    .isDragged {
      .addInterfaceArea {
        opacity: 0;
      }

      .actionBlock {
        background-color:transparent!important;
      }
    }

    .addInterfaceArea {
      padding: 0 .6em;
      height:2.4em;
      
      overflow: hidden;
      transition: height .2s, opacity .1s;

      display: flex;
      align-items: center;
      > * {
        width:100%;
      }

      &.isHidden {
        opacity:0;
        height:0;
        pointer-events: none;
      }
    }

    .actionBlock {
      font-size: .8em;
      padding: .6em .6em;

      display: flex;
      align-items: center;
      justify-content: space-between;


      &:hover {
        background-color: ${(props) => props.colors.deeperBackground};
      }  
      &.isSelected{
        background-color: ${(props) => props.colors.deeperBackground};
      }  

      .actionSelectBalloonArea {
        display: flex;
      }

      &.isGuest .actionSelectBalloonArea {
        flex-flow: row-reverse;
      }

      &.justDisplay {
        pointer-events: none;
        opacity: .4;
      }



      .actionSelectBalloon {
        padding: 1em .8em;
        display: flex;
        align-items: center;
        border-radius: 10px;
        cursor: pointer;

        white-space: pre-wrap;
        line-height: 1.6;

        &:hover {
          opacity:.7;
        } 
        &:active {
          transform: translateY(1px);
        } 
      
      }

      &.isHost .actionSelectBalloon {
        border: 2px solid ${(props) => props.colors.main};
        background-color: ${(props) => props.colors.background};
        border-bottom-left-radius: 0px;
      }
      &.isGuest .actionSelectBalloon {
        background-color: ${(props) => props.colors.main};
        color:#fff;
        border-bottom-right-radius: 0px;
      }

      .sortButton {
        margin-left: .4em;
        width: 1.2em;
        display: flex;
        justify-content: center;
        align-items: center;

        font-size: 1.4em;
        color: ${(props) => props.colors.main};
        opacity: .7;
        cursor: grab;

        &:hover {
          opacity: 1;
        }

        &:active {
          transform: scale(.8);
          cursor: grabbing;
        }
      }
    }

  }

`