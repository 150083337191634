import { useMemo } from 'react';

// style
import styled from 'styled-components';
import { useThemeColor } from 'hooks/global/color';

// hooks
import { useModal } from 'hooks/contexts/modal';
import { useDialog } from 'hooks/contexts/dialog';

// element component
import { ActiveIcon } from 'components/elements/icon/ActiveIcon';
import { OneLineInputPanel } from 'components/generals/OneLineInputPanel'

type ScenarioBlockListProps = {
    scenarioBlockList:ScenarioBlock[],
    selectedScenarioBlockId:string,
    onSelected:(selectedScenarioId:string) => void,
    onAddScenarioBlock:(scenarioBlockName:string) => void,
    onEditScenarioBlockName:(newName:string) => void,
    onDeleteScenarioBlock:(scenarioBlock:string) => void
}

export const ScenarioBlockList = ({
    scenarioBlockList,
    selectedScenarioBlockId,
    onSelected,
    onAddScenarioBlock,
    onEditScenarioBlockName,
    onDeleteScenarioBlock
}:ScenarioBlockListProps) => {

    const colors:ThemeColor = useThemeColor();

    const { showModal,closeModal } = useModal();
    const { showDialog,closeDialog } = useDialog();

    const selectedBlockName:string = useMemo(() => {

        const selectedScenarioBlock = scenarioBlockList.find((scenarioBlock:ScenarioBlock) => {
            return scenarioBlock.id === selectedScenarioBlockId;
        })

        if(selectedScenarioBlock === undefined) return 'none';

        return selectedScenarioBlock.name;

    },[selectedScenarioBlockId])

    return (
        <Style_ScenarioBlockList colors={colors}>
          <div className="scenarioBlockSelectorHead">
            <span>シナリオブロック</span>
          </div>
          <div className="scenarioBlockSelector">
            {scenarioBlockList ? (
                scenarioBlockList.map((scenarioBlock:ScenarioBlock) => {
                    const isSelected = (scenarioBlock.id === selectedScenarioBlockId);
                    return (
                      <div 
                        key={scenarioBlock.id}
                        className={'scenarioBlockOption' + (isSelected ? ' isSelected' : '')}
                        onClick={() => onSelected(scenarioBlock.id)}
                      >
                        <span>{scenarioBlock.name}</span>
                      </div>
                    )
                })
            ) : <></>}
          </div>
          <div className="scenarioBlockButtonArea">
            <span
              onClick={() => showModal({
                  contents:<OneLineInputPanel 
                            okButtonText='追加'
                            placeholder="新規シナリオブロック"
                            onOkButtonClicked={(value:string) => {
                                onAddScenarioBlock(value);
                                closeModal();
                            }}
                          />,
                  baseStyle:'standard'
              })}
            >
              <ActiveIcon iconName='plus' />
            </span>
            <span
              onClick={() => showModal({
                  contents:<OneLineInputPanel 
                            okButtonText='編集'
                            initialText={selectedBlockName}
                            placeholder="シナリオブロック名"
                            onOkButtonClicked={(value:string) => {
                                onEditScenarioBlockName(value);
                                closeModal();
                            }}
                          />,
                  baseStyle:'standard'
              })}
            >
              <ActiveIcon iconName='edit' />
            </span>
            <span
              onClick={() => showDialog({
                  text:`『${selectedBlockName}』を削除\n` + 'シナリオブロックを削除すると、中のアクションも削除されます。よろしいですか？',
                  onOk:() => {
                      onDeleteScenarioBlock(selectedScenarioBlockId);
                      closeDialog();
                  }
              })}
            >
              <ActiveIcon iconName='minus' />
            </span>
          </div>
        </Style_ScenarioBlockList>
    )
}

const Style_ScenarioBlockList = styled.div<{
  colors:ThemeColor
}>`
& {
  height: 100%;
  font-size: 0.9em;
  display: flex;
  flex-flow: column;
  justify-content: space-between;

  .scenarioBlockSelectorHead {
    flex-shrink: 0;
    border-bottom:1px solid ${(props) => props.colors.border};
    text-align:center;
    padding: .8em 0;
    font-weight: bold;
  }

  .scenarioBlockSelector {
    flex-grow: 1;
    overflow-y: auto;

    .scenarioBlockOption {
      padding: .8em 1em;
      border-bottom: 1px solid ${(props) => props.colors.lightBorder};

      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;

      &.isSelected {
        background-color: ${(props) => props.colors.main};
        color:#fff;
      }

      &:not(.isSelected){
        &:hover {
          background-color: ${(props) => props.colors.deeperBackground};
        }
        &:active {
          transform: translateY(1px);
        }
      }
    }
  }

  .scenarioBlockButtonArea {
    flex-shrink: 0;
    height:3em;
    border-top:1px solid ${(props) => props.colors.border};
    padding: 0 1em;

    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap:.8em;

    > span {
      background-color: ${(props) => props.colors.background};
      border: 1px solid ${(props) => props.colors.main};
      border-radius: 4px;
      width:3em;
      height: 2em;
      display: flex;
      justify-content: center;
      align-items: center;

      cursor: pointer;

      &:hover {
        opacity: .7;
      }
      &:active {
        transform:translateY(1px);
      }

      &:nth-child(2){
        margin-right: auto;
      }
    }
  }
}
`