// rest
import {BrandMswHandler} from './rest/brand'
import {ScenarioMswHandler} from './rest/scenario'

// ステータスコード切り替え
const statusCode = 200;

const brandMswHandler = new BrandMswHandler(statusCode).apis;
const scenarioMswHandler = new ScenarioMswHandler(statusCode).apis;

export const handlers = [
    ...brandMswHandler,
    ...scenarioMswHandler,
];

