import { useState,useRef, ChangeEvent } from "react";

// style
import styled from 'styled-components';
import { useThemeColor } from 'hooks/global/color';

// element compoenent
import { IconTextButton } from 'components/elements/ui/IconTextButton';

// component
import { ImageCropper } from "./ImageCropper"


type ImageAddPanelProps = {
    type:ImageType,
    onOk:(imageSrc:string) => void,
    onCancel:() => void
}

export const ImageAddPanel = ({
    type,
    onOk,
    onCancel
}:ImageAddPanelProps) => {

    const colors:ThemeColor = useThemeColor();

    const inputFileRef = useRef<HTMLInputElement>(null);

    const [imageSrc,setImageSrc] = useState<string>('/img/mocks/lp.jpg');
    const [croppedImageSrc,setCroppedImageSrc] = useState<string>('');


    const handleImageUploadButtonClick = () => {
        if(inputFileRef.current !== null){
            inputFileRef.current.click();
        }
    }
    

    const handleImageInputChange = (e:ChangeEvent<HTMLInputElement>):void => {

        const fileList = e.target.files;
        if(fileList === null) return;

        const file:File = fileList[0];

        getBase64(file)
        .then((base64:string | ArrayBuffer) => {
            setImageSrc(base64 as string);
        })

    }

    const getBase64 = (file:File):Promise<(string | ArrayBuffer)> => {
        return new Promise((resolve,reject) => {
            // Make new FileReader
            const reader = new FileReader();

            // Convert the file to base64 text
            reader.readAsDataURL(file);

            // on reader load somthing...
            reader.onload = () => {
                // Make a fileInfo Object
                if(reader.result){
                    const baseURL:string | ArrayBuffer = reader.result;
                    resolve(baseURL);
                }else{
                    reject();
                }
            };
        });
    };

    return (
        <Style_ImageAddPanel colors={colors}>
          <div className="cropperArea">
            <div>
              <ImageCropper 
                cropperAreaclassName="cropBoxArea"
                imageSrc={imageSrc}
                circled={type === 'operator'}
                onCrop={(newCroppedImageSrc) => {
                    setCroppedImageSrc(newCroppedImageSrc);
                }}
              />
            </div>
          </div>
          <div className="cropControlArea">
            <IconTextButton 
              text="画像を選択"
              onClick={handleImageUploadButtonClick}
            />

            <div className="cropPreviewArea">
              <h3>プレビュー</h3>
              <div className={'croppedImagePreview' + (type === 'operator' ? ' round' : '')}>
                <img src={croppedImageSrc} />
              </div>
            </div>

            <div className="cropSubmitButtonArea">
              <IconTextButton text="OK" onClick={() => onOk(croppedImageSrc)} />
              <IconTextButton text="キャンセル" ghost onClick={() => onCancel()} />
            </div>

            <input 
              type="file" 
              hidden
              ref={inputFileRef}
              onChange={(e:ChangeEvent<HTMLInputElement>) => handleImageInputChange(e)}
            />

          </div>
        </Style_ImageAddPanel>
    )
}

const Style_ImageAddPanel = styled.div<{
  colors:ThemeColor
}>`

& {
  width: 100%;
  display: flex;
  justify-content: space-between;
  column-gap:1em;

  .cropperArea {
    flex-grow: 1;
  }

  .cropControlArea {
    flex-shrink: 0;
    width: 240px;
  }

  .cropperArea {
    display: flex;
    justify-content: center;
    align-items: center;
    .cropBoxArea {
      width: 320px;
      height: 320px;
    }
  }

  .cropControlArea {
    display: flex;
    flex-flow: column;
    justify-content: space-between;

    .cropPreviewArea {

      h3 {
        font-size: .9em;
        font-weight: bold;
        opacity: 0.7;
        text-align: center;
        margin-bottom: 1em;
      }
      .croppedImagePreview {
        text-align: center;

        &.round {
          > img {
            border-radius: 100%;
          }
        }

        > img {
          border: 1px solid ${(props) => props.colors.border};
          overflow: hidden;
          max-width: 100%;
          max-height: 120px;
        }
      }
    }

    .cropSubmitButtonArea {
      display: flex;
      flex-flow: column;
      justify-content: space-between;
      row-gap: 1em;
    }

  }

}
`