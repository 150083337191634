import { useState } from 'react';

// style
import styled from 'styled-components';
import { useThemeColor } from 'hooks/global/color';

type ToggleSwitchProps ={
    value:boolean,
    onClickSwitch:() => void
}

export const ToggleSwitch = ({
    value,
    onClickSwitch
}:ToggleSwitchProps) => {

    const colors:ThemeColor = useThemeColor();

    return (
        <Style_ToggleSwitch 
          className={value ? 'isSwitchOn' : ''}
          colors={colors} 
          onClick={(e) => {
              e.stopPropagation();
              onClickSwitch()}
          }
        >
          <div />
        </Style_ToggleSwitch>
    )
}

const css_width = '56px';
const css_height = '28px';

const Style_ToggleSwitch = styled.div<{
  colors:ThemeColor
}>`

& {
  display:inline-block;
  width:${css_width};
  height: ${css_height};
  border-radius: ${css_height};
  background-color: ${(props) => props.colors.disabled};
  box-shadow:0px 0px 4px 0 rgba(0,0,0,.1) inset;
  overflow: hidden;
  cursor: pointer;

  > * {
    width:${css_height};
    height: ${css_height};
    border:3px solid transparent;
    border-radius: 100%;

    &::after {
      display: block;
      content: '';
      background-color: #fff;
      width: 100%;
      height: 100%;
      border-radius: 100%;
      box-shadow: 0px 1px 4px 0 rgba(0,0,0,.5);
    }
  }
}

& {
  > * {
      transition: transform 60ms;
  }
}


&.isSwitchOn {
  background-color: ${(props) => props.colors.safe};

  > * {
    transform: translateX( calc( ${css_width} - ${css_height} ) );
  }
}
`