import { configureStore } from '@reduxjs/toolkit';

import { useSelector as rawUseSelector, TypedUseSelectorHook } from 'react-redux';

// slice
import { styleThemeRreducer } from './slice/styleThemeSlice';

export const store = configureStore({
    reducer: {
        styleTheme:styleThemeRreducer,
    },

    middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
        // payload の type が　seliarize できない場合に起こる console エラーを回避
        // 参考：https://zenn.dev/luvmini511/articles/91a76a34909555
        serializableCheck: {
            // Ignore these action types
            ignoredActions: [],
            // // Ignore these paths in the state
            ignoredPaths: [],
        },
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export const useSelector: TypedUseSelectorHook<RootState> = rawUseSelector;