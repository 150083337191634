import { useState } from 'react';

// style
import styled from 'styled-components';
import { useThemeColor } from 'hooks/global/color';
import { CardDesignMixin } from 'styleValues/mixins';
import { controlAreaHeight,controlAreaGapHeight,mainWrapperPadding } from 'styleValues/layouts';

// local hooks
import { useSettingScenarioPageHooks } from "./settingScenatioPageHooks";

// element component
import { LoadingIcon } from 'components/elements/icon/LoadingIcon';

// component
import { ScenarioHeader } from './ScenarioHeader';
import { ScenarioOperator } from './ScenarioOperator';
import { ScenarioBlockList } from './ScenarioBlockList';
import { ActionListEditor } from './ActionListEditor';
import { PreviewBox } from './PreviewBox'


type SettingScenarioPageViewProps = {
    brandId:number,
    chatbotId:number,
    scenarioId:number,
}

export const SettingScenarioPageView = ({
    brandId,
    chatbotId,
    scenarioId,
}:SettingScenarioPageViewProps) => {

    const colors:ThemeColor = useThemeColor();

    const [previwMode,setPreviewMode] = useState<boolean>(false);

    const {
        editStarted,
        scenarioData,
        selectedScenarioBlockId,
        selectedScenarioBlock,
        selectedAction,

        setSelectedAction,
        handleOperatorUpdate,
        handleSelectScenarioBlock,
        handleAddScenarioBlock,
        handleEditScenarioBlock,
        handleDeleteScenarioBlock,
        handleUpdateActionList
    }:{

        editStarted:boolean,
        scenarioData:ScenarioWithActions | undefined,
        selectedScenarioBlockId:string,
        selectedScenarioBlock:ScenarioBlock | undefined,
        selectedAction:Action | undefined,

        setSelectedAction:(action:Action | undefined) => void,
        handleOperatorUpdate:(data:{imageUrl?:string,name?:string}) => void
        handleSelectScenarioBlock:(selectedScenarioBlockId:string) => void
        handleAddScenarioBlock:(newName:string) => void,
        handleEditScenarioBlock:(newName:string) => void,
        handleDeleteScenarioBlock:(scenarioBlockIdToDel:string) => void
        handleUpdateActionList:(newActionList:Action[]) => void,

    } = useSettingScenarioPageHooks({brandId,chatbotId,scenarioId});


    return (
        <Style_SettingScenarioPageView colors={colors}>
          <div className="scenarioControlArea">
            <ScenarioHeader 
              brandId={brandId}
              chatbotId={chatbotId}
              scenarioData={scenarioData}
              editStarted={editStarted}
              onPreviewButtonClick={() => setPreviewMode(true)}
            />
          </div>

          <div className="scenarioMainArea">
            <div className="scenarioSideArea">
              <div className="scenarioOperatorArea">
                {scenarioData ? (
                      <ScenarioOperator 
                        brandId={brandId}
                        name={scenarioData.operatorName}
                        imageUrl={scenarioData.operatorImageUrl}
                        onUpdateImage={(newUrl:string) => {handleOperatorUpdate({imageUrl:newUrl})}}
                        onUpdateName={(newName:string) => {handleOperatorUpdate({name:newName})}}
                      />
                ) : (
                    <div className="loadingBox">
                      <LoadingIcon icon="ripple" color={colors.main} />
                    </div>
                )}
              </div>

                <div className="scenarioBlockListArea">
                  {scenarioData ? (
                      <ScenarioBlockList 
                        scenarioBlockList={scenarioData.scenarioBlockList} 
                        selectedScenarioBlockId={selectedScenarioBlockId}
                        onSelected={(selectedScenarioBlockId) => handleSelectScenarioBlock(selectedScenarioBlockId)}
                        onAddScenarioBlock={(scenarioBlockName:string) => handleAddScenarioBlock(scenarioBlockName)}
                        onEditScenarioBlockName={(scenarioBlockName:string) => handleEditScenarioBlock(scenarioBlockName)}
                        onDeleteScenarioBlock={(scenarioBlockId:string) => handleDeleteScenarioBlock(scenarioBlockId)}
                      />
                  ) : (
                      <div className="loadingBox">
                        <LoadingIcon icon="ripple" color={colors.main} />
                      </div>
                  )}
              </div>

            </div>
            <div className="actionListArea">
              {(scenarioData && selectedScenarioBlock ) ? (
                  <ActionListEditor
                    scenarioData={scenarioData}
                    actionList={selectedScenarioBlock.actionList}
                    selectedAction={selectedAction}
                    onSelectAction={(action:Action | undefined) => setSelectedAction(action)}
                    onUpdateActionList={(newActionList) => {handleUpdateActionList(newActionList)}}
                  />
              ) : (
                <div className="loadingBox">
                  <LoadingIcon icon="ripple" color={colors.main} />
                </div>
              )}
            </div>
          </div>

          {(previwMode && scenarioData) && (
              <PreviewBox scenarioData={scenarioData} onCloseRequest={() => setPreviewMode(false)}/>
          )}

        </Style_SettingScenarioPageView>
    )
}

const Style_SettingScenarioPageView = styled.div<{
    colors:ThemeColor
}>`
  & {
    max-width:1200px;
    margin: 0 auto;
    display: flex;
    flex-flow: column;
    gap: ${controlAreaGapHeight};

    .scenarioControlArea {
      ${CardDesignMixin}
      height: ${controlAreaHeight};
    }

    .scenarioMainArea {
      flex-grow: 1;
      height: calc(100vh - ${controlAreaHeight} - ${controlAreaGapHeight} - ${mainWrapperPadding} - ${mainWrapperPadding});

      display: flex;
      column-gap: 16px;

      .scenarioSideArea {
        flex-shrink: 0;
        width: 240px;
        max-height: 640px;

        display: flex;
        flex-flow: column;
        justify-content: space-between;
        row-gap: .4em;
      }

      .scenarioOperatorArea {
        height: 160px;
      }

      .scenarioBlockListArea {
        flex-grow: 1;
        overflow-y: auto;
      }

      .actionListArea {
        flex-grow: 1;
      }

      .scenarioOperatorArea,.scenarioBlockListArea,.actionListArea {
        ${CardDesignMixin}
      }
    }

  }

  & {
    .loadingBox {
      display: flex;
      justify-content: center;
      align-items: center;

      width:100%;
      height: 100%;
    }
  }

`