// style
import styled from 'styled-components';
import { useThemeColor } from 'hooks/global/color';

// modal
import { useModal } from 'hooks/contexts/modal'

// element component
import { ActiveIcon } from 'components/elements/icon/ActiveIcon';

// components
import { AddActionPanel } from './AddActionPanel'

type AddActionInterfaceProps = {
    onAddNewAction:(newAction:Action) => void
}

export const AddActionInterface = ({
    onAddNewAction
}:AddActionInterfaceProps) => {

    const colors:ThemeColor = useThemeColor();

    const { showModal,closeModal } = useModal();

    // このメソッドは別ファイルか何かに分割して管理したほうがいい。
    // どんどん増えるので
    const setNewAction = (actionType:ActionType):Action => {

        // シナリオブロックをまたいでユニークかどうかを判定
        const randomString:string = Math.random().toString(32).substring(2);
        const newActionId:string = randomString;

        const newAction:Action = {
            id:newActionId,
            type:actionType,
            actionDetail:undefined
        };

        if(actionType === 'talk') {
            newAction.actionDetail = {
                text:'新しいトーク'
            } as TalkActionDetail
        }
        if(actionType === 'nextBlock'){
            newAction.actionDetail = {
                nextScenarioBlockId:'none'
            } as NextBlockActionDetail
        }
        if(actionType === 'radioBranch'){
            newAction.actionDetail = {
                radioList:[
                    {
                        label:'A',
                        nextScenarioBlockId:'none'
                    },
                    {
                        label:'B',
                        nextScenarioBlockId:'none'
                    },
                ] 
          }  as RadioBranchActionDetail
        }

        if(actionType === 'payment'){
            newAction.actionDetail = {
                paymentList:[]
            } as PaymentActionDetail
        }


        return newAction;

    }

    const AddActionButton = ({isHostAction}:{isHostAction:boolean}) => {
        return (
            <div className="addActionButton"
              onClick={() => {
                  showModal({
                      contents:<AddActionPanel 
                                isHostAction={isHostAction} 
                                onSubmit={(actionType) => {
                                    const newAction = setNewAction(actionType);
                                    onAddNewAction(newAction);
                                    closeModal();
                                }}
                                onCancel={() => closeModal()}
                              />,
                      baseStyle:'standard'
                  });
              }}
            >
              <ActiveIcon iconName='plus' />
            </div>
        )
    }

    return (
        <Style_AddActionInterface colors={colors}>
          <AddActionButton isHostAction={true} />
          <AddActionButton isHostAction={false} />
        </Style_AddActionInterface>
    )
}

const Style_AddActionInterface = styled.div<{
    colors:ThemeColor
}>`

&{
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin: .4em 1em; */

  .addActionButton {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: .8em;
    width:2em;
    height: 2em;
    border-radius: 100%;

    cursor: pointer;

    &:hover {
      opacity: .7;
    }

    &:active{
      transform: translateY(1px);
    }

    &:nth-child(1){
      border-bottom-left-radius: 0px;
      border: 2px solid ${(props) => props.colors.safe};
      color:${(props) => props.colors.safe};
    }

    &:nth-child(2){
      border-bottom-right-radius: 0px;
      background-color: ${(props) => props.colors.safe};
      color: #fff;
    }

  }
}
`