import { useMemo } from 'react';

// settings
import { useActionConfig } from 'hooks/config/scenario/action'

// style
import styled from 'styled-components';
import { useThemeColor } from 'hooks/global/color';

// components
import { ActionList } from './ActionList';
import { HostActionEditor } from './hostActionEditor/HostActionEditor';
import { GuestActionEditor } from './guestActionEditor/GuestActionEditor';

type ActionListEditorProps ={
    scenarioData:ScenarioWithActions,
    actionList:Action[],
    selectedAction:Action | undefined,
    onSelectAction:(action:Action | undefined) => void,
    onUpdateActionList:(newActionList:Action[]) => void
}

export const ActionListEditor = ({
    scenarioData,
    actionList,
    selectedAction,
    onSelectAction,
    onUpdateActionList
}:ActionListEditorProps) => {

    const colors:ThemeColor = useThemeColor();

    const {hostActionTypeList} = useActionConfig();

    const displayState:('noSide' | 'host' | 'guest') = useMemo(() => {

        if(selectedAction === undefined) return 'noSide';

        if( hostActionTypeList.includes(selectedAction.type) ) return 'host';

        return 'guest';

    },[hostActionTypeList, selectedAction]);


    const handleAddAction = (index:number,newAction:Action) => {

        // deep copy
        const actionListToEdit:Action[] = Array.from(actionList);

        // 差し込み
        actionListToEdit.splice(index,0,newAction);

        // 更新
        onUpdateActionList(actionListToEdit);

    }

    const handleUpdateAction = (newAction:Action) => {

        // deep copy
        const actionListToEdit:Action[] = Array.from(actionList);

        // アップデートするアクションを探してすげかえる
        const targetIndex:number = actionListToEdit.findIndex((action:Action) => {
            return action.id === newAction.id;
        });

        actionListToEdit[targetIndex] = newAction;

        // 更新
        onUpdateActionList(actionListToEdit);

        // 閉じる
        onSelectAction(undefined);

    }

    const handleRemoveAction = (actionToRemove:Action) => {
        // deep copy
        const actionListToEdit:Action[] = Array.from(actionList);

        // アクションの index を検索
        const actionIndex:number = actionListToEdit.findIndex((action:Action) => {
            return action.id === actionToRemove.id;
        })

        // 指定 index をリムーブ
        actionListToEdit.splice(actionIndex,1);

        // 更新
        onUpdateActionList(actionListToEdit);

        // 閉じる
        onSelectAction(undefined);

    }

    const handleSortAction = (newIndex:number,oldIndex:number) => {

        const newActionList = arrayMove(
            Array.from(actionList),
            oldIndex,
            newIndex
        );

        // 更新
        onUpdateActionList(newActionList);

    }

    /**
     * 借用させてもらったメソッド
     * 参考：https://qiita.com/nowayoutbut/items/991515b32805e21f8892
     * @param {*} array 操作したい配列
     * @param {*} index 移動するアイテムの index
     * @param {*} to 
     * @returns 
     */
    const arrayMove = (array:any[], index:number, to:number):any[] => {

        if (index === to || index > array.length -1 || to > array.length - 1) {
            return array;
        }
    
        const value = array[index];
        const tail = array.slice(index + 1);
    
        array.splice(index);
    
        Array.prototype.push.apply(array, tail);
    
        array.splice(to, 0, value);
    
        return array;

    }  

  
    return (
      <Style_ActionListEditor colors={colors} className={displayState}>
        <div className="hostActionArea">
          { (selectedAction !== undefined && displayState === 'host') && (
            <HostActionEditor 
              operatorImageUrl={scenarioData.operatorImageUrl}
              action={selectedAction}
              onUpdate={(newAction) => handleUpdateAction(newAction)}
              onRemove={(action:Action) => handleRemoveAction(action)}
            />
          )}
        </div>
        <div className="listControlArea">
          <div className="actionListEditArea">
            <ActionList 
              actionList={actionList}
              selectedAction={selectedAction}
              onSelectAction={(action:Action | undefined) => onSelectAction(action)}
              onAddAction={(index:number,newAction:Action) => handleAddAction(index,newAction)}
              onSortAction={(newIndex,oldIndex) => handleSortAction(newIndex,oldIndex)}
            />
          </div>
        </div>
        <div className="guestActionArea">
          { (selectedAction !== undefined && displayState === 'guest') && (
              <GuestActionEditor 
                scenarioData={scenarioData}
                action={selectedAction} 
                onUpdate={(newAction) => handleUpdateAction(newAction)}
                onRemove={(action:Action) => handleRemoveAction(action)}
              />
          )}
        </div>
      </Style_ActionListEditor>
    )
}

const Style_ActionListEditor = styled.div<{
  colors:ThemeColor
}>`

// 構成
& {

  display: flex;
  justify-content: center;

  .hostActionArea,.guestActionArea {
    transition: width ease-in-out .5s,opacity .2s;
    overflow-x: hidden;
  }


  .listControlArea {
    flex-shrink: 0;
    width:30%;
    max-width: 400px;
  }


  &.noSide {
    .hostActionArea,.guestActionArea {
      width:100%;
      opacity: 0;
    }
  }

  &.host {
    .hostActionArea {
      width:100%;
    }

    .guestActionArea {
      width:40%;
      opacity: 0;
    }
  }

  &.guest {
    .hostActionArea {
      width:40%;
      opacity: 0;
    }

    .guestActionArea {
      width:100%;
    }
  }
}


& {
  height: 100%;
  overflow-y: hidden;

  .listControlArea {
    height: 100%;
    border-left:1px solid ${(props) => props.colors.main};
    border-right:1px solid ${(props) => props.colors.main};

    .actionListEditArea {
      height: 100%;
    }
  }
}
`