import { createSlice } from '@reduxjs/toolkit';

export const styleThemeSlice = createSlice({
    name: 'styleTheme',
    initialState:{
        themeName:'light',
    },
    reducers: {
        changeTheme: (state, action) => {
            return {
                ...state,
                ...action.payload,
            }
        },
    },
});


export const { changeTheme } = styleThemeSlice.actions;
export const styleThemeRreducer = styleThemeSlice.reducer;