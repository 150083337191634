// style
import styled from 'styled-components';
import { useThemeColor } from 'hooks/global/color';

// element components
import { TextInput } from 'components/elements/inputs/TextInput'
import { SelectInput } from 'components/elements/inputs/SelectInput'
import { ActiveIcon } from 'components/elements/icon/ActiveIcon';


type RadioBranchOptionInputProps = {
    radioBranchObj:RadioBranchObject
    selectOptionList:SelectOption[],
    onChange:(changedValue:RadioBranchObject) => void,
    onDelete:() => void
}

export const RadioBranchOptionInput = ({
    radioBranchObj,
    selectOptionList,
    onChange,
    onDelete
}:RadioBranchOptionInputProps) => {

    const colors:ThemeColor = useThemeColor();

    const handleLabelChange = (newLabel:string) => {
        const newRadioBranchObj:RadioBranchObject = {
            ...radioBranchObj,
            label:newLabel
        }
        onChange(newRadioBranchObj);
    }

    const handleSelectChange = (selectedId:string) => {

        const newRadioBranchObj:RadioBranchObject = {
            ...radioBranchObj,
            nextScenarioBlockId:selectedId
        }

        onChange(newRadioBranchObj);
    }

    return (
        <Style_RadioBranchOptionInput colors={colors}>
          <div className="radioBranchObjectHead">
            <div className="objectDeleteButton" onClick={() => onDelete()}>
              <ActiveIcon iconName='close' />
            </div>
          </div>
          <div className="labelArea">
            <TextInput 
              value={radioBranchObj.label} 
              placeholder="選択肢名を入力してください"
              onChange={handleLabelChange} 
            />
          </div>
          <div className="scenarioBlockArea">
            <SelectInput 
              selectOptionList={selectOptionList} 
              value={radioBranchObj.nextScenarioBlockId}
              withHiddenOption
              onChange={(id:string | number) => handleSelectChange(id.toString())} 
            />
          </div>
        </Style_RadioBranchOptionInput>
    )
}

const Style_RadioBranchOptionInput = styled.div<{
    colors:ThemeColor
}>`
  & {
    display: flex;
    flex-flow: column;
    row-gap: .6em;

    .radioBranchObjectHead {
      display: flex;
      justify-content: flex-end;

      .objectDeleteButton {
        font-size: 1em;
        cursor: pointer;

        &:hover {
          opacity: .7;
        }

        &:active {
          transform: translateY(1px);
        }
      }
    }

    .labelArea,.scenarioBlockArea {
      display: flex;
      flex-flow: column;
    }
  }

`