import {useEffect, useState, useMemo} from 'react';

// style
import styled from 'styled-components';
import { useThemeColor } from 'hooks/global/color';

// hooks
import { useDialog } from 'hooks/contexts/dialog';

// element component
import { ToggleSwitch } from 'components/elements/inputs/ToggleSwitch';


type ScenarioListProps = {
    chatbotId:number,
    onScenarioSelect:(scenarioId:number) => void
}

export const ScenarioList = ({
    chatbotId,
    onScenarioSelect
}:ScenarioListProps) => {

    const colors:ThemeColor = useThemeColor();

    const {showDialog,closeDialog} = useDialog();

    const [scenarioList,setScenarioList] = useState<Scenario[] | undefined>(undefined);

    const activenessRate:number = useMemo(() => {

        if(scenarioList === undefined) return 0;

        let activeScenarioCount = 0;

        // シナリオの isActive の個数を計算し、パーセンテージを算出
        scenarioList.forEach((scenario:Scenario) => {
            if(scenario.isActive) activeScenarioCount += 1;
        });

        if(activeScenarioCount === 0) return 0;

        const _activenessRate = Math.floor(100 / activeScenarioCount);

        return _activenessRate;

    },[scenarioList]);
    
    useEffect(() => {
        getThenSetScenarioList();
    },[])

    const getThenSetScenarioList = () => {
        setScenarioList( [
            {id:1,name:'シナリオ1',firstHeader:'',secondHeader:'',operatorName:'たかし',operatorImageUrl:'/img/mocks/operator.png',isActive:true,updatedAt:'2022-08-01'},
            {id:2,name:'シナリオ2',firstHeader:'',secondHeader:'',operatorName:'たかし',operatorImageUrl:'/img/mocks/operator.png',isActive:true,updatedAt:'2022-08-01'},
            {id:3,name:'シナリオ3',firstHeader:'',secondHeader:'',operatorName:'たかし',operatorImageUrl:'/img/mocks/operator.png',isActive:false,updatedAt:'2022-08-01'},
            {id:4,name:'シナリオ4',firstHeader:'',secondHeader:'',operatorName:'たかし',operatorImageUrl:'/img/mocks/operator.png',isActive:false,updatedAt:'2022-08-01'},
            {id:5,name:'シナリオ5',firstHeader:'',secondHeader:'',operatorName:'たかし',operatorImageUrl:'/img/mocks/operator.png',isActive:false,updatedAt:'2022-08-01'},
        ])
    }

    const setScenarioIsActive = (scenarioId:number,isActive:boolean) => {

        if(scenarioList === undefined) return;

        const newScenarioList = Array.from(scenarioList);

        const targetScenarioIndex = newScenarioList.findIndex( (scenario:Scenario) => (scenario.id === scenarioId) );

        const targetScenario = newScenarioList[targetScenarioIndex];

        targetScenario.isActive = isActive;

        setScenarioList(newScenarioList);

    }

    return (
        <Style_ScenarioList colors={colors}>
          {scenarioList ? (
              scenarioList.map((scenario:Scenario) => {
                  return (
                      <div key={scenario.id} className="scenarioRack">
                        <div className="scenarioLeftArea"
                          onClick={() => onScenarioSelect(scenario.id)}
                        >
                          <div className="scenarioName">{scenario.name}</div>
                          <div className="scenarioDate">
                            <span>更新日 : </span>
                            <span>{scenario.updatedAt}</span>
                          </div>
                        </div>
                        <div className="scenarioRightArea">
                          <div className="scenarioState">
                            {scenario.isActive
                              ? (<div className="isActive">
                                  {activenessRate === 100 ? '稼働中' : activenessRate + '%'}
                                </div>) 
                              : (<div className="isInactive">停止中</div>)
                            }
                          </div>
                          <div>
                            <ToggleSwitch 
                              value={scenario.isActive}
                              onClickSwitch={() => {
                                  showDialog({
                                      text: (scenario.isActive) ? '停止しますか？' : '稼働状態にしますか？',
                                      onOk:() => {
                                          setScenarioIsActive(
                                              scenario.id,
                                              !(scenario.isActive)
                                          );
                                          closeDialog();
                                      }
                                  })
                              }}
                            />
                          </div>
                        </div>
                      </div>
                  )
              })
          ) : (
            <></>
          )}
        </Style_ScenarioList>
    )
}

const Style_ScenarioList = styled.div<{
  colors:ThemeColor
}>`

& {


  .scenarioRack {

    display: flex;
    justify-content: space-between;
    align-items: stretch;
    column-gap:2em;

    .scenarioLeftArea {
      flex-grow: 1;
      display: flex;
      flex-flow: column;
      justify-content: space-between;
    }

    .scenarioRightArea {
      flex-shrink: 0;
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
      gap:8px;
    }
  }
  .scenarioRack {

    + .scenarioRack {
      border-top:1px solid ${(props) => props.colors.border};
    }

    padding: 1em;

    .scenarioLeftArea {
      cursor: pointer;

      &:hover {
        opacity: .7;
      }
      &:active > *{
        transform: translateY(1px);
      }
    }

    .scenarioName {
      font-weight: bold;
      line-height: 2;
    }

    .scenarioDate {
      font-size: .8em;
      opacity: .7;
    }

    .scenarioState {

      > div {
        width:5em;
        text-align: center;
        color:#fff;
        padding: .4em 0;
        border-radius: 4px;
        font-size: .9em;
        line-height: 1;

        &.isActive {
          background-color: ${(props) => props.colors.safe};
          font-weight:bold;
        }
  
        &.isInactive {
          background-color: ${(props) => props.colors.disabled};
        }
      }

    }

  }
}
`