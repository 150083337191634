// style
import styled,{css} from 'styled-components';
import { useThemeColor } from 'hooks/global/color';


type ReportTableProps = {

}
export const ReportTable = ({

}:ReportTableProps) => {

    const colors:ThemeColor = useThemeColor();


    const tableData = {
        header:[
            'ブランド名',
            'シナリオ名',
            '個数',
            '金額',
            '決済手段',
            '決済日時',
        ],
        rows:[
            ['ロコモア','シナリオ1','1','3,500円','クレジットカード（stripe）','2022-08-01 01:00:11'],
            ['グルコサミン','シナリオ2','15','50,000円','代引き','2022-08-03 04:44:44'],
        ]
    }

    const columnStyle = [
        {width:'12em'},
        {width:'16em'},
        {width:'4em'},
        {width:'8em'},
        {width:'16em'},
        {width:'12em'},
    ]



    return (
        <Style_ReportTable colors={colors} columnStyle={columnStyle}>
            <div className="tableHeader tableRow">
              {tableData.header.map((headerString:string,index:number) => {
                  return (
                    <div key={index + '_' + headerString} className="tableCell">
                      <span>{headerString}</span>
                    </div>
                  )
              })}
            </div>
            {tableData.rows.map((row:any[],index:number) => {
                return (
                    <div className="tableBody tableRow" key={index}>
                        {row.map((rowString:string) => {
                            return (
                              <div key={rowString} className="tableCell">
                                <span>{rowString}</span>
                              </div>
                            )
                        })}
                    </div>
                )
            })}
        </Style_ReportTable>
    )
}


const createColumnStyle = (columnStyle:any[]) => {

    let styles = '';

    columnStyle.forEach((style:any,index:number) => {
        styles += `
            > .tableCell:nth-child(${index + 1}){
                ${style.width ? 'width:' + style.width : ''};
            }
        `;
    })


    return css`${styles}`;

}



const Style_ReportTable = styled.div<{
  colors:ThemeColor,
  columnStyle:any[]
}>`

// テーブル構成
& {
  display:table;
  border-collapse:collapse;
  width: max-content;

  > .tableRow {
    display:table-row;

    > .tableCell {
      display:table-cell;
      border:#333333 1px solid; /*境界線を指定*/
      padding:10px; /*余白を指定*/
    }

    ${(props) => createColumnStyle(props.columnStyle)}

  }
}

// style
& {
  font-size: .9em;

  .tableHeader {
    background-color: ${(props) => props.colors.main};
    color:#fff;

    > .tableCell {
      text-align: center;
      border: none;
    }
  }

  .tableBody {
    > .tableCell {
      border:1px solid ${(props) => props.colors.border};
    }
  }
}
`;

const _Style_ReportTable = styled.div<{
  colors:ThemeColor,
  columnStyle:any[]
}>`
  & {
    font-size:.9em;

    // 構成
    .rowRack {
      /* display: flex;
      justify-content: space-between; */
      display: flex;
      
      ${(props) => createColumnStyle(props.columnStyle)}

    }

    .tableBox {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .tableHeader {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      background-color: ${(props) => props.colors.main};
      color:#fff;
      .tableBox {
        height: 3em;
      }
    }

    .tableRowsArea {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      border: 1px solid ${(props) => props.colors.border};

      .rowRack {
        + .rowRack {
          border-top: 1px solid ${(props) => props.colors.border};;
        }
      }

      .tableBox {
        height: 3em; // 可変にする

        + .tableBox {
          border-left: 1px solid ${(props) => props.colors.lightBorder};;
        }
      }
    }

    .tableBox {

    }

  }

`
