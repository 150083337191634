import { useEffect, useMemo, useState } from 'react';

// router
import { useParams } from 'react-router-dom';

// style
import styled from 'styled-components';
import { useThemeColor } from 'hooks/global/color'
import { zIndex } from 'styleValues/z-index'

// api
import { useBrandRestApiHooks } from 'api/rest/brand'

// element component
import { ActiveIcon } from 'components/elements/icon/ActiveIcon';

// component
import { BrandControlPanel } from './BrandControllPanel'


type BrandControllerProps = {
}

export const BrandController = ({
}:BrandControllerProps) => {

    const colors:ThemeColor = useThemeColor();

    const BrandRestApiHooks = useBrandRestApiHooks();

    const routeParams = useParams();

    // state
    const [currentBrand,setCurrentBrand] = useState<Brand>();
    const [drawerIsShown,setDrawerIsShown] = useState<boolean>(false);

    // memo
    const currentBrandId = useMemo(() => {
        return parseInt(routeParams.brandId!);
    },[routeParams.brandId])

    useEffect(() => {
        if(routeParams.brandId){
            getThenSetBrand();
        }else{
            setCurrentBrand({
                id:0,
                name:'ブランドを選択'
            })
        }
    },[routeParams.brandId])

    const getThenSetBrand = () => {
        BrandRestApiHooks.show(currentBrandId)
        .then((brand:Brand) => {
            setCurrentBrand(brand);
        });
    }

    return (
        <Style_BrandController colors={colors}>
            {currentBrand !== undefined && (
              <>
                <div className="brandControllerArea">
                  <div className="brandControllerButton" 
                    onClick={() => setDrawerIsShown(true)}
                  >
                    <span>{currentBrand.name}</span>
                    <ActiveIcon iconName='drawerRight'/>
                  </div>
                </div>

                <div className={'brandDrawerWrap' + (drawerIsShown ? '' : ' isHidden')}>
                  <div className="brandControllDrawerBase" 
                    onClick={() => setDrawerIsShown(false)}
                  />
                  <div className="brandControllDrawer">
                    <BrandControlPanel 
                      currentBrandId={currentBrandId}
                      onSelected={(brand:Brand) => {
                          setCurrentBrand(brand); // get を投げる前に見た目だけ先に変えてしまう
                          setDrawerIsShown(false)
                      }}
                    />
                  </div>
                </div>
              </>
            )}
        </Style_BrandController>
    )
}

const Style_BrandController = styled.div<{
  colors:ThemeColor
}>`

& {

  .brandControllerArea {
    padding: 1em 1.4em;

    .brandControllerButton {
      background-color: ${(props) => props.colors.background};
      border:1px solid ${(props) => props.colors.border};
      border-radius: 4px;
      box-shadow: 0 1px 1px -1px rgba(0,0,0,.3);
      padding: .6em 4%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;

      &:hover {
        filter: brightness(.9);
      }
      &:active {
        transform: translateX(4px);
      }

      > *:nth-child(2){
        font-size: 1.2em;
      }
    }
  }

  .brandDrawerWrap {
    .brandControllDrawerBase {
      position: fixed;
      top:0;left:0;right:0;bottom:0;
      background-color: rgba(0,0,0,.1);
      z-index: ${zIndex.brandDrawer};
      transition: opacity .2s;
    }
  
    .brandControllDrawer {
      position: fixed;
      top:32px;left:0;
      width:480px;
      height: 600px;
      max-height: 80vh;
      padding: 40px;
  
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      background-color: ${(props) => props.colors.background};
      box-shadow: 0 2px 4px 0px rgba(0,0,0,.04);
      z-index: ${zIndex.brandDrawer};

      transition: transform .4s;
    }

    &.isHidden {
      .brandControllDrawerBase {
        pointer-events: none;
        opacity: 0;
      }

      .brandControllDrawer {
        transform: translateX(-110%);
      }
    }
  }
}
`