import { useEffect, useMemo, useState,ReactNode } from 'react';

// hooks
import { useModal } from 'hooks/contexts/modal';

// style
import styled from 'styled-components';
import { useThemeColor } from 'hooks/global/color';
import { zIndex } from 'styleValues/z-index'

const modalOpenAnimationTime = 100;

export const Modal = () => {

    const colors:ThemeColor = useThemeColor();

    const {modalState,closeModal} = useModal();
    
    const [contents,setContents] = useState<ReactNode | null>(null); // 実際に表示されるコンテンツ
    const [timeoutId,setTimeoutId] = useState<number>(0);

    // global state の値
    const contentsNode:(ReactNode | null) = useMemo(() => {
        return modalState.contents;
    },[modalState.contents])

    const baseStyle:(string | undefined) = useMemo(() => {
        return modalState.baseStyle;
    },[modalState.baseStyle])

    const bgClose:boolean = useMemo(() => {
        return modalState.bgClose;
    },[modalState.baseStyle])

    const isShown = useMemo(() => {
        // ローカルの値とグローバルの値が同時に存在しなければ出現させない
        if(contents !== null && contentsNode !== null){
            return true;
        }else{
            return false;
        }
    },[contents,contentsNode])

    useEffect(() => {
        // 閉じるアニメーションが動いている間はコンテンツを維持する
        // モーダルを閉じるアニメーション中に html が消えてガクつくため

        // 直近のタイムアウトを中止
        // モーダルを閉じて、タイムアウトが発生する前に再度開かれた場合に
        // コンテンツを null にされないように
        window.clearTimeout(timeoutId)
        
        if(contentsNode !== null){
            // グローバルの値が入ったら、即時ローカルにも代入
            setContents(contentsNode);
        }else{
            // グローバルの値が null になったら、時間を置いてローカルにコンテンツを null にする
            // 時間の数値はアニメーションが終わるのに十分な時間とする
            const _timeoutId = window.setTimeout(() => {
                setContents(null)
            },modalOpenAnimationTime)

            // タイムアウトの id は保持。取り回しに使う
            setTimeoutId(_timeoutId);
        }

    },[contentsNode]);

    const handleBackgroundClick = (e:Event) => {   

        if(bgClose === false) return;
             
        if(e.currentTarget === e.target){
            closeModal();
        }
    }
    
  
    return (
        <Style_Modal 
          className={ !isShown ? 'isHidden' : ''} 
          onMouseDown={ (e:any) => handleBackgroundClick(e) }
          colors={colors}
        >
          <div className="modalScrollArea"
            onMouseDown={ (e:any) => handleBackgroundClick(e) }
          >
            <div 
              className={'modalInnerWrapper' + (baseStyle !== undefined ? (' ' + baseStyle) : '')}
            >
              { contents !== null && contents }
            </div>
          </div>
        </Style_Modal>
    )
}


const Style_Modal = styled.div<{
    colors:ThemeColor
}>`
& {
  position: fixed;
  top:0;left:0;bottom:0;right:0;
  z-index: ${zIndex.modal};
  padding: 56px 2%;
  background-color: rgba(0,0,0,.6);
  backdrop-filter: blur(8px);
  overflow-y: auto;
  transition: opacity ${modalOpenAnimationTime}ms;

  &.isHidden {
    opacity: 0;
    pointer-events: none;
  }

  .modalScrollArea {
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .modalInnerWrapper {
      &.standard {
        padding: 40px 32px;
        border-radius: 8px;
        box-shadow: 0 2px 8px 0px rgba(0,0,0,.1);
        background-color:${(props) => props.colors.background} ;
      }
    }
  }
}
`