// style
import styled from 'styled-components';
import { useThemeColor } from 'hooks/global/color';

// hooks
import { useModal } from 'hooks/contexts/modal';

// element component
import { IconTextButton } from 'components/elements/ui/IconTextButton';

// component
import { LpPopupEditPanel } from './LpPopupEditPanel';


type LpPopupDisplay = {
    popup:PopupForLp
}

export const LpPopupDisplay = ({
    popup
}:LpPopupDisplay) => {

    const colors:ThemeColor = useThemeColor();

    const {showModal} = useModal();

    const showPopupEditModal = () => {
        showModal({
            contents:<LpPopupEditPanel id={popup.id}/>,
            baseStyle:'standard'
        })
    }
    
    return (
      <Style_LpPopupDisplay colors={colors}>
        <div className="popupImageArea">
          <img src={popup.imageSrc} />
        </div>

        <div className="popupTriggerArea">
          <h3>出現条件</h3>
          <div className="triggerDisplayBox">
            {popup.trigger.scroll.isEnabled && (
                <div>
                  <span>スクロール量が</span>
                  <strong>{popup.trigger.scroll.threshold}%</strong>
                  <span>を超える</span>
                </div>
            )}
            {popup.trigger.time.isEnabled && (
                <div>
                  <span>ページ滞在時間が</span>
                  <strong>{popup.trigger.time.seconds}秒</strong>
                  <span>を超える</span>
                </div>
            )}
            {popup.trigger.backAction.isEnabled && (
                <div>
                  <span>ブラウザの戻るボタンが押される</span>
                </div>
            )}
          </div>
        </div>

        <div className="popupEditButton">
          <IconTextButton text="設定を変更" icon="edit"
            onClick={() => showPopupEditModal()}
          />
        </div>

      </Style_LpPopupDisplay>

    )
}

const Style_LpPopupDisplay = styled.div<{
  colors:ThemeColor
}>`

&{
  .popupImageArea {
    margin-bottom: 2em;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    > img {
      width:120px;
      height: 120px;
      object-fit: cover;
    }
  }

  .popupTriggerArea {

    h3 {
      font-weight: bold;
      margin:0 .8em .4em;
    }

    .triggerDisplayBox {
      border: 2px solid ${(props) => props.colors.main};
      border-radius: 6px;
      padding: 1em .8em;

      > div {
        line-height: 2.4;

        &::before {
          content: '・';
        }

        > strong {
          font-weight: bold;
          margin: 0 .2em;
        }
      }
    }
  }

  .popupEditButton {
    margin: 2em 0 0;
    text-align: center;
  }

}
`
