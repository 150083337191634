import { Modal } from './Modal'
import { Dialog } from './Dialog'

export const GlobalComponents = () => {
    return (
        <>
          <Modal />
          <Dialog />
        </>
    )
}