// global active style
import { createGlobalStyle } from 'styled-components';

import { useThemeColor } from 'hooks/global/color';

export const GlobalStyle = () => {

    const colors:ThemeColor = useThemeColor();
    return (
        <>
          <StyleGlobalBody colors={colors}/>
        </>
    )
}

const StyleGlobalBody = createGlobalStyle<{
  colors:ThemeColor
}>`
    body {
      background-color: ${(props) => props.colors.deeperBackground};
      color: ${(props) => props.colors.font};
    }
`;


