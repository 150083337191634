import React from 'react';
import ReactDOM from 'react-dom/client';
import './destyle.css';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { mock_service_worker } from 'apiMocks/browser'; // mock_service_worker
// globalContext
import { GlobalContext } from 'hooks/contexts/index'

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);

const reactApp = (
  <React.StrictMode>
    <GlobalContext>
      <App />
    </GlobalContext>
  </React.StrictMode>
);

// development の場合、api 通信を msw 経由
// firebase にモックをアップする関係で一旦全部でやってる
// バックエンドと繋ぐに当たってまた書き直して設定してください
console.log(process.env.NODE_ENV)
if (process.env.NODE_ENV === "test") {
    void mock_service_worker.start({onUnhandledRequest: 'bypass'}).then(() => {
        root.render(reactApp);
    });  
}else{
    root.render(reactApp);
}  


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
