// style
import styled from 'styled-components';
import { useThemeColor } from 'hooks/global/color';
import { useEffect, useState } from 'react';

type CheckboxProps = {
    type?:string,
    checkboxList:CheckboxObject[],
    valueList:string[],
    onChange:(valueList:string[]) => void
}

export const Checkbox = ({
    type = 'block',
    checkboxList,
    valueList,
    onChange
}:CheckboxProps) => {

    const colors:ThemeColor = useThemeColor();

    const handleClick = (clickedValue:string) => {

        const _valueList:string[] = [...valueList];

        const checkedValueIndex:number 
            = valueList.findIndex((value:string) => (value === clickedValue));

        if(checkedValueIndex < 0){
            // チェックがなければ加える
            _valueList.push(clickedValue);
        }else{
            // チェックされていれば取り除く
            _valueList.splice(checkedValueIndex,1);
        }

        onChange(_valueList);
    }

    return (
        <Style_Checkbox colors={colors} className={type}>
          {checkboxList.map((checkboxObj:CheckboxObject) => {
              return (
                  <div 
                    key={checkboxObj.value} 
                    className={'checkboxBlock' + (valueList.includes(checkboxObj.value) ? ' checked' : '')} 
                    onClick={() => handleClick(checkboxObj.value)}
                  >
                    <span>{checkboxObj.label}</span>
                  </div>
              )
          })}
        </Style_Checkbox>
    )
}



const Style_Checkbox = styled.div<{
  colors:ThemeColor
}>`

& {

  &.block {
    display: flex;
    flex-flow: column;
    row-gap: 1em;
  
    .checkboxBlock {
      background-color: ${(props) => props.colors.background};
      border:2px solid ${(props) => props.colors.main};
      border-radius: 4px;
      color:${(props) => props.colors.main};
      display: flex;
      justify-content: center;
      align-items: center;
      height: 2.4em;
      cursor: pointer;

      &:hover {
        opacity: .7;
      }

      &:active {
        transform:translateY(2px);
      }
      
      &.checked {
        background-color: ${(props) => props.colors.main};
        color:#fff;
      }

    }
  
  }
}
`
