import { useEffect,useState } from 'react';

// router
import { useNavigate } from 'react-router-dom';

// style
import styled from 'styled-components';
import { useThemeColor } from 'hooks/global/color'

// api
import { useBrandRestApiHooks } from 'api/rest/brand'

// element components
import { IconTextButton } from 'components/elements/ui/IconTextButton'


type BrandControlPanelProps = {
    currentBrandId:number,
    onSelected:(brand:Brand) => void
}

export const BrandControlPanel = ({
    currentBrandId,
    onSelected
}:BrandControlPanelProps) => {

    const colors:ThemeColor = useThemeColor();

    const BrandRestApiHooks = useBrandRestApiHooks();

    const navigate = useNavigate();

    const [brandList,setBrandList] = useState<Brand[]>([]);

    useEffect(() => {
        getThenSetBrandList();
    },[])

    const getThenSetBrandList = () => {
        BrandRestApiHooks.index()
        .then((brandList:Brand[]) => {
            setBrandList(brandList);
        });
    }

    return (
        <Style_BrandControlPanel colors={colors}>
          <div className="brandControlPanelHeader">
            <IconTextButton icon="plus" text="新規登録" />
          </div>
          <div className="brandSelector">
            {brandList.map((brand:Brand) => {
                return (
                  <div key={brand.id} 
                    className={"brandButton" + (brand.id === currentBrandId ? ' isSelected' : '')}
                    onClick={() => {
                        navigate(`/settings/brands/${brand.id}/lps`);
                        onSelected(brand);
                    }}
                  >
                    <span className="brandName">{brand.name}</span>
                  </div>
                )
            })}
          </div>
        </Style_BrandControlPanel>
    )
}

const Style_BrandControlPanel = styled.div<{
  colors:ThemeColor
}>`
& {
  height: 100%;
  display: flex;
  flex-flow: column;
  row-gap: 32px;

  .brandControlPanelHeader {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-flow: row-reverse;
  }

  .brandSelector {
    flex-grow: 1;
    border-top: 2px solid ${(props) => props.colors.main};
    border-bottom: 2px solid ${(props) => props.colors.main};
    background-color: ${(props) => props.colors.lightBorder};
    overflow-y: auto;

    .brandButton {
      padding: 1em 4%;
      display: flex;
      align-items: center;
      background-color: ${(props) => props.colors.background};
      cursor: pointer;

      &:active {
        > * {
          transform: translateY(2px);
        }
      }


      & + .brandButton {
        border-top: 1px solid ${(props) => props.colors.border};
      }

      &:hover,&.isSelected {
        background-color: ${(props) => props.colors.main};
        color:#fff;
      }
      
      .brandName {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }
}
`