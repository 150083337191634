// style
import styled from 'styled-components';
import { useThemeColor } from 'hooks/global/color';

// hooks
import { useModal } from 'hooks/contexts/modal';

// element component
import { IconTextButton } from 'components/elements/ui/IconTextButton'
import { ToggleSwitch } from 'components/elements/inputs/ToggleSwitch'

// general component
import { OneLineInputPanel } from 'components/generals/OneLineInputPanel'

type ChatbotBaseInfoProps = {
    chatbotId:number
}

export const ChatbotBaseInfo = ({
    chatbotId,
}:ChatbotBaseInfoProps) => {

    const colors:ThemeColor = useThemeColor();

    const {showModal} = useModal();

    const name:string = 'チャットボット1';
    const url:string = 'https://chatbotxxxxx.com';

    const showNameEditModal = () => {
        showModal({
            contents:<OneLineInputPanel initialText={name} placeholder="チャットボット名"/>,
            baseStyle:'standard'
        })
    }

    return (
      <Style_ChatbotBaseInfo colors={colors}>
        <div className="itemArea">
          <h3>チャットボット名</h3>
          <div className="itemRack">
            <span className='itemDisplay'>{name}</span>
            <div className="editButton">
              <IconTextButton icon='edit' 
                onClick={() => showNameEditModal()}
              />
            </div>
          </div>
        </div>
        <div className="itemArea">
          <h3>ページURL</h3>
          <div className="itemRack">
            <span className='itemDisplay'>
              <a href={url} target="_blank" rel="noopener noreferrer">{url}</a>
            </span>
          </div>
        </div>
      </Style_ChatbotBaseInfo>
    )
}

const Style_ChatbotBaseInfo = styled.div<{
  colors:ThemeColor
}>`

  & {

    .itemArea {

      + .itemArea {
        margin-top: 1.8em;
      }

      h3 {
        font-size: .8em;
        font-weight: bold;
      }
    }

    .itemRack {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap:1em;
      border-bottom: 1px solid ${(props) => props.colors.main};
      height: 3em;
  
      .itemDisplay {
        flex-grow: 1;
        line-height: 2;
        padding: 0 .4em;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
  
      .editButton {
        font-size:.9em;
      }
    } 

  }

`
