// pageView

import { getLoginUserData } from "services/user.service";

export const Dashboard = () => {
  const loginedData = getLoginUserData();

  console.log(loginedData);

  return <>
  <h1>Brand Admin</h1></>;
};
