import React, { useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { Form, FormikProvider, useFormik } from "formik";
import {
  Container,
  Alert,
  Typography,
  Link,
  Box,
  Divider,
  Stack,
  TextField,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Icon } from "@iconify/react";
import { motion } from "framer-motion";
import * as Yup from "yup";
import { login } from "services/auth.service";
import useAuth from "providers/useAuth";

const Login = () => {
  const { signIn } = useAuth();

  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Provide a valid email address")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),

  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      remember: true
    },
    validationSchema: LoginSchema,
    onSubmit: (
      formValue: { email: string; password: string; remember: boolean },
      { setStatus, setSubmitting }
    ) => {
      const { email, password } = formValue;
      
      login(email, password).then(
        () => {
          signIn();
          navigate("/admin/dashboard");
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          setSubmitting(false);
          setStatus(resMessage);
        }
      );
    },
  });

  const { errors, touched, values, isSubmitting, status, handleSubmit, getFieldProps } =
    formik;

  let easing = [0.6, -0.05, 0.01, 0.99];
  const animate = {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.6,
      ease: easing,
      delay: 0.16,
    },
  };

  return (
    <div className="App">
      <Container
        maxWidth="sm"
        sx={{ border: 1, boxShadow: 3, p: 5, marginTop: 5 }}
      >
        <Box justifyContent="center" alignItems="center" display="flex">
          <RouterLink to="/">
            <Box
              component="img"
              src="/img/logo/service_logo_200.png"
              width="100"
              height="100"
              alt="logo"
            />
          </RouterLink>
        </Box>

        <Divider sx={{ my: 3 }}></Divider>

        <Typography sx={{ color: "text.secondary", mb: 5 }}>
          ユーザー名・パスワードを入力してください
        </Typography>

        {status? <Alert severity="error" sx={{mb: 3}}>{status}</Alert>: null }

        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Box
              component={motion.div}
              animate={{
                transition: {
                  staggerChildren: 0.55,
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 3,
                }}
                component={motion.div}
                initial={{ opacity: 0, y: 40 }}
                animate={animate}
              >
                <TextField
                  fullWidth
                  autoComplete="username"
                  type="email"
                  label="メールアドレス"
                  {...getFieldProps("email")}
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                />

                <TextField
                  fullWidth
                  autoComplete="current-password"
                  type={showPassword ? "text" : "password"}
                  label="パスワード"
                  {...getFieldProps("password")}
                  error={Boolean(touched.password && errors.password)}
                  helperText={touched.password && errors.password}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowPassword((prev) => !prev)}
                        >
                          {showPassword ? (
                            <Icon icon="eva:eye-fill" />
                          ) : (
                            <Icon icon="eva:eye-off-fill" />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>

              <Box
                component={motion.div}
                initial={{ opacity: 0, y: 20 }}
                animate={animate}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{ my: 2 }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        {...getFieldProps("remember")}
                        checked={values.remember}
                      />
                    }
                    label="ログインを記憶する"
                  />

                  <Link
                    component={RouterLink}
                    variant="subtitle2"
                    to="#"
                    underline="hover"
                  >
                    パスワードを忘れた方はこちら
                  </Link>
                </Stack>

                <LoadingButton
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                >
                  {isSubmitting ? "loading..." : "ログイン"}
                </LoadingButton>
              </Box>
            </Box>
          </Form>
        </FormikProvider>

        {/* <Typography>
          Don’t have an account?{" "}
          <Link variant="subtitle2" component={RouterLink} to="/signup">
            Sign up
          </Link>
        </Typography> */}
      </Container>
    </div>
  );
};

export default Login;
