import { useEffect, useState } from 'react';

// style
import styled from 'styled-components';
import { useThemeColor } from 'hooks/global/color';

type PopupPreviewProps = {
    imageUrl:string,
    hasEmailLogFunction:boolean,
    emailImageUrl:string,
    emailPlaceholder:string,
    emailButtonDisplay:string,
    emailTermsUrl:string,
    emailTermsString:string,  
}

export const PopupPreview = ({
    imageUrl,
    hasEmailLogFunction = false,
    emailImageUrl,
    emailPlaceholder,
    emailButtonDisplay,
    emailTermsUrl,
    emailTermsString,
}:PopupPreviewProps) => {

    const colors:ThemeColor = useThemeColor();

    const [emailButtonClicked,setEmailButtonClicked] = useState<boolean>(false);

    useEffect(() => {
        if(hasEmailLogFunction === false) {
            setEmailButtonClicked(false);
        }
    },[hasEmailLogFunction])

    return (
        <Style_PopupPreview colors={colors}>
          <div className="popupPreviewBase">
            <div className="popupImageArea">
              <img className="popupImage" src={ emailButtonClicked ? emailImageUrl : imageUrl} />
            </div>
            {hasEmailLogFunction && (
              <div className="popupEmailArea">
                <div className="emailTerms">
                  <a href={emailImageUrl} target="_blank" rel="noopener noreferrer">{emailTermsString}</a>
                </div>
                <input type="email" placeholder={emailPlaceholder}/>
                <div className="emailPostButtonArea">
                  <div className="emailPostButton"
                    onClick={() => setEmailButtonClicked( !emailButtonClicked )}
                  >
                    <span>{emailButtonDisplay}</span>
                  </div>
                </div>
              </div>
            )}
          </div>
        </Style_PopupPreview>
    )
}

const Style_PopupPreview = styled.div<{
  colors:ThemeColor
}>`

& {

  border:16px solid #666;
  border-radius:10px;
  height: 540px;
  background-color: #ccc;
  display: flex;
  justify-content: center;
  align-items: center;

  .popupPreviewBase {
    width:280px;

    .popupImage {
      width:100%;
      object-fit: contain;
    }
  
    .popupEmailArea {
      color:#666;
      background-color: #fff;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      padding: 1.2em 2%;

      .emailTerms {
        font-size: .8em;
        text-align: center;
        text-decoration: underline;
        opacity: .7;
        margin-bottom: 1em;
      }

      input[type="email"] {
        width: 100%;
        border: 1px solid #ddd;
        background-color: #efefef;
        font-size:16px;
        border-radius: 4px;
        line-height: 2.4;
        outline: none;
        padding: 0 .4em;

        &::placeholder {
          color:#ccc;
        }
      }

      .emailPostButton {
        width: 96%;
        margin: 1em auto 0;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 2.8em;
        border-radius: 1.4em;
        background-color: rgba(25,168,82,1);
        color: #fff;
        font-weight: bold;
        box-shadow: 4px 6px 6px -2px rgba(0,0,0,.6);
        cursor: pointer;

        > span {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }
  }
  
}
`
