export const paymentActionTransformer = (actionFrontend:PaymentAction):Action_Backend => {

    const actionDetailFrontend = actionFrontend.actionDetail as PaymentActionDetail;

    const paymentActionDetail:PaymentActionDetail_Backend = {
        payment_list:actionDetailFrontend.paymentList
    }

    const nextBlockAction:Action_Backend = {
        id:actionFrontend.id,
        type:'payment',
        action_detail:paymentActionDetail
    }

    return nextBlockAction;

}