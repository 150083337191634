// router
import { useParams } from 'react-router-dom';

// pageView
import {SettingBrandContentsListPageView} from 'components/pages/settings/brandContentsList/SettingBrandContentsListPageView'

export const SettingPopupListPage = () => {

    const params = useParams();
      
    const brandId = parseInt(params.brandId!);

    return (
        <SettingBrandContentsListPageView brandId={brandId} pageCode="popup"/>
    )
}