import { rest } from 'msw';

import { Handler } from '../Handler';

import { baseUrl } from 'api/apiSettings';

import { BrandMock } from '../../data/rest/brand';

export class BrandMswHandler extends Handler {

    private statusCode;

    constructor(statusCode:number) {
        super(); // 親のコンストラクタを呼んでおく
        this.statusCode = statusCode | 200;
    }

    public apis = [
        rest.get(
            baseUrl + '/brands',
            async (req, res, ctx) => {
                const response = BrandMock.index();
                await super.wait();
                return res(ctx.status(this.statusCode), ctx.json(response));
            }
        ),
        rest.get(
            baseUrl + '/brands/:brandId',
            async (req, res, ctx) => {
                const { brandId } = req.params;
                const response = BrandMock.show(parseInt(brandId as string));
                await super.wait();
                return res(ctx.status(this.statusCode), ctx.json(response));
            }
        ),
        // rest.post(
        //     baseUrl + '/brands',
        //     async (req, res, ctx) => {
        //         const response = BrandMock.create();
        //         await super.wait();
        //         return res(ctx.status(this.statusCode), ctx.json(response));
        //     }
        // ),
        // rest.post(
        //     baseUrl + '/brands/:brandId',
        //     async (req, res, ctx) => {
        //         const { brandId } = req.params;

        //         const body = req.body as any;
              
        //         if(body === undefined || body === null) return;

        //         const siteUpdateData:any = {
        //             name:body.name
        //         }

        //         const response = BrandMock.update(brandId,data);
        //         await super.wait();
        //         return res(ctx.status(this.statusCode), ctx.json(response));
        //     }
        // ),
        // rest.delete(
        //     baseUrl + '/brands/:brandId',
        //     async (req, res, ctx) => {
        //         const { brandId } = req.params;
        //         const response = BrandMock.destroy(brandId);
        //         await super.wait();
        //         return res(ctx.status(this.statusCode), ctx.json(response));
        //     }
        // )
    ]


}
